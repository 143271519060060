import * as Yup from 'yup'
import type { FormikConfig, FormikErrors } from 'formik'
import { validate } from '@extend/client-helpers'
import { formatPhoneNumberIso, formatPhoneNumberLocal, validatePhoneNumber } from '@extend/client-helpers'
import { US_STATES } from '../../constants/us-states'
import type { CustomerFormFields, CustomerSubmitDataTypes } from './customer-form-template.types'

export const MAX_NAME_LENGTH = 100

const getContractTransferSchema = (ownerEmail?: string): Yup.ObjectSchema => {
  return Yup.object()
    .shape({
      firstName: Yup.string()
        .trim()
        .max(MAX_NAME_LENGTH, `Please use only up to ${MAX_NAME_LENGTH} characters`)
        .required('Enter a first name'),
      lastName: Yup.string()
        .trim()
        .max(MAX_NAME_LENGTH, `Please use only up to ${MAX_NAME_LENGTH} characters`)
        .required('Enter a last name'),
      email: Yup.string()
        .trim()
        .required('Enter an email')
        .test('email-validation', 'Please enter a valid email', (value): boolean => {
          return Boolean(value?.length && validate.isValidEmail(value))
        })
        .test(
          'new-email-validation',
          'The recipients email address cannot be the same as the current owner',
          (value): boolean => {
            return value !== ownerEmail
          },
        ),
      address1: Yup.string().trim().required('Enter an address'),
      address2: Yup.string().trim().optional(),
      city: Yup.string().trim().required('Enter a city'),
      postalCode: Yup.string()
        .trim()
        .required('Enter a ZIP code')
        .test('postalCode-validation', 'Please enter a valid ZIP', (value): boolean => {
          return Boolean(value?.length && validate.isValidZIPCode(value))
        }),
      provinceCode: Yup.string()
        .trim()
        .required('Enter a state')
        .max(2, 'Please use an abbreviated state name (i.e. TX)')
        .test('provinceCode-validation', 'Please enter a valid state', (value): boolean => {
          return Boolean(value?.length && validate.isValidProvinceCode(value))
        })
        .test(
          'provinceCode-us-validation',
          'Plans may only be transferred within the United States',
          (value): boolean => {
            return Boolean(value?.length && US_STATES.includes(value.toUpperCase()))
          },
        ),
      phoneNumber: Yup.string()
        .trim()
        .required('Enter a phone number')
        .test('is-valid-e.164-number', 'Please enter a valid phone number', (value) => {
          return validatePhoneNumber(value, 'US')
        }),
    })
    .defined()
}

export const isSubmitDisabled = (
  errors: FormikErrors<{
    firstName: string
    lastName: string
    email: string
    address1: string
    address2: string
    city: string
    postalCode: string
    provinceCode: string
    phoneNumber: string
  }>,
): boolean => {
  return Object.values(errors).some((error) => error !== undefined)
}

export function formikConfig(
  initialValues: CustomerFormFields,
  onSubmit: (data: CustomerSubmitDataTypes) => void,
  ownerEmail?: string,
): FormikConfig<{
  firstName?: string
  lastName?: string
  email?: string
  address1?: string
  address2?: string
  city?: string
  postalCode?: string
  provinceCode?: string
  phoneNumber?: string
}> {
  return {
    enableReinitialize: true,
    validationSchema: getContractTransferSchema(ownerEmail),
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      firstName: decodeURI(initialValues.firstName || ''),
      lastName: decodeURI(initialValues.lastName || ''),
      email: decodeURI(initialValues.email || ''),
      address1: decodeURI(initialValues.address1 || ''),
      address2: decodeURI(initialValues.address2 || ''),
      city: decodeURI(initialValues.city || ''),
      postalCode: decodeURI(initialValues.postalCode || ''),
      provinceCode: decodeURI(initialValues.provinceCode || ''),
      phoneNumber: formatPhoneNumberLocal(decodeURI(initialValues.phoneNumber || ''), 'US'),
    },
    onSubmit: (formValues: CustomerFormFields): void => {
      onSubmit({
        customer: {
          name: `${formValues.firstName} ${formValues.lastName}`,
          phone: formValues.phoneNumber && formatPhoneNumberIso(formValues.phoneNumber, 'US'),
          email: formValues.email,
          shippingAddress: {
            address1: formValues.address1 || '',
            address2: formValues.address2,
            city: formValues.city || '',
            countryCode: 'US',
            postalCode: formValues.postalCode || '',
            provinceCode: formValues.provinceCode || '',
          },
        },
      })
    },
  }
}
