// Incrementally loads bot messages into the chat
import type { SagaIterator } from 'redux-saga'
import type { Reply } from '@customers-api-client'
import { delay, put, call } from '@redux-saga/core/effects'
import { formatBotMessages, requestTimeout, getParam, isMultiselectPrompt } from '../../lib/chat-utils'
import { chatActions } from '../../actions'
import { setPollState } from '../../store/slices/poll'
import chatAutoAdvance from './chat-auto-advance'

const isChatAutoAdvanceOn = getParam('fast')

export default function* loadBotContent(
  reply: Reply,
  firstBotMessageKey?: string,
  requestDelay?: number,
): SagaIterator {
  const { messages, prompt, poll } = reply
  const botMessages = formatBotMessages(messages)
  const firstBotMessage = botMessages[0]

  if (firstBotMessageKey) {
    yield put(chatActions.chatMessagesUpdateMessage(firstBotMessage, firstBotMessageKey))
    const timeout = requestTimeout(firstBotMessage, requestDelay)
    yield delay(timeout)
    yield put(chatActions.chatMessagesStopTyping(firstBotMessageKey))
  }

  if (poll) {
    yield put(setPollState(JSON.stringify(poll)))
  }

  // If firstBotMessageKey does not exist, need to render all messages instead of skipping the first.
  const remainingMessages = firstBotMessageKey ? botMessages.slice(1) : botMessages

  for (const message of remainingMessages) {
    const { initialPauseTimeout, showTypingTimeout, key } = message
    yield delay(initialPauseTimeout)
    yield put(chatActions.chatMessagesAdd(message))
    yield delay(showTypingTimeout)
    yield put(chatActions.chatMessagesStopTyping(key))
  }

  if (prompt) {
    if (isMultiselectPrompt(prompt)) {
      yield put(chatActions.chatMultiselectInit(prompt))
    } else {
      // append a delay from the moment the chat messages
      // are triggered to "stop typing", to visibly displaying
      // the input prompts
      yield delay(1000)
      yield put(chatActions.chatPromptSet(prompt))
    }
  }

  yield put(chatActions.chatSessionReady())
  if (isChatAutoAdvanceOn && prompt) {
    yield call(chatAutoAdvance, prompt.slot)
  }
}
