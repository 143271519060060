import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@customers-ui'
import { bp } from '@extend/client-helpers'
import type {
  Contract,
  Plan,
  PlanContract,
  PlanDetails as IPlanDetails,
  PlanTermsSetResponse,
} from '@customers-api-client'
import { ContractDetails } from './contract-details/contract-details'
import { PlanDetails } from './plan-details/plan-details'
import { MainHeader } from './main-header/main-header'
import { PlanSummary } from './plan-summary/plan-summary'
import { PlanTerms } from './plan-terms/plan-terms'

export interface WarrantyTermsMainProps {
  contract?: Contract & {
    planDetails?: PlanContract
  }

  plan: Plan
  planDetails: IPlanDetails
  terms: string | PlanTermsSetResponse | null | undefined
  isDefault: boolean
}

const WarrantyTermsMain: FC<WarrantyTermsMainProps> = ({ contract, plan, planDetails, terms, isDefault }) => {
  return (
    <Main data-qa="plans-main" role="main">
      <MainHeader planDetails={planDetails} />
      {!isDefault && <PlanDetails plan={plan} contract={contract} planDetails={planDetails} />}
      <ContractDetails contract={contract} planDetails={planDetails} />
      {!isDefault && <Divider />}
      <PlanSummary planDetails={planDetails} />
      <PlanTerms terms={terms} planDetails={planDetails} />
    </Main>
  )
}

const Divider = styled.div({
  backgroundColor: COLOR.NEUTRAL[300],
  height: 1,
  maxWidth: 1280,
  margin: '0 auto',
  [bp.xl]: {
    marginTop: 48,
    marginBottom: 19,
  },
})

const Main = styled.div({
  margin: '0 auto',
  padding: '30px 20px 0',
  background: COLOR.WHITE,
  [bp.md]: {
    padding: '40px 0 0',
  },
})

export { WarrantyTermsMain }
