import type { FC } from 'react'
import React from 'react'
import type { Contract, StoreConsumerReadableProperties } from '@customers-api-client'
import styled from '@emotion/styled'
import { ArrowForward, Button, COLOR } from '@extend/zen'
import { bp } from '@customers-ui'
import { currency } from '@extend/client-helpers'
import svgs from '../../../lib/svgs'
import images from '../../../lib/images'
import { useMarqeta } from '../../../hooks/use-marqeta'
import type { Locale } from '../../../types/localization'

export interface VirtualCardDetailsProps {
  isHidden?: boolean
  isCardActivated: boolean
  walletId: string
  sellerName: string
  remainingAmount: number
  contract: Contract
  isCA?: boolean
  store: StoreConsumerReadableProperties | undefined
  locale?: Locale
}

const pageText = {
  'en-US': {
    heading: 'You have {remainingAmount} to spend at {sellerName}',
    bullets: [
      'This virtual debit card only supports online transactions',
      `Wait to reveal and copy the card number until you're ready to check out`,
      'We recommend using the "credit or debit" payment method at checkout, other payment options may lead to issues',
      'This card also covers additional taxes and standard shipping costs',
    ],
    expirationNotice: `This card expires after 7 days. If you do not use it during this time, you will be issued a new card automatically with the same balance.`,
    license:
      'This Extend Visa® Card is issued by Pathward, N.A., Member FDIC, pursuant to a license from Visa U.S.A. Inc.',
    cardholderAgreement: 'View cardholder agreement',
    virtualCard: {
      name: 'Virtual Card',
      accountNumber: 'ACCOUNT NUMBER',
      cvv: 'CVV2',
      valid: 'Valid Thru',
      use: 'LIMITED USE',
    },
    copy: 'View & Copy Card Number',
    visit: 'Visit {sellerName}',
  },
  'en-CA': {
    heading: 'You have {remainingAmount} to spend at {sellerName}',
    bullets: [
      'This virtual debit card only supports online transactions',
      `Wait to reveal and copy the card number until you're ready to check out`,
      'Use the "credit or debit" payment method at checkout',
    ],
    expirationNotice: `This card expires after 7 days.  If you do not use during that time, you will be issued a new one. For assistance, please call (877) 248-7707`,
    license: '*Visa Int./Peoples Trust Company, Licensed User. Powered by Marqeta.',
    cardholderAgreement: 'View cardholder agreement',
    virtualCard: {
      name: 'Virtual Card',
      accountNumber: 'ACCOUNT NUMBER',
      cvv: 'CVV2',
      valid: 'Valid Thru',
      use: 'LIMITED USE',
    },
    copy: 'View & Copy Card Number',
    visit: 'Visit {sellerName}',
  },
  'fr-CA': {
    heading: 'Vous avez {remainingAmount} à dépenser chez {sellerName}!',
    bullets: [
      'Cette carte de débit virtuelle prend seulement en charge les transactions en ligne.',
      `Attendez le moment de payer avant de révéler et de copier le numéro de la carte.`,
      'Utilisez le mode de paiement « crédit ou débit » à la caisse.',
    ],
    expirationNotice: `Cette carte expire après sept jours. Si vous ne l'utilisez pas pendant cette période, vous en recevrez une nouvelle. Pour obtenir de l'aide, veuillez appeler le 1 877 248-7707.`,
    license: '*Visa Int./La Compagnie Fiducie Peoples, utilisée sous licence. Propulsé par Marqeta.',
    cardholderAgreement: `Consulter l'accord du titulaire de la carte`,
    virtualCard: {
      name: 'Carte virtuelle',
      accountNumber: 'NUMÉRO DE COMPTE',
      cvv: 'CVV2',
      valid: `Valide jusqu'au`,
      use: 'UTILISATION LIMITÉE',
    },
    copy: 'Afficher et copier le numéro de la carte',
    visit: 'Visiter {sellerName}',
  },
}

export const CARDHOLDER_AGREEMENT_URL_US =
  'https://virutal-cards-assets-prod.s3.amazonaws.com/extend_card_program_cardholder_agreement_11_14_22.pdf'

export const CARDHOLDER_AGREEMENT_URL_CA =
  'https://virutal-cards-assets-prod.s3.amazonaws.com/extend_card_program_cardholder_agreement_ca_12_04_23.pdf'

export const VirtualCardDetails: FC<VirtualCardDetailsProps> = ({
  isHidden,
  isCardActivated,
  sellerName,
  remainingAmount,
  store,
  contract,
  walletId,
  isCA = false,
  locale = 'en-US',
}) => {
  const { innerWidth } = window
  const { isMarqetaLoaded } = useMarqeta(
    { walletId, windowWidth: innerWidth },
    { isSkipped: !!isHidden || !isCardActivated },
  )
  const isCardDisplayDisabled = !isMarqetaLoaded || isHidden
  const { heading, bullets, expirationNotice, license, cardholderAgreement, virtualCard, copy, visit } =
    pageText[locale]

  if (!walletId) return null

  return (
    <Container>
      <Description>
        <Heading>
          {heading
            .replace(
              '{remainingAmount}',
              `${currency.format(remainingAmount, currency.getCurrencyCodeByLocale(locale), '', locale)}`,
            )
            .replace('{sellerName}', sellerName)}
        </Heading>
        <Bullets>
          {bullets.map((bullet: string) => (
            <Bullet key={bullet.replace(/ /gi, '-')}>
              <svgs.Checkmark
                width={23}
                height={23}
                minWidth={23}
                minHeight={23}
                fill={COLOR.BLUE[800]}
                display="inline-block"
                margin="3px 8px 0px 0px"
              />
              {bullet}
            </Bullet>
          ))}
        </Bullets>
        <p data-cy="expiration-notice">
          {expirationNotice}
          <br />
          <br />
          {license}
        </p>
        <AgreementLinkWrapper>
          <a
            href={isCA ? CARDHOLDER_AGREEMENT_URL_CA : CARDHOLDER_AGREEMENT_URL_US}
            target="_blank"
            rel="noreferrer noopener"
          >
            {cardholderAgreement}
          </a>
        </AgreementLinkWrapper>
      </Description>
      <VirtualCardContainer id="virtual-card-container">
        {isCardDisplayDisabled ? (
          <VirtualCardPlaceholder />
        ) : (
          <VirtualCard>
            <ExtendLogoRow>
              <ExtendLogo src={images.extendLogoWhite} />
              <CardText>{virtualCard.name}</CardText>
            </ExtendLogoRow>
            <CardPan>
              <CardFieldLabel fontSize={14}>{virtualCard.accountNumber}</CardFieldLabel>
              <CardNumber id="mq-card-pan" />
              <CVVRow>
                <CVVWrapper>
                  <CardFieldLabel>{virtualCard.cvv}</CardFieldLabel>
                  <CardCVV id="mq-card-cvv" />
                </CVVWrapper>
                <ExpWrapper>
                  <CardFieldLabel>{virtualCard.valid}</CardFieldLabel>
                  <CardExp id="mq-card-exp" />
                </ExpWrapper>
              </CVVRow>
              <UseLabel>{virtualCard.use}</UseLabel>
            </CardPan>
            <CardLogoRow>
              <CardLogo src={images.visaLogo} />
            </CardLogoRow>
          </VirtualCard>
        )}
        {isCardDisplayDisabled ? (
          <CopyButtonPlaceholder />
        ) : (
          <VirtualCardButtonContainer>
            <CopyButton id="mq-card-copy">
              <Button text={copy} fillContainer data-cy="copy-cvv-btn" emphasis="medium" />
            </CopyButton>
            {(store?.domain || contract.resellerMerchant?.domain) && (
              <Button
                text={visit.replace('{sellerName}', sellerName)}
                fillContainer
                data-cy="link-to-store-btn"
                onClick={() => window.open(contract?.resellerMerchant?.domain ?? `https://${store?.domain}`, '_blank')}
                icon={ArrowForward}
                iconPosition="right"
              />
            )}
          </VirtualCardButtonContainer>
        )}
      </VirtualCardContainer>
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 48,
  padding: '28px 32px 28px 35px',
  width: '100%',
  backgroundColor: COLOR.NEUTRAL[100],
  borderRadius: '4px 4px 0px 4px',
  color: COLOR.BLACK,
  alignItems: 'center',
  [bp.desktop]: {
    flexDirection: 'row',
    padding: '58px 64px 57px 91px',
    justifyContent: 'space-between',
  },
})

const Description = styled.div({
  maxWidth: 600,
})

const Heading = styled.h2({
  fontWeight: 600,
  fontSize: 24,
  lineHeight: '30px',
  [bp.desktop]: {
    fontWeight: 700,
    fontSize: 36,
    lineHeight: '49px',
  },
})

const Bullets = styled.div({
  fontSize: 18,
  lineHeight: '24px',
  marginTop: 16,
  [bp.desktop]: {
    fontSize: 20,
    lineHeight: '28px',
    marginTop: 16,
  },
})

const Bullet = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: 30,
})

const VirtualCardContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  width: '60vw',
  minWidth: 250,
  paddingTop: 20,
  [bp.desktop]: {
    paddingTop: 0,
    width: '38vw',
    maxWidth: 600,
    minWidth: 450,
  },
})

const VirtualCardPlaceholder = styled.div({
  backgroundColor: COLOR.NEUTRAL[300],
  borderRadius: 4,
  width: '60vw',
  height: '38vw',
  minWidth: 250,
  minHeight: 157,
  paddingTop: 20,
  [bp.desktop]: {
    paddingTop: 0,
    width: '38vw',
    height: '24vw',
    maxWidth: 600,
    maxHeight: 378,
    minWidth: 450,
    minHeight: 283,
  },
})

const CopyButton = styled.div({
  position: 'relative',
})

const CopyButtonPlaceholder = styled.div({
  position: 'relative',
  height: 40,
})

const VirtualCard = styled.div({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100%',
  aspectRatio: '3.37 / 2.125',
  borderRadius: 4,
  background: `url(${images.virtualCardBackground})`,
  padding: 8,
  paddingBottom: 20,
  justifyContent: 'space-between',
})

const CardText = styled.span({
  color: COLOR.NEUTRAL[300],
  lineHeight: '18px',
  fontSize: 14,
  [bp.tablet]: {
    lineHeight: '28px',
    fontSize: 20,
  },
  [bp.desktop]: {
    lineHeight: '38px',
    fontSize: 28,
  },
})

const CardPan = styled.div({
  paddingLeft: 14,
  position: 'relative',
  top: 10,
  fontWeight: 100,
  color: COLOR.WHITE,
  [bp.tablet]: {
    paddingLeft: 20,
    top: 30,
    fontWeight: 200,
  },
  [bp.desktop]: {
    paddingLeft: 20,
    top: 50,
    fontWeight: 400,
  },
})

const CardFieldLabel = styled.span<{ fontSize?: number }>(({ fontSize = 12 }) => ({
  color: COLOR.WHITE,
  fontSize: 8,
  [bp.tablet]: {
    fontSize: 10,
  },
  [bp.desktop]: {
    fontSize,
  },
}))

const UseLabel = styled(CardFieldLabel)({
  color: COLOR.NEUTRAL[400],
  fontSize: 8,
  position: 'relative',
  top: 12,
  fontWeight: 100,
  [bp.tablet]: {
    fontSize: 10,
    top: 14,
  },
  [bp.desktop]: {
    fontSize: 12,
    top: 14,
  },
})

const CardNumber = styled.div({
  height: 20,
  iframe: {
    width: '100%',
  },
  [bp.tablet]: {
    height: 30,
  },
  [bp.desktop]: {
    height: 40,
  },
})

const CVVRow = styled.div({
  display: 'flex',
  maxWidth: 214,
  height: 20,
  iframe: {
    width: '100%',
    height: 38,
  },
  [bp.tablet]: {
    height: 30,
  },
  [bp.desktop]: {
    height: 40,
  },
})

const CardCVV = styled.div({ fontSize: 12 })

const CVVWrapper = styled.div({})
const ExpWrapper = styled.div({})
const CardExp = styled.div({})

const ExtendLogoRow = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 12,
  paddingLeft: 14,
  paddingRight: 6,
  [bp.tablet]: {
    marginTop: 24,
    paddingLeft: 24,
    paddingRight: 12,
  },
})

const VirtualCardButtonContainer = styled.div({
  paddingLeft: 19,
  paddingRight: 19,
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
})

const ExtendLogo = styled.img({
  width: 60,
  [bp.tablet]: {
    width: 100,
  },
  [bp.desktop]: {
    width: 'auto',
  },
})

const CardLogoRow = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingRight: 12,
})

const CardLogo = styled.img({
  width: 60,
  [bp.tablet]: {
    width: 90,
  },
  [bp.desktop]: {
    width: 'auto',
  },
})

const AgreementLinkWrapper = styled.div({
  marginTop: 25,
  a: {
    color: COLOR.BLUE[700],
    textDecoration: 'underline',
  },
})
