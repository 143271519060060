import React from 'react'
import type { FC, FormEvent, KeyboardEvent } from 'react'
import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { COLOR, Button } from '@extend/zen'
import { bp } from '@extend/client-helpers'
import type { InputWithButtonsPrompt } from '@customers-api-client'
import { kebabCase } from 'lodash'
import { useSelector } from 'react-redux'
import * as selectors from '../../../../../reducers/selectors'
import type { RootState } from '../../../../../reducers'
import { svgs } from '../../../../../lib/assets'
import { MailButton } from '../../mail-button'
import type { UserInputComponentProps } from '../../types'
import ChatTextArea from '../chat-text-area'

export interface ChatTextWithButtonsProps extends UserInputComponentProps<InputWithButtonsPrompt> {}

const ChatTextWithButtonsInput: FC<ChatTextWithButtonsProps> = ({ onAddInput, prompt }) => {
  const [message, setMessage] = useState('')

  const { slot, options } = prompt
  const { placeholder } = prompt
  const promptOption = options?.[0]

  const isLoading = useSelector((state: RootState) => selectors.getChatIsLoading(state))

  const [secondsLeft, setSecondsLeft] = useState<number>(promptOption.enableDelaySec || 0)
  const [isTimerRunning, setIsTimerRunning] = useState<boolean>(!!promptOption.enableDelaySec)
  const [isDisabled, setIsDisabled] = useState(false)

  const decreaseSeconds = (): void => {
    setSecondsLeft((prev) => {
      if (prev > 0) {
        return prev - 1
      }
      return 10
    })
  }

  useEffect(() => {
    if (secondsLeft <= 0) {
      setIsTimerRunning(false)
      return undefined
    }
    const timer = setInterval(decreaseSeconds, 1000)

    return () => clearInterval(timer)
  }, [secondsLeft])

  const isSubmitEnabled = !!message

  const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault()

    if (message.trim()) handleSubmitMessage(message.trim())
  }

  const onKeyDown = (event: KeyboardEvent<HTMLFormElement>): void => {
    const { key } = event

    if (message.trim() && key === 'Enter') handleSubmitMessage(message.trim())
  }

  const handleSubmitMessage = (string: string): void => {
    const newMessage = string

    onAddInput({ message: newMessage, slot, value: newMessage })
    setMessage('')
  }

  const { title, value, outputText } = promptOption

  const handleClick = (): void => {
    setIsDisabled(true)
    onAddInput({ message: outputText, value, slot })
  }

  const ButtonElement = (
    <ButtonWrapper key={value as string} data-cy="chat-button-input-wrapper">
      <Button
        data-cy={`chat-button-input-${kebabCase(title)}`}
        size="regular"
        emphasis="medium"
        type="button"
        text={`${title}${isTimerRunning ? ` (0:${secondsLeft.toString().padStart(2, '0')})` : ''}`}
        isDisabled={isLoading || isTimerRunning || isDisabled}
        onClick={handleClick}
      />
    </ButtonWrapper>
  )

  return (
    <OuterWrapper>
      <Form {...{ onSubmit, onKeyDown }}>
        <ComponentWrapper data-cy="chat-text-with-buttons">
          <TextWrapper data-cy="chat-text-wrapper">
            <ChatTextArea
              aria-label={placeholder}
              {...{
                setMessage,
                message,
                placeholder,
              }}
            />
            <MailButton type="submit" disabled={!isSubmitEnabled} aria-label="send">
              {isSubmitEnabled ? <svgs.MailEnabled /> : <svgs.MailDisabled />}
            </MailButton>
          </TextWrapper>
          <ButtonsWrapper>{ButtonElement}</ButtonsWrapper>
        </ComponentWrapper>
      </Form>
    </OuterWrapper>
  )
}

const OuterWrapper = styled.div({
  width: '100%',
  maxWidth: 768,
  marginBottom: '28px',
  [bp.md]: {
    padding: '0px 24px',
    maxWidth: 673,
  },
})

const ComponentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

const TextWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
})

const ButtonsWrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '8px',
})

const ButtonWrapper = styled.div({
  justifyContent: 'center',
  alignItems: 'stretch',
  display: 'flex',
})

const Form = styled.form({
  display: 'flex',
  borderTop: `1px solid ${COLOR.NEUTRAL[500]}`,
  paddingRight: 10,
  [bp.md]: {
    borderTop: `1px solid ${COLOR.WHITE}`,
    paddingRight: 0,
  },
})

export { ChatTextWithButtonsInput }
