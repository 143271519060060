import type { ChatMessage } from '../../../types/chat-message'

const groupMessages = <T extends ChatMessage>(messages: T[]): T[][] => {
  return messages.reduce<T[][]>((arr, message) => {
    const { bot } = message
    const previousArray = arr[arr.length - 1]

    // First message in messages
    if (!previousArray) {
      return [[message]]
    }

    const { bot: previousItemBot } = previousArray[0]

    // Message is the same type (bot or not) as previous message
    if (previousItemBot === bot) {
      return [...arr.slice(0, arr.length - 1), [message, ...previousArray]]
    }

    // Message is not the same as previous message
    return [...arr, [message]]
  }, [])
}

export default groupMessages
