import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery, X_EXTEND_ACCESS_TOKEN } from '../base-query'
import type { AccessTokenGetResponse, CardActivateResponse, WalletGetResponse } from './types'

export const virtualCardsApi = createApi({
  baseQuery,
  reducerPath: 'VirtualCards',
  tagTypes: ['wallet', 'marqeta-access-token'],
  endpoints: (build) => ({
    activateCard: build.mutation<CardActivateResponse, { walletId: string; accessToken?: string }>({
      query: ({ walletId, accessToken }) => ({
        url: `/virtual-cards/wallet/${walletId}/activate`,
        headers: {
          ...(accessToken && { [X_EXTEND_ACCESS_TOKEN]: accessToken }),
        },
        method: 'POST',
      }),
      invalidatesTags: ['wallet'],
    }),
    getMarqetaAccessToken: build.query<AccessTokenGetResponse, { walletId: string; accessToken?: string }>({
      query: ({ walletId, accessToken }) => ({
        url: `/virtual-cards/wallet/${walletId}/access-token`,
        headers: {
          ...(accessToken && { [X_EXTEND_ACCESS_TOKEN]: accessToken }),
        },
        method: 'GET',
      }),
      providesTags: (_, _err, accessToken) => [{ type: 'marqeta-access-token', marqetaAccessToken: accessToken }],
    }),
    getVirtualCardRemainingValue: build.query<WalletGetResponse, { walletId: string; accessToken?: string }>({
      query: ({ walletId, accessToken }) => ({
        url: `/virtual-cards/wallet/${walletId}/remaining-value`,
        headers: {
          [X_EXTEND_ACCESS_TOKEN]: accessToken,
        },
        method: 'GET',
      }),
      providesTags: (_, _err, walletId) => [{ type: 'wallet', walletId }],
    }),
  }),
})

export const { useActivateCardMutation, useGetMarqetaAccessTokenQuery, useGetVirtualCardRemainingValueQuery } =
  virtualCardsApi
