import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../base-query'
import type { MerchantServicingSettings } from './types'
import type { V2 } from '@extend-services/service-orders'

export const servicersApi = createApi({
  baseQuery,
  reducerPath: 'Servicers',
  tagTypes: ['merchant-servicing-settings', 'merchant-branding-configurations'],
  endpoints: (build) => ({
    getMerchantServicingSettings: build.query<MerchantServicingSettings, { sellerId: string }>({
      query: ({ sellerId }) => ({
        url: `/servicers/merchant-servicing-settings/${sellerId}`,
        method: 'GET',
      }),
      providesTags: ['merchant-servicing-settings'],
    }),
    getMerchantBrandingConfigurations: build.query<V2.Models.MerchantBrandingResponse, { subdomainName: string }>({
      query: ({ subdomainName }) => ({
        url: `/servicers/merchant-branding/${subdomainName}`,
        method: 'GET',
      }),
      providesTags: ['merchant-branding-configurations'],
    }),
  }),
})

export const { useGetMerchantServicingSettingsQuery, useGetMerchantBrandingConfigurationsQuery } = servicersApi
