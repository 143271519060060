import type { FC } from 'react'
import React from 'react'
import { css, Global } from '@emotion/react'
import { COLOR } from '@customers-ui'
import { bp } from '@extend/client-helpers'

export const ChatDateGlobalStyles: FC = () => (
  <Global
    styles={css`
      .react-datepicker {
        font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
        border: none;

        ${bp.sm} {
          border: 1px solid ${COLOR.NEUTRAL[200]};
          box-shadow: 0px 3px 5px 0px rgba(209, 205, 205, 0.5);
          position: relative;
        }
      }

      .react-datepicker__input-container input {
        border-radius: 4px;
        border: 1px solid ${COLOR.BLUE[700]};
        padding: 10px 16px;
        font-size: 14px;
        color: ${COLOR.BLACK};
        background: none;
      }

      .react-datepicker .react-datepicker__header {
        background: ${COLOR.WHITE};
        border: none;
      }

      .react-datepicker .react-datepicker__day--outside-month {
        color: ${COLOR.NEUTRAL[200]};
      }

      .react-datepicker .react-datepicker__day--today.react-datepicker__day--outside-month {
        color: ${COLOR.WHITE};
      }

      .react-datepicker .react-datepicker__day--today {
        font-weight: normal;
        background: ${COLOR.NEUTRAL[200]};
      }

      .react-datepicker .react-datepicker__day {
        width: 31px;
        height: 31px;
        margin: 8px;
        border: 1px solid ${COLOR.WHITE};
        font-size: 12px;
        border-radius: 50%;
        padding-top: 2px;
        padding-bottom: 2px;
      }

      .react-datepicker .react-datepicker__day:hover {
        background: ${COLOR.WHITE};
        border-radius: 50%;
        border: 1px solid ${COLOR.BLUE[700]};
      }

      .react-datepicker .react-datepicker__day--disabled:hover {
        border-radius: 50%;
        border: 1px solid ${COLOR.NEUTRAL[200]};
      }

      .react-datepicker .react-datepicker__day--selected,
      .react-datepicker .react-datepicker__day--selected:hover {
        background: ${COLOR.BLUE[700]};
        color: ${COLOR.WHITE};
        border: none;
      }

      .react-datepicker .react-datepicker__day-name {
        width: 31px;
        height: 31px;
        margin: 8px;
        font-size: 12px;
        margin-top: 24px;
        font-weight: 600;
      }

      .react-datepicker .react-datepicker__navigation--previous {
        border-right-color: ${COLOR.BLUE[700]};
      }

      .react-datepicker .react-datepicker__navigation--next {
        border-left-color: ${COLOR.BLUE[700]};
      }

      .react-datepicker .react-datepicker__navigation--previous:hover {
        outline: none;
        @media (hover: none) {
          border-right-color: ${COLOR.BLUE[700]};
        }
        @media (hover: hover) {
          border-right-color: ${COLOR.BLUE[700]};
        }
      }

      .react-datepicker .react-datepicker__navigation--next:hover {
        outline: none;
        @media (hover: none) {
          border-left-color: ${COLOR.BLUE[700]};
        }
        @media (hover: hover) {
          border-left-color: ${COLOR.BLUE[700]};
        }
      }
    `}
  />
)
