import type { FC } from 'react'
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { ContentLayout, Spinner } from '@customers-ui'
import {
  useGetContractQuery,
  useListInsuranceClaimsQuery,
  useUpdateContractMutation,
  getRTKQueryErrorMessage,
} from '@customers-api-rtk-query'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import type { SerializedError } from '@reduxjs/toolkit'
import { customLogger } from '@extend/client-helpers'
import { SuccessErrorComponent } from '../../components/forms/success-error-component'
import type { CustomerSubmitDataTypes } from '../../components/forms/customer-form-template.types'
import { TransferInputView } from './transfer-input-view'
import { TransferConfirmView } from './transfer-confirm-view'
import { TransferSummaryView } from './transfer-summary-view'
import { getCanTransfer } from './contract-transfer.utils'
import type { ContractsSearchIndividual, ContractsUpdateResponse } from '../../types/contract'
import type { Claim } from '../../types/claim'

const ContractTransfer: FC = () => {
  const { id: contractId } = useParams<{ id: string }>()

  const [
    updateContract,
    {
      isSuccess: isContractUpdateSuccess,
      isLoading: isContractUpdateLoading,
      isError: isContractUpdateError,
      error: contractUpdateError,
      data: updatedContract,
    },
  ] = useUpdateContractMutation()

  const {
    data: contract,
    isLoading: isContractLoading,
    isError: isContractGetError,
    error: contractGetError,
  } = useGetContractQuery({ contractId }, { skip: !!updatedContract }) as {
    data?: ContractsSearchIndividual
    isLoading: boolean
    isError: boolean
    error: FetchBaseQueryError | SerializedError | undefined
  }

  const {
    data: { items: fetchedClaims } = {},
    error: listClaimError,
    isError: isListClaimError,
  } = useListInsuranceClaimsQuery({
    containsContractId: contractId,
  })
  const claims = fetchedClaims as unknown as Claim[]

  const history = useHistory()
  const [isVisible, setIsVisible] = useState(false)
  const [stage, setStage] = useState<string>('input')

  // Error logging for requests
  const [hasLoggedError, setHasLoggedError] = useState(false)
  const queryError = contractUpdateError || contractGetError || listClaimError

  if (queryError && !hasLoggedError) {
    const errorMessage = getRTKQueryErrorMessage(queryError)

    customLogger.warn(`[Err: Contract Transfer Page]: ${errorMessage}`, {
      isContractUpdateError,
      isContractGetError,
      isListClaimError,
      contractId,
    })
    setHasLoggedError(true)
  }

  const [formData, setFormData] = useState<CustomerSubmitDataTypes>({
    customer: {
      name: '',
      phone: '',
      email: '',
      shippingAddress: {
        address1: '',
        address2: '',
        city: '',
        countryCode: '',
        postalCode: '',
        provinceCode: '',
      },
    },
  })

  const handleDismiss = (): void => {
    setIsVisible(false)
  }

  useEffect(() => {
    if (contract && claims && !getCanTransfer({ contract, claims })) {
      history.push(`/my_plans/${contract?.id}`)
    }
  }, [history, contract, claims])

  useEffect(() => {
    if (isContractUpdateSuccess || Boolean(contractUpdateError)) {
      setIsVisible(true)
    }
  }, [isContractUpdateSuccess, contractUpdateError])

  return (
    <ContentLayout pageTitle="Transfer Plan" data-cy="transfer-plan-content-layout">
      <SuccessErrorComponent
        successMessage="Transfer complete! An email has been sent to the recipient."
        errorMessage="We were unable to transfer your plan due to an error. Please try again."
        isSuccess={isContractUpdateSuccess}
        isError={Boolean(contractUpdateError)}
        {...{ isVisible, handleDismiss }}
      />
      {(!claims || isContractLoading) && <Spinner size="md" />}
      {contract && (
        <>
          {stage === 'input' && <TransferInputView {...{ contract, formData, setFormData, setStage }} />}
          {stage === 'confirm' && (
            <TransferConfirmView
              contract={contract}
              formData={formData}
              setStage={setStage}
              isLoading={isContractUpdateLoading}
              isSuccess={isContractUpdateSuccess}
              updateContract={updateContract}
            />
          )}
          {stage === 'summary' && (
            <TransferSummaryView contract={updatedContract as unknown as ContractsUpdateResponse} formData={formData} />
          )}
        </>
      )}
    </ContentLayout>
  )
}

export { ContractTransfer }
