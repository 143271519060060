import type { FC, ComponentProps } from 'react'
import React from 'react'
import { Select } from '@chakra-ui/react'
import { COLOR } from '@extend/zen'

import { FormControl } from '../form-control'

interface DropdownProps extends ComponentProps<typeof Select> {
  className?: string
  id?: string
  label?: string
  errorMessage?: string
  options?: Option[]
  hint?: string
}

interface Option {
  value: string
  text: string
}

const Dropdown: FC<DropdownProps> = ({
  className,
  options = [],
  id,
  label,
  errorMessage,
  isDisabled,
  isRequired,
  isInvalid,
  hint,
  ...props
}) => {
  return (
    <FormControl
      {...{
        className,
        id,
        label,
        errorMessage,
        isDisabled,
        isInvalid,
        hint,
      }}
      isAsteriskVisible={isRequired}
    >
      <Select {...DropdownStyles} {...props} {...{ id, isDisabled, isInvalid }}>
        {options.map(({ value, text }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </Select>
    </FormControl>
  )
}

const DropdownStyles = {
  height: 40,
  borderRadius: 16,
  iconColor: COLOR.NEUTRAL[600],
  _hover: {
    cursor: 'pointer',
  },
  _focus: {
    border: `2px solid ${COLOR.BLUE[800]}`,
    _invalid: {
      border: `2px solid ${COLOR.RED[700]}`,
    },
  },
  _invalid: {
    border: `1px solid ${COLOR.RED[700]}`,
  },
  sx: {
    '&.chakra-select': { paddingInlineStart: 16 },
  },
}

export { Dropdown }
