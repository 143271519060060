import dayjs from 'dayjs'

function getDateString(timeStamp: number, monthOffset = 0, dayOffset = 0, locale: string): string {
  const date = dayjs(timeStamp * 1000)
    .add(monthOffset, 'month')
    .add(dayOffset, 'day')
    .toDate()
  return new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    timeZone: 'America/New_York',
  }).format(date)
}

export function getFormattedDate(timestamp: number, locale = 'en-US'): string {
  return getDateString(timestamp, undefined, undefined, locale)
}

export function setDateByMonths(currentDate: Date, monthsOffset: number): Date {
  return dayjs(currentDate).add(monthsOffset, 'month').toDate()
}
