import ArrowsCircle from './arrows-circle'
import BackArrow from './back-arrow'
import CalendarIcon from './calendar-icon'
import Cart from './cart'
import Checkmark from './checkmark'
import { CircleCheckmark } from './circle-checkmark'
import CloseIcon from './close-icon'
import { CloseModal } from './close-modal'
import CustomerHeart from './customer-heart'
import { Cross } from './cross'
import External from './external'
import ForwardArrow from './forward-arrow'
import FreeShipping from './free-shipping'
import MailDisabled from './mail-disabled'
import MailEnabled from './mail-enabled'
import Minus from './minus'
import Package from './package'
import PhoneTouch from './phone-touch'
import Plus from './plus'
import Power from './power'
import QuoteLeft from './quote-left'
import QuoteRight from './quote-right'
import Replacement from './replacement'
import RightChevron from './right-chevron'
import StartOverIcon from './start-over-icon'
import ZeroMoney from './zero-money'
import { BrokenPhoneImage } from './broken-phone-image'
import { BrokenWineGlass } from './broken-wine-glass'
import { iMac } from './imac'
import { ImageMediaFile } from './image-media-file'
import { InfoCircleIcon } from './info-circle-icon'
import { Manufactured } from './manufactured'
import { PackageCheckmark } from './package-checkmark'
import { PeaceOfMindImage } from './peace-of-mind-image'
import { Polaroid } from './polaroid'
import { RepairCheckmarkIcon } from './repair-checkmark-icon'
import { RoadCone } from './road-cone'
import Profile from './profile'
import { QuestionCircleIcon } from './question-circle-icon'
import { LostPackage } from './lost-package'
import { StolenPackage } from './stolen-package'
import { DamagedPackage } from './damaged-package'
import { LearnMoreArrow } from './learn-more-arrow'
import { WorryFreePackage } from './worry-free-package'
import { ClaimTextBubbles } from './claim-text-bubbles'
import { ConvenientSupportHeart } from './convenient-support-heart'
import PackageClaim from './package-claim'
import PackageIcon from './pacakgeIcon'

const svgs = {
  ArrowsCircle,
  BackArrow,
  BrokenPhoneImage,
  BrokenWineGlass,
  CalendarIcon,
  Cart,
  Checkmark,
  CircleCheckmark,
  ClaimTextBubbles,
  CloseIcon,
  CloseModal,
  ConvenientSupportHeart,
  Cross,
  CustomerHeart,
  DamagedPackage,
  External,
  ForwardArrow,
  FreeShipping,
  iMac,
  ImageMediaFile,
  InfoCircleIcon,
  LearnMoreArrow,
  LostPackage,
  MailDisabled,
  MailEnabled,
  Manufactured,
  Minus,
  Package,
  PackageCheckmark,
  PeaceOfMindImage,
  PhoneTouch,
  Plus,
  Polaroid,
  Power,
  Profile,
  QuestionCircleIcon,
  QuoteLeft,
  QuoteRight,
  RepairCheckmarkIcon,
  Replacement,
  RightChevron,
  RoadCone,
  StartOverIcon,
  StolenPackage,
  WorryFreePackage,
  ZeroMoney,
  PackageClaim,
  PackageIcon,
}

export default svgs
