import { useContext } from 'react'
import type { TrackingContextProps } from './tracking-context'
import { TrackingContext } from './tracking-context'

/**
 * Reusable hook for calling the tracking-context's available methods
 *
 */
const useTrackingContext = (): TrackingContextProps => {
  const trackingContext = useContext(TrackingContext)
  if (!trackingContext) {
    throw new Error(`Make sure to wrap your component with the "TrackingProvider" container`)
  }
  const { trackEvent } = trackingContext

  return {
    trackEvent,
  }
}

export { useTrackingContext }
