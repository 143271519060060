import { Loader } from '@googlemaps/js-api-loader'
import { DEPOT_REPAIR_GOOGLE_MAPS_API_KEY, GOOGLE_MAPS_API_KEY } from '@helloextend/client-constants'
import { customLogger } from '@extend/client-helpers'

export type AutocompleteService = google.maps.places.AutocompleteService
export type AutocompletePrediction = google.maps.places.AutocompletePrediction
export type PlacesService = google.maps.places.PlacesService
export type GeocoderAddressComponent = google.maps.GeocoderAddressComponent

export interface Services {
  AutocompleteService: AutocompleteService
  PlacesService: PlacesService
}

type GetDetailsProps = (
  request: google.maps.places.PlaceDetailsRequest,
  callback: (a: google.maps.places.PlaceResult | null, b: google.maps.places.PlacesServiceStatus) => void,
) => void

type GetPlacePredictionsProps = (
  request: google.maps.places.AutocompletionRequest,
  callback: (a: google.maps.places.AutocompletePrediction[] | null, b: google.maps.places.PlacesServiceStatus) => void,
) => void

export const initServices = async (useDepotRepairApiKey = false): Promise<typeof google | void> => {
  const loader = new Loader({
    apiKey: useDepotRepairApiKey ? DEPOT_REPAIR_GOOGLE_MAPS_API_KEY : GOOGLE_MAPS_API_KEY,
    version: 'weekly',
    libraries: ['places'],
  })

  return loader.load().catch((error) => {
    customLogger.error(`Error occurred during Google Maps attempt to load`, error)
  })
}

export const getDetails: GetDetailsProps = (request, callback) => {
  // PlacesService needs a new div as a param to inject attribution/credit: https://stackoverflow.com/a/14345546
  const PlacesService = new google.maps.places.PlacesService(document.createElement('div'))
  return PlacesService.getDetails(request, callback)
}

export const getPlacePredictions: GetPlacePredictionsProps = (request, callback) => {
  const AutocompleteService = new google.maps.places.AutocompleteService()
  AutocompleteService.getPlacePredictions(request, callback)
}
