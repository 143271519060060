import type { FC } from 'react'
import React from 'react'

export const Cross: FC = () => {
  return (
    <svg width={20} height={18} viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg">
      <title>Cross</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M37-27.45c3.438 0 6.55 1.394 8.803 3.647A12.411 12.411 0 0149.45-15h0v67.45h-639.9V-15c0-3.438 1.394-6.55 3.647-8.803A12.411 12.411 0 01-578-27.45h0z"
          stroke="#CCC"
          strokeWidth={1.1}
          fill="#FFF"
        />
        <path
          d="M9.636 1.667v14.666M2 9h16"
          stroke="#03C"
          strokeWidth={2.2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
