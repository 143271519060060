import type { Action } from 'redux'

interface ErrorActionFactory<T extends string> extends Action {
  type: T
  payload: ErrorObject
  error: true
  meta?: {
    statusCode?: number
  }
}

interface ErrorObject {
  name?: string
  message: string
  statusCode?: number
}

export function createActionFailure<T extends string>(type: T) {
  return function failure(error: Error | string, statusCode?: number): ErrorActionFactory<T> {
    return {
      type,
      payload: typeof error === 'string' ? new Error(error) : error,
      error: true,
      meta: {
        statusCode,
      },
    }
  }
}
