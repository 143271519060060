import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { Middleware } from '@reduxjs/toolkit'
import { customLogger } from '@extend/client-helpers'

export const logRtkQueryErrors: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const endpoint = action.meta?.arg?.endpointName || 'Unknown Endpoint'
    const error = action.payload?.data?.message || action?.payload?.error || 'Unknown Error'
    customLogger.warn(`[RTK Query Err] ${endpoint}: ${error}`, { action })
  }

  return next(action)
}
