import type { FC } from 'react'
import React from 'react'
import { animated, useTransition } from 'react-spring'
import styled from '@emotion/styled'
import { COLOR, Close, Icon, IconSize } from '@extend/zen'
import { MenuLinks } from './menu-links'
import { ProfileMenu } from './profile-menu'

export const MENU_WIDTH = 280

interface WrapperProps {
  showMenu: boolean
}

interface HamburgerMenuProps {
  closeMenu: () => void
  showMenu: boolean
  isLoggedIn: boolean
}

const HamburgerMenu: FC<HamburgerMenuProps> = ({ showMenu, closeMenu, isLoggedIn }) => {
  const menuTransitions = useTransition(showMenu, null, {
    from: { transform: `translate(0px)` },
    enter: { transform: `translate(${MENU_WIDTH}px)` },
    leave: { transform: `translate(0px)` },
  })

  const backgroundTransitions = useTransition(showMenu, null, {
    from: { opacity: 0 },
    enter: { opacity: 0.75 },
    leave: { opacity: 0 },
  })

  const menu = menuTransitions.map(({ item, props, key }) => {
    return (
      item && (
        <NavMenu
          role="navigation"
          data-cy="nav-menu"
          key={key}
          style={{
            ...props,
          }}
        >
          <CloseIconWrapper data-cy="nav-close-icon" onClick={closeMenu}>
            <Icon icon={Close} data-cy="close-icon" size={IconSize.xlarge} />
          </CloseIconWrapper>

          {isLoggedIn && (
            <ProfileMenuWrapper data-cy="nav-profile-menu">
              <ProfileMenu />
            </ProfileMenuWrapper>
          )}

          <MenuLinksWrapper data-cy="nav-menu-links">
            <MenuLinks closeMenu={closeMenu} isLoggedIn={isLoggedIn} />
          </MenuLinksWrapper>
        </NavMenu>
      )
    )
  })

  const background = backgroundTransitions.map(({ item, props, key }) => {
    return (
      item && (
        <animated.div
          key={key}
          style={{ ...props, background: COLOR.BLACK, flex: 2, width: '120vw' }}
          onClick={closeMenu}
          id="menu-background"
          data-cy="menu-background"
        />
      )
    )
  })

  return (
    <Wrapper showMenu={showMenu}>
      {menu}
      {background}
    </Wrapper>
  )
}

const NavMenu = styled(animated.nav)({
  position: 'relative',
  background: COLOR.WHITE,
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  paddingTop: 67,
  paddingBottom: 32,
  marginLeft: `-${MENU_WIDTH}px`,
  zIndex: 1,
  width: MENU_WIDTH,
  gap: 8,
})

const CloseIconWrapper = styled.div({
  background: 'none',
  border: 'none',
  position: 'absolute',
  top: 16,
  left: 16,
  cursor: 'pointer',
  padding: 0,
})

const ProfileMenuWrapper = styled.div({
  width: 'calc(100% - 40px)',
  padding: '8px 32px',
})

const MenuLinksWrapper = styled.div({
  padding: '8px 32px',
  display: 'flex',
  flexDirection: 'column',
})

const Wrapper = styled.div<WrapperProps>(
  {
    height: '100vh',
    top: '0px',
    left: '0px',
    display: 'flex',
    zIndex: 1,
    position: 'absolute',
  },
  (props) => ({
    width: props.showMenu ? '100vw' : 'auto',
  }),
)

export { HamburgerMenu }
