import { combineReducers } from 'redux'
import type { AuthAccessTokenReducerState } from './access-token'
import accessToken from './access-token'
import type { AuthErrorReducerState } from './error'
import error from './error'
import type { AuthIsLoadingReducerState } from './is-loading'
import isLoading from './is-loading'
import type { AuthDecodedAccessTokenReducerState } from './decoded-access-token'
import decodedAccessToken from './decoded-access-token'

export interface AuthReducerState {
  accessToken: AuthAccessTokenReducerState
  error: AuthErrorReducerState
  isLoading: AuthIsLoadingReducerState
  decodedAccessToken: AuthDecodedAccessTokenReducerState
}

const auth = combineReducers({
  accessToken,
  error,
  isLoading,
  decodedAccessToken,
})

export default auth
