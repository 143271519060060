import type { SagaIterator } from 'redux-saga'
import { call, select } from '@redux-saga/core/effects'
import * as selectors from '../../reducers/selectors'
import type { chatActions } from '../../actions'
import sessionUpdate from './session-update'

type Action = ReturnType<typeof chatActions.chatSessionUpdate>

/* uses the reducer property "chat.prompt.isPromptHidden"
 * as an implicit indicator that a session update is in the middle of
 * being processed; if the input prompt is marked hidden,
 * when a session update action gets dispatched, then we ignore it
 */
export default function* throttleSessionUpdate(action: Action): SagaIterator {
  const isPromptHidden = yield select(selectors.getChatIsPromptHidden)
  if (isPromptHidden) return

  yield call(sessionUpdate, action)
}
