import React from 'react'
import styled from '@emotion/styled'
import type { BadgeProps } from '@extend/zen'
import { Badge, Shield } from '@extend/zen'

export const ProductProtectionInlineBadge = ({
  isClaimInProgress,
  subBadgeText,
  isLoading,
  badgeDetails,
  badgeColor,
}: {
  isClaimInProgress: boolean
  subBadgeText?: string
  isLoading: boolean
  badgeDetails: string
  badgeColor: BadgeProps['color']
}) => {
  return (
    <BadgeWrapper>
      <Badge text="Product Protection" color="blue" data-cy="product-protection-coverage-badge" icon={Shield} />
      {!isLoading && (
        <>
          {isClaimInProgress ? (
            <Badge
              emphasis="low"
              text={subBadgeText || 'Claim in Progress'}
              data-cy="product-protection-progress-badge"
              color="green"
            />
          ) : (
            <Badge emphasis="low" text={badgeDetails} color={badgeColor} data-cy="product-protection-coverage-badge" />
          )}
        </>
      )}
    </BadgeWrapper>
  )
}

const BadgeWrapper = styled.div({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  flexDirection: 'row',
})
