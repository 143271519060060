import React from 'react'
import styled from '@emotion/styled'
import { bp } from '@customers-ui'
import type { ServiceOrder } from '@customers-api-client'
import { COLOR, Timeline, TimelineItem } from '@extend/zen'
import type { FC } from 'react'

interface RepairInProgressTimelineProps {
  serviceOrder: ServiceOrder
}

export const RepairInProgressTimeline: FC<RepairInProgressTimelineProps> = ({ serviceOrder }) => {
  return (
    <TimelineWrapper data-cy="repair-in-progress-timeline">
      <Timeline>
        <TimelineItem label="Claim approved" state="complete" data-cy="claim-approved-timeline-item" />
        <TimelineItem
          label="Defective product shipped by customer"
          state="complete"
          data-cy="defective-product-shipped-timeline-item"
        />
        <TimelineItem label="Repair in progress" state="current" data-cy="repair-in-progress-timeline-item">
          <ContentContainer>
            {!!serviceOrder.assignedServicer?.businessName && (
              <ServicerTextContainer>
                <span>Servicer: </span>
                <ServicerText data-cy="servicer-name">{serviceOrder.assignedServicer.businessName}</ServicerText>
              </ServicerTextContainer>
            )}
            <MainText data-cy="main-text">
              {`Your servicer is repairing your item. We'll email you when it's fixed and on its
                way back to you.`}
            </MainText>
          </ContentContainer>
        </TimelineItem>
        <TimelineItem label="Claim fulfilled" state="upcoming" data-cy="claim-fulfilled-timeline-item" />
      </Timeline>
    </TimelineWrapper>
  )
}

const TimelineWrapper = styled.div({
  width: '100%',
})

const ContentContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: 4,
  flex: '1 0 0',
})

const ServicerTextContainer = styled.div({
  color: `${COLOR.NEUTRAL[1000]}`,
  fontFeatureSettings: `'clig' off, 'liga' off;`,
  fontFamily: 'Nunito Sans',
  lineHeight: '24px',
  fontWeight: 700,
  [bp.mobile]: {
    fontSize: 15,
  },
  [bp.desktop]: {
    fontSize: 16,
  },
})

const ServicerText = styled.span({
  fontWeight: 600,
})

const MainText = styled.div({
  color: `color: var(--text-emphasis-high-default, ${COLOR.NEUTRAL[1000]});`,
  fontFeatureSettings: `'clig' off, 'liga' off;`,
  fontFamily: 'Nunito Sans',
  fontWeight: 400,
  lineHeight: '24px',
  [bp.mobile]: {
    fontSize: 15,
  },
  [bp.desktop]: {
    fontSize: 16,
  },
})
