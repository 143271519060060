import type { FC } from 'react'
import React from 'react'

const RightChevron: FC = () => {
  return (
    <svg width="24px" height="60px" viewBox="0 0 24 60" version="1.1">
      <g id="Claims-portal-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.5">
        <g id="Desktop_Product-picker" transform="translate(-971.000000, -636.000000)" fill="#000000">
          <polygon
            id="back_button-copy-2"
            transform="translate(983.000000, 666.000000) scale(-1, 1) translate(-983.000000, -666.000000) "
            points="994.092041 636 971 666 994.092041 696 995 694.7179 972.887348 665.999823 995 637.281746"
          />
        </g>
      </g>
    </svg>
  )
}

export default RightChevron
