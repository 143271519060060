import type { Action } from '../../types/action-type'

export type ChatLoadingReducerState = boolean

// Using `createBooleanReducer` sets the initial state to `false`, which causes a flicker when Kaley loads
export default function isLoading(state = true, action: Action): ChatLoadingReducerState {
  switch (action.type) {
    case 'CHAT_SESSION_CONNECT_REQUEST':
      return true
    case 'CHAT_SESSION_READY':
      return false
    default:
      return state
  }
}
