import type { FC, ChangeEvent, KeyboardEvent } from 'react'
import React, { useState, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import styled from '@emotion/styled'
import { TextArea } from '@extend/zen'
import { Slot } from '@customers-api-client'
import { formatPhoneNumberOnChange, getCallingCode } from '@extend/client-helpers'
import type { DefaultPrompt } from '@extend-conversations/types'
import { useScreenSize } from '../../../../hooks'

const MAX_LINE_WIDTH = 558

export interface ChatTextAreaProps {
  message: string
  placeholder?: string
  setMessage: (message: string) => void
  validationType?: DefaultPrompt['validationType']
  isError?: boolean
  errorFeedback?: string
  slot?: Slot
  isMobile?: boolean
}

const ChatTextArea: FC<ChatTextAreaProps> = ({
  setMessage,
  message,
  placeholder,
  validationType,
  isError,
  errorFeedback,
  slot,
  isMobile = false,
}) => {
  const [rows, setRows] = useState(1)
  const [hiddenMessage, setHiddenMessage] = useState(message)
  const hiddenMessageRef = useRef<HTMLSpanElement>(null)
  const screenSize = useScreenSize()
  const intl = useIntl()
  const isPhoneValidation = validationType === 'phone'

  const getRows = (): number => {
    if (hiddenMessageRef && hiddenMessageRef.current) {
      return hiddenMessageRef.current.offsetWidth > MAX_LINE_WIDTH ? 2 : 1
    }

    return 1
  }

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    event.preventDefault()
    const { value } = event.target
    let newValue = value
    if (isPhoneValidation) {
      newValue = formatPhoneNumberOnChange(value)
    }
    setMessage(newValue)
    setHiddenMessage(newValue)
  }

  const onKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>): void => {
    if (event.keyCode === 13 && !message) {
      // prevent default if not holding shift while enter
      event.preventDefault()
    }
  }

  useEffect(() => {
    setRows(getRows())
  }, [hiddenMessage])

  return (
    <>
      <HiddenMessage ref={hiddenMessageRef}>{hiddenMessage}</HiddenMessage>
      <TextAreaWrapper size={validationType && 'lg'}>
        <TextArea
          id="chat-text-area"
          data-cy="chat-text-area"
          rows={screenSize === 'small' ? 1 : rows}
          value={message}
          aria-label={placeholder || 'Enter a description'}
          {...{ onKeyDown, onChange, placeholder }}
          isResizable={false}
          isError={isError}
          errorFeedback={errorFeedback}
          hasNoBorder={screenSize === 'small'}
          {...(isPhoneValidation && {
            prefix: getCallingCode(intl.locale),
            subtext: 'Currently available for US/CA numbers',
          })}
          {...(isMobileNumericInput(isPhoneValidation, isMobile, slot) && {
            inputMode: 'numeric',
          })}
          {...(validationType === 'number' && {
            inputMode: 'numeric',
            subtext: 'Please enter a numeric value',
          })}
          // We don't want to see error text on top of subtext
          {...(isError && { subtext: '' })}
        />
      </TextAreaWrapper>
    </>
  )
}

const isMobileNumericInput = (isPhoneValidation: boolean, isMobile: boolean, slot?: Slot): boolean => {
  if (!slot) {
    return false
  }

  const isMobileContractsSearchKey = isPhoneValidation && isMobile && slot === Slot.ContractsSearchKey
  const isMobileVerificationCode = isMobile && slot === Slot.VerificationCode
  return isMobileContractsSearchKey || isMobileVerificationCode
}

const TextAreaWrapper = styled.div<{ size: string | undefined }>(({ size }) => {
  return `
  display: flex;
  flex-direction: column;
  width: 100%;

  ${
    size === 'lg' &&
    `& > div {
    min-height: 76px;
  }

  & > div > div:last-child {
    padding-left: 12px;
    min-height: 36px;
  }
  @media screen and (min-width: 768px) {
    & > div > div:last-child {
      padding-left: 0;
    }
  }`
  }
`
})

export const HiddenMessage = styled.span({
  position: 'absolute',
  visibility: 'hidden',
  whiteSpace: 'pre',
  fontSize: 16,
})

export default ChatTextArea
