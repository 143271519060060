import type { StylesConfig } from 'react-select'
import styled from '@emotion/styled'
import { COLOR } from '@customers-ui'
import { bp, breakpoints } from '@extend/client-helpers'
import type { OptionType } from '../../../../../lib/google-api'

export const Wrapper = styled.div({
  width: '100%',
  maxWidth: breakpoints[1],
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [bp.md]: {
    flex: 2,
    flexDirection: 'row',
    padding: '0px 24px',
    maxWidth: 673,
  },
})

export const MobileSeparator = styled.div({
  borderTop: `1px solid ${COLOR.NEUTRAL[200]}`,
  marginTop: 12,
  marginBottom: 8,
  width: 'calc(100% - 32px)',
  alignSelf: 'center',
  [bp.md]: {
    display: 'none',
  },
})

export const InlineWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flex: 2,
  padding: '0px 16px',
  paddingBottom: 8,
  [bp.md]: {
    padding: 0,
  },
})

export const StyledTextInput = styled.input({
  height: 40,
  border: `1px solid ${COLOR.WHITE}`,
  borderRadius: 3,
  flex: 3,
  paddingLeft: 4,
  paddingRight: 4,
  fontSize: 16,
  wordBreak: 'break-word',
  resize: 'none',
  margin: 0,
  backgroundClip: 'padding-box',
  caretColor: COLOR.BLUE[700],
  '::-webkit-scrollbar': {
    display: 'none',
  },
  '::placeholder': {
    color: COLOR.NEUTRAL[500],
  },
  ':focus': {
    outline: 'none',
    border: `1px solid ${COLOR.WHITE}`,
  },
  [bp.md]: {
    margin: '32px 0px',
    border: `1px solid ${COLOR.BLUE[700]}`,
    padding: 8,
    ':focus': {
      outline: 'none',
      outlineColor: COLOR.WHITE,
      border: `1px solid ${COLOR.BLUE[700]}`,
    },
  },
})

export const MailButtonWrapper = styled.div({
  flex: 1,
  display: 'flex',
  justifyContent: 'end',
  [bp.md]: {
    justifyContent: 'start',
  },
})

export const customSelectStyles: StylesConfig<OptionType, false> = {
  container: (defaultProvidedStyles) => ({
    ...defaultProvidedStyles,
    flex: 1,
    [bp.md]: {
      flex: 2,
      margin: '32px 8px',
    },
  }),
  control: (defaultProvidedStyles) => ({
    ...defaultProvidedStyles,
    border: 'none',
    height: 40,
    padding: '8px 12px',
    [bp.md]: {
      border: `1px solid ${COLOR.BLUE[700]}`,
      borderColor: COLOR.BLUE[700],
      padding: 0,
    },
    boxShadow: 'none',
  }),

  option: (defaultProvidedStyles, { isFocused }) => ({
    ...defaultProvidedStyles,
    padding: 8,
    backgroundColor: isFocused ? COLOR.BLUE[100] : 'white',
    color: isFocused ? COLOR.BLUE[700] : 'black',
    borderBottom: `1px solid ${COLOR.NEUTRAL[200]}`,
    ':last-child': {
      border: 'none',
    },
    [bp.md]: {
      ':first-of-type': {
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
      },
      ':last-child': {
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
      },
    },
  }),

  menu: (defaultProvidedStyles) => ({
    ...defaultProvidedStyles,
    borderRadius: 0,
    width: '100vw',
    marginBottom: 0,
    padding: 0,
    [bp.md]: { width: '100%', borderRadius: 4, marginBottom: 8 },
  }),

  menuList: (defaultProvidedStyles) => ({
    ...defaultProvidedStyles,
    padding: 0,
  }),
}
