import { combineReducers } from 'redux'
import type { AccessTokenReducerState } from './access-token'
import accessToken from './access-token'
import type { ChatLoadingReducerState } from './is-loading'
import isLoading from './is-loading'
import type { MessagesReducerState } from './messages'
import messages from './messages'
import type { PromptReducerState } from './prompt'
import prompt from './prompt'
import type { SlotsReducerState } from './slots'
import slots from './slots'

export interface ChatReducerState {
  accessToken: AccessTokenReducerState
  isLoading: ChatLoadingReducerState
  messages: MessagesReducerState
  prompt: PromptReducerState
  slots: SlotsReducerState
}

export default combineReducers({
  accessToken,
  isLoading,
  messages,
  prompt,
  slots,
})
