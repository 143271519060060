import React from 'react'

const CloseIcon: React.FC = () => {
  return (
    <svg width="18px" height="16px" viewBox="0 0 18 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g
        id="Claims-portal-close-icon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g id="360_Menu-close" transform="translate(-23.000000, -87.000000)">
          <g id="Group" transform="translate(24.000000, 87.000000)">
            <path d="M16,0 L0.941176471,16" id="Path-4" />
            <path d="M0,0 L16,16" id="Path-5" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default CloseIcon
