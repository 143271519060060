import React from 'react'

const ZeroMoney: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
      <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1">
        <path
          fill="#27AEE4"
          d="M6.143 39.561v-3.163C2.446 36.05.424 33.734.076 29.45h3.457c.13 1.196.403 2.104.816 2.723.413.62 1.01 1.017 1.794 1.19V25.57l-.555-.196c-1.761-.63-3.066-1.468-3.914-2.511C.826 21.819.402 20.515.402 18.949c0-1.652.522-3.044 1.566-4.175 1.044-1.13 2.435-1.793 4.175-1.99V9.948H8.23v2.838c1.587.174 2.848.76 3.783 1.761.935 1 1.577 2.435 1.924 4.305l-3.228.522c-.218-1.065-.522-1.88-.914-2.446a2.697 2.697 0 00-1.565-1.109v7.077l.685.229c1.848.674 3.19 1.543 4.028 2.609.837 1.065 1.255 2.435 1.255 4.11 0 1.826-.538 3.331-1.614 4.516s-2.528 1.865-4.354 2.039v3.163H6.143zM8.23 33.43c.826-.152 1.462-.522 1.908-1.109.446-.587.668-1.359.668-2.316 0-.935-.195-1.685-.587-2.25-.391-.565-1.054-1.044-1.99-1.435v7.11zM6.143 15.753c-1.566.304-2.349 1.348-2.349 3.13 0 .827.174 1.48.522 1.958.348.478.957.902 1.827 1.272v-6.36z"
          transform="translate(-737 -1188) translate(737 1188)"
        />
        <path
          fill="#224BC5"
          d="M38.268 52.121c-4.9 0-8.527-2.085-10.882-6.254-2.356-4.17-3.534-10.611-3.534-19.327 0-17.242 4.9-25.863 14.699-25.863 4.899 0 8.526 2.085 10.882 6.254 2.355 4.17 3.533 10.611 3.533 19.327 0 17.242-4.9 25.863-14.698 25.863zm.07-3.675c3.628 0 6.254-1.754 7.88-5.264 1.625-3.51 2.438-9.128 2.438-16.854 0-5.276-.354-9.516-1.06-12.72-.707-3.203-1.802-5.547-3.286-7.03-1.484-1.484-3.427-2.226-5.83-2.226-3.628 0-6.254 1.754-7.88 5.264-1.625 3.51-2.437 9.128-2.437 16.854 0 5.276.353 9.516 1.06 12.72.706 3.203 1.802 5.547 3.286 7.03 1.484 1.484 3.427 2.226 5.83 2.226z"
          transform="translate(-737 -1188) translate(737 1188)"
        />
      </g>
    </svg>
  )
}

export default ZeroMoney
