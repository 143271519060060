import { createApi } from '@reduxjs/toolkit/query/react'
import type {
  ClaimPhotosGetResponse,
  ClaimPhotosCreateResponse,
  ClaimPhotosCreateRequest,
  ClaimPhotoDetail,
  InsuranceClaim,
} from '@customers-api-client'
import type { ClaimUpdateRequest } from '@extend-services/claims-management'
import { baseQuery, X_EXTEND_ACCESS_TOKEN } from '../base-query'
import type {
  ClaimsSearchQueryStringOptions,
  InsuranceClaimsListResponse,
  InsuranceClaimsListQueryStringOptions,
} from './types'
import { safeBtoa } from '../../helpers'

const buildCacheKey = (qs: ClaimsSearchQueryStringOptions): string => {
  const { cursor, minLimit, ...qsWithoutPagination } = qs
  return safeBtoa(JSON.stringify(qsWithoutPagination))
}

export const claimsApi = createApi({
  baseQuery,
  reducerPath: 'Claims',
  tagTypes: ['claims', 'claim-notes', 'claim-photos'],
  endpoints: (build) => ({
    updateClaim: build.mutation<InsuranceClaim, { claimId: string; body: ClaimUpdateRequest }>({
      query: ({ claimId, body }) => ({
        url: `/claims-management/claims/${claimId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['claims'],
    }),
    getClaimPhotos: build.query<
      ClaimPhotosGetResponse,
      { claimId: string; filterStatuses?: Array<ClaimPhotoDetail['status']> }
    >({
      query: ({ claimId }) => {
        return {
          url: `claims-management/claims/${claimId}/photos`,
        }
      },
      transformResponse: (response: ClaimPhotosGetResponse, _meta, { filterStatuses = ['saved'] }) => ({
        photoDetails: response.photoDetails?.filter((photo) => filterStatuses.includes(photo.status)),
      }),
      providesTags: (_, _err, { claimId }) => [{ type: 'claim-photos', id: claimId }],
    }),
    createClaimPhoto: build.mutation<ClaimPhotosCreateResponse, { claimId: string; body: ClaimPhotosCreateRequest }>({
      query: ({ claimId, body }) => ({
        url: `claims-management/claims/${claimId}/photos`,
        method: 'POST',
        body,
      }),
    }),
    getInsuranceClaim: build.query<InsuranceClaim, { claimId: string; accessToken?: string }>({
      query: ({ claimId, accessToken }) => ({
        url: `claims-management/claims/${claimId}`,
        params: { claimId },
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=2022-02-01;',
          ...(accessToken && { [X_EXTEND_ACCESS_TOKEN]: accessToken }),
        },
      }),
      providesTags: (_, _err, { claimId }) => [{ type: 'claims', id: claimId }],
    }),
    submitClaim: build.mutation<InsuranceClaim, { claimId: string }>({
      query: ({ claimId }) => ({
        url: `claims-management/claims/${claimId}/submit`,
        params: { claimId },
        method: 'POST',
      }),
      invalidatesTags: ['claims'],
    }),
    listInsuranceClaims: build.query<InsuranceClaimsListResponse, InsuranceClaimsListQueryStringOptions>({
      query: (qs: InsuranceClaimsListQueryStringOptions) => ({
        url: 'claims-management/claims',
        params: qs,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=2022-02-01;',
        },
      }),
      providesTags: (_, _err, qs) => {
        return [{ type: 'claims', id: buildCacheKey(qs) }]
      },
      async onCacheEntryAdded(
        arg: InsuranceClaimsListQueryStringOptions,
        { getState, updateCachedData, cacheDataLoaded },
      ) {
        const { cursor } = arg
        const { Claims } = getState()
        if (cursor?.length) {
          const queryValues = Claims.queries
          const cacheKey = buildCacheKey(arg)
          const getCachedKeys = Claims.provided.claims[cacheKey]
          const cachedQueries = getCachedKeys.reduce((newArr: InsuranceClaimsListResponse['items'], cachedDataKey) => {
            const cache = queryValues[cachedDataKey]
            const { items } = cache?.data as InsuranceClaimsListResponse
            return [...newArr, ...items]
          }, [])
          try {
            const { data } = await cacheDataLoaded

            updateCachedData((draft) => ({
              ...draft,
              items: [...cachedQueries, ...data.items],
            }))
          } catch (err) {
            console.error(err)
          }
        }
      },
    }),
  }),
})

export const {
  useUpdateClaimMutation,
  useGetClaimPhotosQuery,
  useCreateClaimPhotoMutation,
  useGetInsuranceClaimQuery,
  useListInsuranceClaimsQuery,
  useSubmitClaimMutation,
} = claimsApi
