import type { MyExtendState } from './my-extend'
import type { PollState } from './poll'
import { myExtendReducer } from './my-extend'
import { pollReducer } from './poll'

interface SlicesState {
  myExtend: MyExtendState
  poll: PollState
}

const slicesReducers = {
  myExtend: myExtendReducer,
  poll: pollReducer,
}

export type { SlicesState }
export { slicesReducers }
