import * as incredibot from './api/claims-assistant'
import * as claimsPhotos from './api/claim-photos'

interface ExtendApiClient {
  claimsPhotos: typeof claimsPhotos
  incredibot: typeof incredibot
}

export const client: ExtendApiClient = {
  claimsPhotos,
  incredibot,
}
