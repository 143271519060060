import type { FC } from 'react'
import React from 'react'
import { Badge } from '@customers-ui'
import styled from '@emotion/styled'
import type { Claim } from '@customers-api-client'

interface MatchColorToStatusProp {
  status: string
  isClaimCompleted: boolean
  claim?: Claim
}
const MatchColorToStatus: FC<MatchColorToStatusProp> = ({ status, isClaimCompleted }) => {
  if (status === 'review') {
    return (
      <ClaimStatusBadge dataCy="claimStatus" status="warning">
        In review
      </ClaimStatusBadge>
    )
  }
  if (status === 'denied') {
    return (
      <ClaimStatusBadge dataCy="claimStatus" status="danger">
        Denied
      </ClaimStatusBadge>
    )
  }
  if (status === 'fulfilled' || isClaimCompleted) {
    return (
      <ClaimStatusBadge dataCy="claimStatus" status="success">
        Fulfilled
      </ClaimStatusBadge>
    )
  }

  if (status === 'closed') {
    return (
      <ClaimStatusBadge dataCy="claimStatus" status="success">
        Closed - No Service Required
      </ClaimStatusBadge>
    )
  }

  return (
    <ClaimStatusBadge dataCy="claimStatus" status="info">
      In progress
    </ClaimStatusBadge>
  )
}
const ClaimStatusBadge = styled(Badge)({
  display: 'inline-block',
  boxSizing: 'border-box',
  textAlign: 'center',
  width: 'fit-content',
  fontSize: 14,
  lineHeight: '28px',
  padding: '0 12px',
  borderRadius: '4px',
})
export { MatchColorToStatus }
