import type { FC, ComponentProps } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Text as ChakraText, Heading } from '@chakra-ui/react'

import type { TextType } from './text.utils'
import { textStyles } from './text.utils'

interface TextProps extends Omit<ComponentProps<typeof ChakraText>, 'noOfLines'> {
  className?: string
  type?: TextType
  isHeading?: boolean
  numberOfLines?: number
}

const Text: FC<TextProps> = ({ children, className, type, isHeading, numberOfLines, ...props }) => {
  if (isHeading) {
    return (
      <Heading {...HeadingStyles} className={className}>
        <StyledText {...props} type={type} noOfLines={numberOfLines}>
          {children}
        </StyledText>
      </Heading>
    )
  }

  return (
    <StyledText {...props} type={type} className={className} noOfLines={numberOfLines}>
      {children}
    </StyledText>
  )
}

const StyledText = styled(ChakraText)(({ type }) => ({
  ...textStyles(type),
}))

const HeadingStyles = {
  fontWeight: 'unset',
  fontSize: 'unset',
  lineHeight: 'unset',
}

export { Text }
