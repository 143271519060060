import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'

export interface GridProps {
  direction?: 'row' | 'column'
  align?: string
  margin?: string
  gap?: number
  className?: string
  dataCy?: string
}

const Grid: FC<GridProps> = ({ children, direction = 'row', align = 'left', margin, gap, className, dataCy }) => {
  return (
    <StyledGrid {...{ className, direction, align, margin, gap }} data-cy={dataCy}>
      {children}
    </StyledGrid>
  )
}

const StyledGrid = styled.div<{
  direction: 'row' | 'column'
  align: string
  margin?: string
  gap?: number
}>(({ direction, align, margin, gap }) => ({
  display: 'flex',
  flexDirection: direction,
  justifyContent: align,
  margin: margin ?? 0,
  gap,
}))

export { Grid }
