import React, { useEffect, useRef, useState } from 'react'
import type { ConnectRequest, ConnectResponse, Slot, SlotValue, UpdateResponse } from '@extend-incredibot/types'
import { customLogger } from '@extend/client-helpers'
import type { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { getRTKQueryErrorMessage, useConnectMutation, useUpdateMutation } from '@customers-api-rtk-query'
import { ClaimSource } from '@customers-api-client'
import { ContentLayout, Toast, bp } from '@customers-ui'
import styled from '@emotion/styled'
import { Button, HeadingLarge } from '@extend/zen'
import ReactMarkdown from 'react-markdown'
import { PROMPT_MAP } from './user-input/types'
import { getMessagesContent } from '../../lib/chat-utils'
import { setPollState } from '../../store/slices/poll'

const FileClaimPage: FC = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  const [currentReply, setCurrentReply] = useState<Omit<ConnectResponse, 'accessToken'> | UpdateResponse | undefined>()
  const [key, setKey] = useState(0)

  const { contractId, orderId, lineItemIds, shipmentId } = location.state as Partial<ConnectRequest>

  const [
    connect,
    {
      isLoading: isConnectLoading,
      isSuccess: isConnectSuccess,
      error: connectError,
      isError: isConnectError,
      data: connectReply,
    },
  ] = useConnectMutation()

  const [
    update,
    {
      isLoading: isUpdating,
      isSuccess: isUpdateSuccess,
      error: updateError,
      isError: isUpdateError,
      data: updateReply,
    },
  ] = useUpdateMutation()

  /**
   * This page should not be navigated to directly unless referred from my plans or contract details
   */
  useEffect(() => {
    if (!contractId && !orderId) {
      const referrer = document.referrer
      customLogger.warn('Missing contractId and orderId. Navigating back to my_plans', { referrer })
      history.push('/my_plans')
    } else {
      connect({
        source: ClaimSource.chatbot,
        filedBy: {
          firstName: 'Kaley',
          lastName: 'Chatbot',
        },
        contractId,
        orderId,
        lineItemIds,
        shipmentId,
      })
    }
  }, [contractId, orderId, history])

  useEffect(() => {
    if (isConnectSuccess) {
      setCurrentReply(connectReply)
    }
  }, [connectReply, isConnectSuccess])

  useEffect(() => {
    if (isUpdateSuccess) {
      setCurrentReply(updateReply)
    }
  }, [updateReply, isUpdateSuccess])

  /**
   * We need to check the previous reply against the current reply coming in to prevent
   * state from leaking into the next prompt. For example, in a scenario where we would have
   * back-to-back MultiSelect prompts, using a key will prevent the previously selected option index
   * from carrying over to the new prompt.
   */
  const prevReplyRef = useRef(currentReply)
  useEffect(() => {
    if (prevReplyRef.current !== currentReply) {
      setKey((prevKey) => prevKey + 1)
      prevReplyRef.current = currentReply
    }
  }, [currentReply])

  useEffect(() => {
    const { poll } = currentReply || {}
    if (poll) {
      dispatch(setPollState(JSON.stringify(poll)))
    }
  }, [currentReply])

  const handleUpdate = (slot: Slot, slotValue: SlotValue): void => {
    const sessionToken = connectReply?.accessToken
    if (sessionToken) {
      update({ accessToken: sessionToken, data: { slot, slotValue } })
    }
  }

  const isLoading = isConnectLoading || isUpdating
  const hasError = Boolean(connectError || updateError)
  // Error logging for requests
  const [hasLoggedError, setHasLoggedError] = useState(false)
  if (hasError && !hasLoggedError) {
    const errorMessage = getRTKQueryErrorMessage(connectError || updateError)
    customLogger.warn(`[Err: File Claim Page]: ${errorMessage}`, {
      contractId,
      orderId,
      lineItemIds,
      shipmentId,
      isConnectError,
      isUpdateError,
    })
    setHasLoggedError(true)
  }

  const handleReload = (): void => {
    window.location.reload()
  }

  if (hasError) {
    return (
      <Toast message="We've encountered an error. Please refresh this page." type="danger" isVisible={hasError}>
        <Button onClick={handleReload} color="red" text="Refresh" emphasis="medium" />
      </Toast>
    )
  }

  const messageString = currentReply ? getMessagesContent(currentReply?.messages) ?? '' : undefined
  const { prompt } = currentReply || {}
  const PromptComponent = prompt ? PROMPT_MAP[prompt.type] : null
  return (
    <ContentLayout>
      <FormWrapper>
        {currentReply && messageString && PromptComponent && (
          <>
            <HeadingLarge>
              <ReactMarkdown>{messageString as string}</ReactMarkdown>
            </HeadingLarge>
            <PromptComponent onSubmit={handleUpdate} reply={currentReply} isLoading={isLoading} key={key} />
          </>
        )}
      </FormWrapper>
    </ContentLayout>
  )
}

const FormWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  [bp.desktop]: {
    width: '693px',
    height: '536px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '70px',
    gap: 32,
  },
  [bp.mobile]: {
    gap: 16,
    padding: '16px 24px 0px 24px',
    textAlign: 'center',
    marginTop: '40px',
  },
})

export { FileClaimPage }
