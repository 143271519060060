import type { Claim, MappedClaimDetails, ClaimDetails } from '../../types/claim'
import type { EntitlementSearchByOrdersResponse } from '@customers-api-rtk-query'

// This method returns an object keyed by claim id with the value being an object containing the claim and the associated order
export const mapToClaimDetails = (
  claims: Claim[],
  emsOrdersResponse?: EntitlementSearchByOrdersResponse[],
): MappedClaimDetails => {
  if (!emsOrdersResponse) return {}

  // Flatten the products and entitlements into a single array of { order, product, entitlement }
  const flattenedOrders = emsOrdersResponse.flatMap(
    (order) =>
      order.products?.flatMap(
        (product) =>
          product.entitlements?.map((entitlement) => ({
            order,
            product,
            entitlement,
            contractIds: Object.keys(order.contracts || {}),
          })) || [],
      ) || [],
  )

  const detail = claims.reduce((acc: MappedClaimDetails, claim: Claim) => {
    const matchingOrder = flattenedOrders.find(({ entitlement, order, contractIds }) => {
      if (claim.entitlementIds?.length) {
        return (claim?.entitlementIds ?? []).includes(entitlement.id)
      }

      if (claim.orderId) {
        return claim.orderId === order.id
      }

      if (!claim.contractId) return false

      return contractIds.includes(claim.contractId)
    })

    // due to lower env data integrity issues, we may have claims without a matching order, so we 'continue' here
    if (!matchingOrder) return acc

    const matchingDetail: ClaimDetails = {
      claim,
      emsData: matchingOrder.order,
    }
    acc[claim.id.toString()] = matchingDetail

    return acc
  }, {})

  return detail
}
