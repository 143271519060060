import type { Action } from '../../actions'
import type { ErrorReducerState } from '../../reducers/factories'
import { createErrorReducer } from '../../reducers/factories'

export type AuthErrorReducerState = ErrorReducerState | null

export const initialState: AuthErrorReducerState = null

const error = createErrorReducer<Action>(
  ['REQUEST_CONSUMER_TOKEN_ERROR'],
  ['USER_LOGIN_REQUEST', 'USER_LOGIN_SUCCESS', 'USER_LOGOUT', 'USER_AUTH_ERROR_RESET'],
)

export default error
