import type { FC } from 'react'
import React from 'react'

export const BrokenPhoneImage: FC = () => {
  return (
    <svg id="accidentalDamage" xmlns="http://www.w3.org/2000/svg" width="79" height="70" viewBox="0 0 79 70">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-135 -1377)">
          <g transform="translate(136 1097)">
            <g transform="translate(0 280)">
              <g>
                <g transform="translate(19.72)">
                  <path
                    stroke="#224BC5"
                    strokeWidth="3.00"
                    d="M5.636 42.603l-4.326 6.65v9.278a9.144 9.144 0 002.687 6.486 9.144 9.144 0 006.486 2.687h19.22a9.144 9.144 0 006.486-2.687 9.144 9.144 0 002.686-6.486v-9.565l-6.692-5.3-5.673 3.96-5.3-3.874-7.382 7.331-8.192-8.48zM29.703 1.31h-19.22a9.144 9.144 0 00-6.486 2.687 9.144 9.144 0 00-2.686 6.486v21.93l5.995-4.72 6.481 8.568 7.253-10.736 5.642 7.753 5.363-5.398 6.83 4.324v-21.72a9.144 9.144 0 00-2.686-6.487 9.144 9.144 0 00-6.486-2.687z"
                  />
                  <rect width="3.494" height="3.12" x="6.989" y="5.949" fill="#27AEE4" rx="1.31" />
                  <rect width="10.483" height="3.12" x="12.231" y="5.949" fill="#27AEE4" rx="1.31" />
                  <circle cx="20.28" cy="60.84" r="3.64" />
                </g>
                <g
                  stroke="#27AEE4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3.12"
                  transform="rotate(-27 105.085 -112.972)"
                >
                  <path d="M0.52 7.02L8.32 13.78" />
                  <path d="M3.12 1.3L9.36 1.3" />
                </g>
                <g
                  stroke="#27AEE4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3.12"
                  transform="scale(-1 1) rotate(20 -95.91 -14.824)"
                >
                  <path d="M1.3 5.72L10.14 0" />
                  <path d="M3.12 12.74L9.36 12.74" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default BrokenPhoneImage
