import { createContext } from 'react'

export interface TrackingContextProps {
  trackEvent: (eventName: string, properties: Record<string, any>) => void
}

export const initialContext: TrackingContextProps = {
  trackEvent: function noop() {},
}

export const TrackingContext = createContext<TrackingContextProps>(initialContext)
