import * as Yup from 'yup'
import { validate } from '@extend/client-helpers'
import { useIntl } from 'react-intl'
import { validatePhoneNumber } from '@extend/client-helpers'

const MAX_MESSAGE_LENGTH = 1000
const MAX_NAME_LENGTH = 100

export const useContactFormSchema = (): Yup.ObjectSchema => {
  const intl = useIntl()
  return Yup.object()
    .shape({
      name: Yup.string()
        .trim()
        .max(
          MAX_NAME_LENGTH,
          intl.formatMessage(
            {
              id: 'SUPPORT_FORM_NAME_MAXIMUM_LENGTH',
              defaultMessage: 'Please use only up to {MAX_NAME_LENGTH} characters',
              description: 'Notification that name has exceeded maximum length',
            },
            {
              MAX_NAME_LENGTH,
            },
          ),
        )
        .required(
          intl.formatMessage({
            id: 'SUPPORT_FORM_NAME_VALIDATION',
            defaultMessage: 'Please enter your name',
            description: 'Notification for invalid name',
          }),
        ),
      email: Yup.string()
        .trim()
        .test(
          'email-validation',
          intl.formatMessage({
            id: 'SUPPORT_FORM_EMAIL_VALIDATION',
            defaultMessage: 'Please enter a valid email address',
            description: 'Notification for invalid email',
          }),
          (value): boolean => {
            return !value || validate.isValidEmail(value)
          },
        )
        .required(
          intl.formatMessage({
            id: 'SUPPORT_FORM_EMAIL_VALIDATION',
            defaultMessage: 'Please enter a valid email address',
            description: 'Notification for invalid email',
          }),
        ),
      phoneNumber: Yup.string()
        .trim()
        .test(
          'is-valid-e.164-number',
          intl.formatMessage({
            id: 'SUPPORT_FORM_PHONE_NUMBER_VALIDATION',
            defaultMessage: 'Please enter a valid phone number',
            description: 'Notification for invalid phone number',
          }),
          (value) => {
            return validatePhoneNumber(value, 'US')
          },
        )
        .notRequired(),
      requestType: Yup.string().test(
        'topic-validation',
        intl.formatMessage({
          id: 'SUPPORT_FORM_SUPPORT_TOPIC_VALIDATION',
          defaultMessage: 'Please make a selection',
          description: 'Notification that selection of support topic is required',
        }),
        (value): boolean => {
          return value !== undefined && value !== 'selectOption'
        },
      ),
      message: Yup.string()
        .trim()
        .max(
          MAX_MESSAGE_LENGTH,
          intl.formatMessage(
            {
              id: 'SUPPORT_FORM_MESSAGE_MAXIMUM_LENGTH',
              defaultMessage: 'Please use only up to {MAX_MESSAGE_LENGTH} characters',
              description: 'Notification that message has exceeded maximum length',
            },
            {
              MAX_MESSAGE_LENGTH,
            },
          ),
        )
        .required(
          intl.formatMessage({
            id: 'SUPPORT_FORM_MESSAGE_VALIDATION',
            defaultMessage: 'Send us a message',
            description: 'Notification for invalid message',
          }),
        ),
    })
    .defined()
}
