import type { FC, MutableRefObject } from 'react'
import React, { useRef, useState } from 'react'
import { animated, useSpring } from 'react-spring'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import { bp } from '@extend/client-helpers'
import { svgs } from '../../svgs'

const { Cross } = svgs
type Title = string | JSX.Element
type Body = string | JSX.Element

interface AccordionProps {
  list: Array<[Title, Body]>
}

const Accordion: FC<AccordionProps> = ({ list }) => {
  const [activeAccordionIndex, setActiveAccordionIndex] = useState<number | null>(null)

  const handleClickAccordion = (selectedIndex: number) => {
    return (): void => {
      const toggledIndex = activeAccordionIndex === selectedIndex ? null : selectedIndex
      setActiveAccordionIndex(toggledIndex)
    }
  }

  return (
    <AccordionList>
      {list.map(([title, body], index) => {
        const accordionItemProps = {
          body,
          controlId: `faqAccordionButton${index}`,
          handleClick: handleClickAccordion(index),
          isActive: activeAccordionIndex === index,
          panelId: `faqAccordionPanel${index}`, // for setting aria-attributes
          title,
        }

        const key = typeof title === 'string' ? title : title.props.id
        return <AccordionItem key={key} {...accordionItemProps} />
      })}
    </AccordionList>
  )
}

interface AccordionItemProps {
  body: string | JSX.Element
  controlId: string
  handleClick: () => void
  isActive: boolean
  panelId: string
  title: string | JSX.Element
}

const AccordionItem: FC<AccordionItemProps> = ({ body, controlId, handleClick, isActive, panelId, title }) => {
  // capture the accordion body DOM height on render, to
  // store the height range for the spring animation when toggled
  const bodyRef = useRef() as MutableRefObject<HTMLDivElement>
  const bodyHeight = bodyRef.current?.clientHeight ?? 0

  const { color, height, transform } = useSpring({
    color: isActive ? COLOR.BLUE[800] : COLOR.BLUE[1000],
    height: isActive ? bodyHeight : 0,
    transform: `rotate(${isActive ? '45deg' : '0deg'})`,
  })

  return (
    <AccordionListItem>
      <AccordionHeading
        aria-controls={panelId}
        aria-disabled="false"
        aria-expanded={isActive ? 'true' : 'false'}
        id={controlId}
        onClick={handleClick}
        role="button"
        style={{ color }}
      >
        <HeaderText active={isActive}>{title}</HeaderText>
        <HeaderIcon style={{ transform }}>
          <Cross />
        </HeaderIcon>
      </AccordionHeading>
      {/* in order to not paint overlapping borders when an accordion item is not expanded */}
      {isActive && <PanelDivider />}
      <AccordionPanel aria-labelledby={controlId} id={panelId} role="region" style={{ height }}>
        <PanelBody ref={bodyRef}>{body}</PanelBody>
      </AccordionPanel>
    </AccordionListItem>
  )
}

const AccordionList = styled.ul({
  margin: '0 auto',
  maxWidth: 900,
  padding: 0,
  width: '100%',
})

const AccordionListItem = styled.li({
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  listStyle: 'none',
  // style offset so the overlapping borders between lists
  // are placed directly on top of each other (vs. adjacently)
  marginBottom: -1,
  textAlign: 'left',
  position: 'relative',
  [bp.md]: {
    flexBasis: '30%',
  },
  '&:nth-of-type(1)': {
    borderRadius: '10px 10px 0 0',
  },
  '&:last-child': {
    borderRadius: '0 0 10px 10px',
  },
})

export const AccordionHeading = styled(animated.div)({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '16px 24px',
  [bp.md]: {
    padding: '24px 32px',
  },
})

interface HeaderTextProps {
  active: boolean
}

const HeaderText = styled.div<HeaderTextProps>(({ active }) => ({
  fontSize: 16,
  fontWeight: active ? 700 : 400,
  lineHeight: '24px',
  margin: 0,
  // per design mocks, set width dynamically to
  //  reduce likelihood of linebreak
  maxWidth: '85%',
  [bp.md]: {
    fontSize: 20,
    lineHeight: '16px',
    maxWidth: '100%',
  },
}))

const HeaderIcon = styled(animated.div)({ display: 'inline-block' })

const AccordionPanel = styled(animated.div)({
  maxHeight: '100%',
  overflowY: 'hidden',
})

const PanelDivider = styled.div({
  backgroundColor: COLOR.NEUTRAL[300],
  width: '100%',
  height: 1,
})

const PanelBody = styled.div({
  whiteSpace: 'pre-line',
  [bp.md]: {
    fontSize: 20,
    lineHeight: '32px',
    padding: '24px 32px',
  },
  [bp.maxWidthMd]: {
    padding: '16px 24px',
    fontSize: 16,
    lineHeight: '24px',
  },
})

export type { AccordionProps }
export { Accordion }
