import * as pages from '../pages'

// Routes in this file are for Contract Transfer
// Should be conditionally used via FF
// Once FF removed, move into routes.ts or protected-routes.ts

export const routes = [
  {
    path: '/my_plans/:id/transfer',
    component: pages.ContractTransfer,
  },
]
