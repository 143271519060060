import React from 'react'
import type { FC } from 'react'
import { Global } from '@emotion/react'
import { extendTheme } from '@chakra-ui/react'
import { COLOR } from '@extend/zen'

const HEADER_STYLES = {
  fontFamily: 'inherit',
  textDecoration: 'none',
  fontStyle: 'normal',
}

const GlobalTheme = extendTheme({
  styles: {
    global: {
      html: {
        height: '100%',
        margin: 0,
        padding: 0,
      },
      body: {
        fontFamily: "'Nunito Sans', Arial, Helvetica, sans-serif",
        overflowX: 'hidden',
        height: '100%',
        margin: 0,
        padding: 0,
        background: COLOR.WHITE,
        fontSize: '100%',
      },
      a: {
        fontFamily: 'inherit',
        textDecoration: 'none',
      },
      button: { padding: 0 },
      h1: HEADER_STYLES,
      h2: HEADER_STYLES,
      h3: HEADER_STYLES,
      h4: HEADER_STYLES,
      h5: HEADER_STYLES,
      h6: HEADER_STYLES,
    },
  },
})

const GlobalStyles: FC = () => (
  <Global
    styles={`
    body {
      #root {
        height: 100%;
      }
    
      * {
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent;
      }
    
      input,
      button,
      textarea {
        font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
        // Forces FF to calculate the number of rows correctly in a <textarea>:
        // https://bugzilla.mozilla.org/show_bug.cgi?id=33654
        @supports (-moz-appearance: none) {
          overflow-x: hidden;
        }
      }
    }
    
    @media print {
      html, body {
          height: auto;    
      }
    }
  `}
  />
)

export { GlobalTheme, GlobalStyles }
