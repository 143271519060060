import type { ReplacementFulfillmentMethod } from '@extend-services/service-orders/dist/src/client/api-rest/v2/models'
import type {
  Contract,
  InsuranceClaim,
  StoreConsumerReadableProperties,
  SupportedCurrencyCode,
  ServiceOrder,
  VirtualCardFulfillmentMetaData,
} from '@customers-api-client'
import {
  useGetContractQuery,
  useGetInsuranceClaimQuery,
  useGetServiceOrdersByClaimQuery,
  useGetStoreConsumerQuery,
  useGetVirtualCardRemainingValueQuery,
  useGetVirtualCardUserAgreementQuery,
  useSubmitVirtualCardUserAgreementMutation,
} from '@customers-api-rtk-query'

export const useGetVcData = (
  claimId: string,
): {
  isLoading: boolean
  isVirtualCardFulfillment: boolean
  isCanada: boolean
  isRequestError: boolean
  hasAgreementNeverBeenAccepted: boolean
  isUserAgreementLoading: boolean
  isVirtualCardUserAgreementSubmitting: boolean
  claim?: InsuranceClaim
  contract?: Contract
  serviceOrder?: ServiceOrder | null
  wallet?: { remainingValue: { amount: number; currencyCode?: SupportedCurrencyCode } } | null
  walletId?: string
  isVirtualCardUserAgreementSubmitSuccess: boolean
  replacementFulfillmentMethod: ReplacementFulfillmentMethod
  store?: StoreConsumerReadableProperties
  submitUserAgreement: () => Promise<void>
} => {
  const [
    submitVirtualCardUserAgreement,
    { isSuccess: isVirtualCardUserAgreementSubmitSuccess, isLoading: isVirtualCardUserAgreementSubmitting },
  ] = useSubmitVirtualCardUserAgreementMutation()

  const submitUserAgreement = async (): Promise<void> => {
    await submitVirtualCardUserAgreement({})
  }

  const { isLoading: isUserAgreementLoading, error: userAgreementError } = useGetVirtualCardUserAgreementQuery({})

  const {
    data: claim,
    isLoading: isClaimLoading,
    isError: isClaimError,
  } = useGetInsuranceClaimQuery(
    {
      claimId: claimId ?? '',
    },
    { skip: !claimId },
  )

  const {
    data: contract,
    isLoading: isContractLoading,
    isError: isContractError,
  } = useGetContractQuery(
    {
      contractId: claim?.contractId ?? '',
    },
    { skip: !claim },
  )

  const {
    data: serviceOrder,
    isLoading: isServiceOrderLoading,
    isError: isServiceOrderError,
  } = useGetServiceOrdersByClaimQuery(
    {
      claimId: claim?.id ?? '',
    },
    {
      skip: !claim,
      selectFromResult: ({ data, isLoading, isError }) => ({
        data: data?.length ? [...data].sort((a, b) => b.updatedAt - a.updatedAt)[0] : null,
        isLoading,
        isError,
      }),
    },
  )
  const { data: store } = useGetStoreConsumerQuery(
    {
      storeId: claim?.sellerId ?? '',
      includeDeleted: 'true',
    },
    { skip: !claim?.sellerId },
  )
  //
  const walletId = (serviceOrder?.fulfillmentMetaData as VirtualCardFulfillmentMetaData)?.walletId
  const {
    data: wallet,
    isLoading: isRemainingValueLoading,
    isError: isRemainingValueError,
  } = useGetVirtualCardRemainingValueQuery({ walletId }, { skip: !walletId })

  // no previous existing agreement was found for the current accessToken in the DB
  const hasAgreementNeverBeenAccepted = Boolean(
    userAgreementError &&
      'data' in userAgreementError &&
      userAgreementError?.status === 404 &&
      !isVirtualCardUserAgreementSubmitSuccess,
  )

  const isRequestError = isServiceOrderError || isContractError || isClaimError || isRemainingValueError

  const isCanada =
    (wallet && wallet.remainingValue?.currencyCode && wallet.remainingValue?.currencyCode === 'CAD') ?? false

  const isLoading =
    isClaimLoading ||
    isContractLoading ||
    isServiceOrderLoading ||
    isRemainingValueLoading ||
    (!isVirtualCardUserAgreementSubmitSuccess && isUserAgreementLoading)
  const replacementFulfillmentMethod = serviceOrder?.configurations?.replacementFulfillmentMethod || 'manual'
  const isVirtualCardFulfillment = replacementFulfillmentMethod === 'virtual_card'

  return {
    isLoading,
    isVirtualCardFulfillment,
    isCanada,
    isRequestError,
    hasAgreementNeverBeenAccepted,
    isVirtualCardUserAgreementSubmitSuccess,
    isVirtualCardUserAgreementSubmitting,
    claim,
    contract,
    serviceOrder,
    walletId,
    wallet,
    store,
    submitUserAgreement,
    isUserAgreementLoading,
    replacementFulfillmentMethod,
  }
}
