import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { COLOR, bp, Grid, Button } from '@customers-ui'
import type { FormSubmitData, CustomerSubmitDataTypes } from '../../components/forms/customer-form-template.types'
import type { ContractsUpdateResponse } from '../../types/contract'
import { ExtendedWarrantyProductDetails } from './product-details/extended-warranty-product-details'
import { CategoryProductDetails } from './product-details/category-product-details'
import { TransferCustomerDetails } from './transfer-customer-details'

interface TransferSummaryViewProps {
  contract: ContractsUpdateResponse
  formData: CustomerSubmitDataTypes
}

const TransferSummaryView: FC<TransferSummaryViewProps> = ({ contract, formData }) => {
  const history = useHistory()

  const routeToHome = (): void => {
    history.push('/my_plans')
  }

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        history.push('/my_plans')
      }
    }
  })

  return (
    <TransferConfirmGrid dataCy="transfer-summary-grid">
      <Title data-cy="title">Transfer Summary</Title>
      {contract.type === 'pcrs' && <ExtendedWarrantyProductDetails contract={contract} />}
      {contract.type === 'category' && <CategoryProductDetails contract={contract} />}
      <StyledCustomerDetails formData={formData as FormSubmitData} dataCy="customer-form" />
      <ReturnButton kind="primary" text="Return to home" onClick={routeToHome} dataCy="return-button" />
    </TransferConfirmGrid>
  )
}

const TransferConfirmGrid = styled(Grid)({
  flexDirection: 'column',
  width: '100%',
  [bp.mobile]: {
    padding: '0 20px',
  },
  [bp.desktop]: {
    padding: 0,
  },
})

const Title = styled.div({
  color: COLOR.NEUTRAL[1000],
  [bp.mobile]: {
    margin: '40px 0 16px',
    fontSize: 20,
    lineHeight: '28px',
  },
  [bp.desktop]: {
    width: 668,
    margin: '60px auto 32px',
    fontSize: 28,
    lineHeight: '36px',
  },
})

const StyledCustomerDetails = styled(TransferCustomerDetails)({
  [bp.mobile]: {
    margin: '16px 0 24px',
  },
  [bp.desktop]: {
    margin: '32px auto 40px',
  },
})

const ReturnButton = styled(Button)({
  [bp.mobile]: {
    width: 'auto',
    height: 38,
    margin: '40px auto 80px',
    borderRadius: 20,
    fontSize: 16,
    padding: '8px 24px',
  },
  [bp.desktop]: {
    width: 'auto',
    height: 47,
    margin: '24px auto 60px',
    borderRadius: 24,
    fontSize: 20,
    padding: '10px 48px',
  },
})

export { TransferSummaryView }
