import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'

import { bp } from '../../utils/breakpoints'

interface ViewFieldProps {
  className?: string
  title: string
  value?: string
  dataCy?: string
}

const ViewField: FC<ViewFieldProps> = ({ className, title, value, dataCy }) => {
  return (
    <FieldWrapper className={className}>
      <FieldTitle>{title}</FieldTitle>
      <FieldContent data-cy={dataCy}>{value}</FieldContent>
    </FieldWrapper>
  )
}
export const FieldWrapper = styled.div({
  flex: 1,
})

export const FieldTitle = styled.div({
  color: COLOR.NEUTRAL[1000],
  fontWeight: 'bold',
  [bp.mobile]: {
    fontSize: 13,
    lineHeight: '16px',
  },
  [bp.desktop]: {
    fontSize: 12,
    lineHeight: '18px',
  },
})

export const FieldContent = styled.div({
  whiteSpace: 'pre-wrap',
  marginTop: 8,
  color: COLOR.NEUTRAL[800],
  [bp.mobile]: {
    fontSize: 15,
    lineHeight: '20px',
  },
  [bp.desktop]: {
    fontSize: 16,
    lineHeight: '24px',
    paddingRight: 8,
  },
})

export { ViewField }
