import type { FC } from 'react'
import React from 'react'

const Manufactured: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="19" viewBox="0 0 22 19">
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        transform="translate(-424 -837) translate(424 837)"
      >
        <path d="M14.5 0.4998L14.5 7.4998 7.5 0.4998 7.5 7.4998 0.5 0.4998 0.5 7.4998 0.5 18.4998 21.5 18.4998 21.5 7.4998z" />
        <path d="M2.5 13.5L6.5 13.5 6.5 9.5 2.5 9.5z" />
        <path d="M15.5 13.5L19.5 13.5 19.5 9.5 15.5 9.5z" />
        <path d="M8.5 18.5L13.5 18.5 13.5 9.5 8.5 9.5z" />
      </g>
    </svg>
  )
}

export { Manufactured }
