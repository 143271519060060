import type { FulfillmentMethod } from '../../types/faq'

const manualOrGiftCardQuestions = [
  {
    question: 'Why did I get a gift card or other product for my replacement?',
    answer:
      'We’ve provided a gift card or like product of equivalent value because (a) the merchant indicated this fulfillment method or (b) the product may not be currently available.',
  },
  {
    question: 'How did you come up with the amount?',
    answer: 'Your replacement amount is equal to the original price of the product you purchased.',
  },
  {
    question: 'If I received a gift card can I purchase other things?',
    answer: 'Definitely! You have complete control as to how you want to use your gift card.',
  },
  {
    question: 'What if I want to purchase another protection plan?',
    answer:
      'The cost of the protection plan is not included in your replacement so you will have to purchase that separately.',
  },
]

export const CLAIM_FULFILLMENT_EN_US: FulfillmentMethod = {
  repair: [
    {
      question: 'Am I responsible for shipping costs?',
      answer: 'Nope! As a customer of Extend, you can enjoy free 2-way shipping on us.',
    },
    {
      question: 'How long do repairs generally take?',
      answer:
        'Depending on the damage, the process can range from 2-3 weeks. Note that factors like backordered parts may cause delays.',
    },
    {
      question: 'How are repair costs determined?',
      answer:
        'Once we receive your product, our technicians will evaluate the damage and determine the best cost for repairs.',
    },
    {
      question: 'What am I covered for?',
      answer:
        'Repair costs are covered by your coverage amount balance. Once you use it up, you will be responsible for any additional costs.',
    },
  ],
  virtual_card: [
    {
      question: ' Can I use my virtual card anywhere?',
      answer:
        "Virtual cards can only be used directly on the retailer's website. At the time of checkout, select the credit card option as your form of payment and enter your virtual card number. Your virtual cannot be processed as ShopPay, PayPal, or GooglePay.",
    },
    {
      question: 'I want a more expensive product than what I originally purchased. Will my card cover the difference?',
      answer:
        'You cannot exceed the balance on your virtual card. If your payment exceeds the purchase amount, you will need to use a second form of payment for the exceeding balance. Please refer to the merchant to inquire further.',
    },
    {
      question: 'What happens if my order is canceled?',
      answer:
        'If your order is canceled by you or your retailer, payment will be returned to your virtual card and any other secondary forms of payment within 72 hours.',
    },
    {
      question: 'Is my card transferable?',
      answer: 'Your virtual card cannot be transferred or refunded to any other person or form of payment.',
    },
    {
      question: 'What if I don’t use the full balance on my virtual card?',
      answer:
        'Your remaining balance is available to you until your virtual card is fully redeemed. There is no expiration on the funds you are entitled to.',
    },
    {
      question: 'When does my virtual card expire?',
      answer:
        'Your virtual card is valid through the date listed on the card and will continue to update every 7 days after it is activated. It will never expire.',
    },
  ],
  manual: manualOrGiftCardQuestions,
  gift_card: manualOrGiftCardQuestions,
}

export const CLAIM_FULFILLMENT_EN_CA: FulfillmentMethod = {
  repair: [
    {
      question: 'Am I responsible for shipping costs?',
      answer: 'Nope! As a customer of Extend, you can enjoy free 2-way shipping on us.',
    },
    {
      question: 'How long do repairs generally take?',
      answer:
        'Depending on the damage, the process can range from 2-3 weeks. Note that factors like backordered parts may cause delays.',
    },
    {
      question: 'How are repair costs determined?',
      answer:
        'Once we receive your product, our technicians will evaluate the damage and determine the best cost for repairs.',
    },
    {
      question: 'What am I covered for?',
      answer:
        'Repair costs are covered by your coverage amount balance. Once you use it up, you will be responsible for any additional costs.',
    },
  ],
  virtual_card: [
    {
      question: 'Why did I get a virtual card for my replacement?',
      answer:
        "We've provided a virtual card amount of equivalent value because (a) the merchant indicated this fulfillment method or (b) the product may not be currently available.",
    },
    {
      question: 'How did you come up with the amount?',
      answer: 'Your virtual card amount is equal to the original price of the product you purchased.',
    },
    {
      question: "What happens if I don't use the full amount?",
      answer: 'We will issue you a new card for the remaining replacement value via new email to access it.',
    },
    {
      question: 'Can I buy other things with my virtual card?',
      answer:
        'Definitely! You have complete control over what you buy, as long as it is at the original merchant store.',
    },
  ],
  manual: manualOrGiftCardQuestions,
  gift_card: manualOrGiftCardQuestions,
}

export const CLAIM_FULFILLMENT_FR_CA: FulfillmentMethod = {
  virtual_card: [
    {
      question: "Pourquoi est-ce que j'ai obtenu une carte virtuelle pour mon remplacement?",
      answer:
        'Nous avons émis un crédit en magasin de valeur équivalente parce que : (a) le commerçant a indiqué cette méthode de compensation; ou (b) il se peut que le produit ne soit actuellement pas disponible.',
    },
    {
      question: 'Comment avez-vous calculé le montant?',
      answer: 'Le montant de votre carte virtuelle est égal au prix initial du produit que vous avez acheté.',
    },
    {
      question: 'Que se passe-t-il si je ne dépense pas tout le montant?',
      answer:
        "Nous vous enverrons un courriel pour accéder à une nouvelle carte d'une valeur correspondant au montant inutilisé pour le remplacement.",
    },
    {
      question: 'Est-ce que je peux acheter autre chose avec ma carte virtuelle?',
      answer:
        "Bien sûr! C'est vous qui décidez la façon dont vous voulez utiliser le montant de votre carte virtuelle approuvée chez Grand Magasin Boisé.",
    },
  ],
  manual: [{ question: '', answer: '' }],
  gift_card: [{ question: '', answer: '' }],
}
