import type { FC } from 'react'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { redact } from '@extend/client-helpers'
import { TrackingContext } from './tracking-context'

/**
 * Required higher-order component (HOC) for using the analytics in the client applications
 * Apply the HOC at the topmost level container / page to  use of the tracking context hook
 *  i.e.:
 *  <TrackingProvider>
 *     <App />
 *  <TrackingProvider>
 */
const TrackingProvider: FC = ({ children }) => {
  const location = useLocation()
  const [segmentStatus, setSegmentStatus] = useState<'idle' | 'loading' | 'ready' | 'error'>('idle')

  /**
   * Ensures that Segment's analytics snippet is injected globally
   */
  useEffect(() => {
    setSegmentStatus('loading')
    if (!window.analytics) {
      setSegmentStatus('error')
      return
    }
    window.analytics.ready(() => {
      setSegmentStatus('ready')
    })
  }, [])

  /**
   * Passively records page views on the targeted application
   * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#page
   */
  useEffect(() => {
    if (segmentStatus !== 'ready' || !window?.analytics) return
    window.analytics.page()
  }, [location, segmentStatus])

  /**
   * Records a targeted action performed by the site visitor
   * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track
   * @param {string} eventName - human-readable phrase to describe the event being tracked
   * @param {Object} properties - additional properties to be sent in Segment to get the event context
   */
  const trackEvent = useCallback(
    (eventName: string, properties: Record<string, any> = {}): void => {
      if (segmentStatus !== 'ready' || !window?.analytics) return
      window.analytics.track(eventName, redact(properties))
    },
    [segmentStatus],
  )

  return <TrackingContext.Provider value={{ trackEvent }}>{children}</TrackingContext.Provider>
}

export { TrackingProvider }
