import React from 'react'

const Power: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="57" viewBox="0 0 57 57">
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        transform="translate(-145 -1493) translate(147 1495)"
      >
        <path
          stroke="#27AEE4"
          d="M21.863 12.849L33.62 1.093a3.23 3.23 0 014.57 0l.013.013a3.23 3.23 0 010 4.57L26.458 17.42M35.647 26.565l11.722-11.722a3.23 3.23 0 014.57 0l.013.014a3.23 3.23 0 010 4.57l-11.71 11.71"
        />
        <path stroke="#224BC5" d="M0.146 48.316L8.167 40.295" />
        <path stroke="#224BC5" d="M12.75 44.878L4.729 52.899" />
        <path
          stroke="#224BC5"
          d="M43.748 34.629L18.416 9.42l-4.519 4.522 2.292 2.291-7.941 7.941c-4.473 4.473-4.473 11.729 0 16.204l4.418 4.419c4.478 4.475 11.734 4.475 16.207 0l7.94-7.939 2.292 2.292 4.643-4.521z"
        />
      </g>
    </svg>
  )
}

export default Power
