import * as pages from '../pages'

// Routes in this file are for PoPA Authentication
// Should be conditionally used via FF
// Once FF removed, move into routes.ts

export const routes = [
  {
    path: '/authentication',
    component: pages.MyExtendAuthenticationPage,
  },
]
