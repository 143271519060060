import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import styled from '@emotion/styled'
import { bp, COLOR, FormattedMessage } from '@customers-ui'
import { Button, Input, InputType, ToastColor, ToastDuration, useToaster } from '@extend/zen'
import { validate } from '@extend/client-helpers'
import { querystring, formatPhoneNumberOnChange, validatePhoneNumber, getCallingCode } from '@extend/client-helpers'
import { isMobile } from 'react-device-detect'
import { useIntl } from 'react-intl'
import type { UserInfo } from './myextend-authentication'

type QueryStringProps = {
  email: string
  phoneNumber: string
}

export interface MyExtendAuthFormProps {
  onSubmit: (userInfo: UserInfo) => void
  isVerification?: boolean
  isRestrictedLogin?: boolean
  isRestrictedEmail?: boolean
}

const MyExtendAuthForm: FC<MyExtendAuthFormProps> = ({
  onSubmit,
  isVerification = false,
  isRestrictedLogin = false,
  isRestrictedEmail = false,
}) => {
  const { search } = useLocation()
  const params = querystring.parse<QueryStringProps>(search)
  const [isEmailContext, setIsEmailContext] = useState<boolean>(
    isVerification || isRestrictedLogin ? isRestrictedEmail : !isMobile,
  )
  const [userInfo, setUserInfo] = useState<UserInfo>({
    email: params.email || '',
    phoneNumber: params.phoneNumber || '',
  })
  const intl = useIntl()
  const { toast } = useToaster()

  const isFormEmpty = useMemo(() => !userInfo.email && !userInfo.phoneNumber, [userInfo])

  const handleSubmit = (): void => {
    if (isEmailContext && (!validate.isValidEmail(userInfo.email) || isFormEmpty)) {
      toast({
        message: 'Wrong email address',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
      return
    }

    if (!isEmailContext && (!validatePhoneNumber(userInfo.phoneNumber, 'US') || isFormEmpty)) {
      toast({
        message: 'Wrong mobile phone number',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
      return
    }

    onSubmit(userInfo)
  }

  const resetFormValues = (): void => {
    setUserInfo({ email: '', phoneNumber: '' })
  }

  const toggleContext = (): void => {
    setIsEmailContext(!isEmailContext)
  }

  return (
    <Wrapper>
      <UpperContent>
        <Title>
          {isVerification ? (
            <FormattedMessage
              id="MYEXTEND_VERIFICATION_TITLE"
              defaultMessage="Let's verify your account"
              description="This is the title for the email authentication form"
            />
          ) : (
            <FormattedMessage
              id="MYEXTEND_AUTHENTICATION_TITLE"
              defaultMessage="Let's get you logged in"
              description="This is the title for the email authentication form"
            />
          )}
        </Title>
        <Content>
          <FormattedMessage
            id="MYEXTEND_AUTHENTICATION_INTRO_MESSAGE"
            defaultMessage="We'll send you a one time passcode to access your order details."
            description="This is an introductory message about the email authentication flow that explains the magic link process"
          />
        </Content>
        <InputWrapper data-qa="customers--myextend-authentication-form">
          {isEmailContext ? (
            <StyledInput
              data-cy="email-authentication-input"
              id="email"
              label={intl.formatMessage({
                id: 'MYEXTEND_AUTHENTICATION_EMAIL_LABEL',
                defaultMessage: 'Email address',
                description: "Prompt for the user's email address",
              })}
              type={InputType.text}
              value={userInfo.email}
              onChange={(e) => {
                setUserInfo({ email: e.target.value, phoneNumber: '' })
              }}
              placeholder="example@gmail.com"
            />
          ) : (
            <StyledInput
              data-cy="phone-authentication-input"
              id="phone"
              label={intl.formatMessage({
                id: 'MYEXTEND_AUTHENTICATION_PHONE_LABEL',
                defaultMessage: 'Mobile phone number',
                description: "Prompt for the user's phone number",
              })}
              type={InputType.tel}
              value={
                userInfo.phoneNumber.length > 4
                  ? formatPhoneNumberOnChange(userInfo.phoneNumber, 'US')
                  : userInfo.phoneNumber
              }
              onChange={(e) => {
                setUserInfo({
                  email: '',
                  phoneNumber: e.target.value,
                })
              }}
              prefix={getCallingCode(intl.locale)}
              subtext="Currently available for US/CA numbers"
            />
          )}
        </InputWrapper>
      </UpperContent>
      <ButtonsWrapper>
        <SubmitButtonWrapper>
          <Button
            data-cy="myextend-authentication-submit-button"
            text="Next"
            color="neutral"
            emphasis="high"
            fillContainer
            type="submit"
            onClick={handleSubmit}
          />
        </SubmitButtonWrapper>
        {!isVerification && !isRestrictedLogin && (
          <SwitchButtonWrapper>
            <Button
              data-cy="myextend-authentication-context-switch-button"
              text={isEmailContext ? 'Log in with phone number' : 'Log in with email address'}
              emphasis="low"
              color="neutral"
              fillContainer
              onClick={() => {
                resetFormValues()
                toggleContext()
              }}
            />
          </SwitchButtonWrapper>
        )}
      </ButtonsWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div({
  [bp.desktop]: {
    backgroundColor: COLOR.WHITE,
    borderRadius: '4px',
    padding: '32px 24px 32px 24px',
    width: '416px',
    height: '444px',
    flexDirection: 'column',
    display: 'flex',
  },
})

const SwitchButtonWrapper = styled.div({
  button: {
    color: COLOR.BLACK,
    textDecoration: 'underline',
    [bp.desktop]: {
      fontSize: '20px',
    },
  },
})

const SubmitButtonWrapper = styled.div({
  button: {
    backgroundColor: COLOR.BLACK,
    color: COLOR.WHITE,
  },
})

const Title = styled.div<{ isAuth?: boolean }>(({ isAuth }) => ({
  color: COLOR.BLACK,
  textAlign: 'center',
  fontWeight: 600,
  [bp.mobile]: {
    marginBottom: 16,
    fontSize: '28px',
    lineHeight: '36px',
  },
  [bp.desktop]: {
    ...(isAuth
      ? {
          marginBottom: 24,
          fontSize: '32px',
          lineHeight: '52px',
        }
      : {
          marginBottom: 16,
          fontSize: '28px',
          lineHeight: '36px',
        }),
  },
}))

const UpperContent = styled.div({
  [bp.desktop]: {
    flexGrow: 1,
  },
  [bp.mobile]: {
    marginBottom: '25px',
  },
})

const Content = styled.div({
  fontWeight: 400,
  color: COLOR.NEUTRAL[600],
  textAlign: 'center',
  [bp.mobile]: {
    margin: '8px 0 16px',
    fontSize: '18px',
    lineHeight: '24px',
  },
  [bp.desktop]: {
    fontSize: '18px',
    lineHeight: '28px',
    flexGrow: 1,
  },
})

const StyledInput = styled(Input)({
  '.chakra-input': {
    height: 50,
  },
  height: 100,
})

const InputWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
})

const ButtonsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
})

export { MyExtendAuthForm }
