import React from 'react'

const Plus: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(-604 -767) translate(605 768)"
      >
        <text fill="#53CEFF" fontFamily="NunitoSans-Light, Nunito Sans Light" fontSize="16" fontWeight="normal">
          <tspan x="9" y="18">
            +
          </tspan>
        </text>
        <circle cx="13.5" cy="13.5" r="13.5" stroke="#53CEFF" />
      </g>
    </svg>
  )
}

export default Plus
