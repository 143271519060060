import styled from '@emotion/styled'
import { COLOR } from '@customers-ui'
import { bp } from '@extend/client-helpers'

export const MailButton = styled.button({
  border: 'none',
  color: 'white',
  height: '20px',
  alignSelf: 'center',
  marginLeft: '8px',
  backgroundColor: COLOR.WHITE,
  outline: 'none',
  ':hover': {
    cursor: 'pointer',
  },
  '&:focus': {
    outline: 'none',
  },
  [bp.md]: {
    marginRight: '24px',
  },
})
