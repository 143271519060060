import type { FC } from 'react'
import React from 'react'
import { createPortal } from 'react-dom'
import { Modal, ModalController, Checkbox } from '@extend/zen'
import { CACardholderAgreement, USCardholderAgreement } from './cardholder-agreement-text'

export const ModalCardholderAgreement: FC<{
  onDismiss(): void
  onSubmit(): void
  isModalOpen: boolean
  isAgreementAccepted: boolean
  isProcessing: boolean
  toggleIsAgreementAccepted(accepted: boolean): void
  isCA?: boolean
}> = ({
  onDismiss,
  onSubmit,
  isModalOpen,
  isAgreementAccepted,
  isProcessing,
  toggleIsAgreementAccepted,
  isCA = false,
}) => {
  return createPortal(
    <ModalController isOpen={isModalOpen}>
      <Modal
        heading="Consent to cardholder agreement"
        size="md"
        maxHeight={450}
        primaryButtonProps={{
          text: 'Agree',
          onClick: onSubmit,
          isDisabled: !isAgreementAccepted || isProcessing,
          isProcessing,
          'data-cy': 'cardholder-agreement-modal-confirm-button',
        }}
        secondaryButtonProps={{
          text: 'Cancel',
          onClick: onDismiss,
          'data-cy': 'cardholder-agreement-modal-cancel-button',
        }}
        footerContent={
          <Checkbox
            checked={isAgreementAccepted}
            onChange={(e) => toggleIsAgreementAccepted(e.target.checked)}
            label="Yes, I have read and agree to the cardholder agreement."
          />
        }
      >
        {isCA ? <CACardholderAgreement /> : <USCardholderAgreement />}
      </Modal>
    </ModalController>,
    document.body,
  )
}
