'use client'

console.warn(
  "⚠️ Avoid importing components from 'index.js' for better performance. This practice is deprecated and may be removed in the future.",
)

export * as _Builtin from './_Builtin'
export * from './devlinkContext.jsx'
export * from './interactions.jsx'
export * from './utils'
export * from './devlink.jsx'
export * from './DevLinkProvider.jsx'
export * from './MyExtendLoader.jsx'
