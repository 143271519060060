import type { FC } from 'react'
import React from 'react'
import type { Contract, PlanDetails } from '@customers-api-client'
import { currency } from '@extend/client-helpers'
import { useIntl } from 'react-intl'
import { Section, DescriptionList } from '../../../../components/warranty-terms-section'
import { FormatDate } from '../../../../components/format-dates'

interface ContractDetailsProps {
  contract?: Contract
  planDetails: PlanDetails
}

const ContractDetails: FC<ContractDetailsProps> = ({ contract, planDetails }) => {
  const { locale } = useIntl()

  if (!contract) return null
  const { customer, sellerName: storeId, transactionId, createdAt, product, plan } = contract

  return (
    <Section title={planDetails.planTermsLabels.orderDetailsHeader}>
      <DescriptionList>
        <dt>{planDetails.planTermsLabels.customerNameLabel}</dt>
        <dd>{customer?.name ?? planDetails.planTermsLabels.notApplicable}</dd>
        <dt>{planDetails.planTermsLabels.customerEmailLabel}</dt>
        <dd>{customer?.email ?? planDetails.planTermsLabels.notApplicable}</dd>
        <dt>{planDetails.planTermsLabels.storeNameLabel}</dt>
        <dd>{storeId ?? planDetails.planTermsLabels.notApplicable}</dd>
        <dt>{planDetails.planTermsLabels.storeOrderIdLabel}</dt>
        <dd>{transactionId ?? planDetails.planTermsLabels.notApplicable}</dd>
        <dt>{planDetails.planTermsLabels.datePurchasedLabel}</dt>
        <dd>
          <FormatDate epochDateSeconds={createdAt} locale={locale} monthFormat="long" />
        </dd>
        <dt>{planDetails.planTermsLabels.productPriceLabel}</dt>
        <dd>
          {plan.purchasePrice
            ? currency.format(product.purchasePrice, contract.currency, undefined, locale)
            : planDetails.planTermsLabels.notApplicable}
        </dd>
        <dt>{planDetails.planTermsLabels.protectionPlanPriceLabel}</dt>
        <dd>
          {plan.purchasePrice
            ? currency.format(plan.purchasePrice, contract.currency, undefined, locale)
            : planDetails.planTermsLabels.notApplicable}
        </dd>
      </DescriptionList>
    </Section>
  )
}

export type { ContractDetailsProps }
export { ContractDetails }
