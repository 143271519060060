import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import styled from '@emotion/styled'
import { bp, COLOR, ContentLayout, Spinner, Toast } from '@customers-ui'
import {
  useFetchPlanDetailsQuery,
  useGetContractQuery,
  useListInsuranceClaimsQuery,
  getRTKQueryErrorMessage,
} from '@customers-api-rtk-query'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import type { SerializedError } from '@reduxjs/toolkit'
import { customLogger } from '@extend/client-helpers'
import { Faqs } from '../plan-details/faqs/faqs'
import ContractDetailsHeader from './contract-details-header'
import { ContractPlanDetails } from './contract-plan-details'
import { ClaimsAndTransferPlanSection } from './claims-transfer-plan-section'
import { AtAGlance } from './at-a-glance'
import { ContractNotFound } from './contract-not-found'
import type { Claim } from '../../types/claim'
import type {
  CategoryContract,
  CategoryProduct,
  ContractsSearchIndividual,
  ExtendedWarrantyContract,
  ProductProtectionBundleContract,
} from '../../types/contract'

export interface ProductDetailsProp {
  title?: string
  name?: string
  imageUrl?: string
  purchasePrice: number
  referenceId: string
  lineItemId?: string
}

export const isADH = (coverage: string): boolean => coverage === 'adh'
const now = Date.now()

export const ContractDetailsPage: FC = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [hasLoggedError, setHasLoggedError] = useState(false)

  const { id: contractId, lineItemId } = useParams<{ id: string; lineItemId?: string }>()

  const {
    data: contract,
    isLoading: isContractLoading,
    error: contractQueryError,
    isError: isContractQueryError,
  } = useGetContractQuery({
    contractId,
  }) as {
    data?: ContractsSearchIndividual
    isLoading: boolean
    error: FetchBaseQueryError | SerializedError | undefined
    isError: boolean
  }

  const {
    data: planDetails,
    isLoading: isPlanDetailsLoading,
    error: planDetailsQueryError,
    isError: isPlanDetailsQueryError,
  } = useFetchPlanDetailsQuery(contract?.plan?.id ?? '', {
    skip: !contract?.plan,
  })

  const {
    data: claimData,
    isLoading: isClaimsLoading,
    isSuccess: isClaimsQuerySuccess,
    error: insuranceClaimQueryError,
    isError: isInsuranceClaimQueryError,
  } = useListInsuranceClaimsQuery({
    containsContractId: contractId,
    cacheBust: now,
  })

  const filteredClaims = lineItemId
    ? claimData?.items.filter((claim) => claim.products?.some((p) => p.lineItemId === lineItemId))
    : claimData?.items
  const claims = isClaimsQuerySuccess ? (filteredClaims as unknown as Claim[]) : null

  const handleReload = (): void => {
    window.location.reload()
  }

  const handleDismiss = (): void => {
    setIsVisible(false)
  }

  useEffect(() => {
    const queryError = contractQueryError || insuranceClaimQueryError || planDetailsQueryError
    if (queryError) {
      if (!hasLoggedError) {
        const errorMessage = getRTKQueryErrorMessage(queryError)
        customLogger.warn(`[Err: Contract Details Page]: ${errorMessage}`, {
          contractQueryError: isContractQueryError,
          insuranceClaimQueryError: isInsuranceClaimQueryError,
          planDetailsQueryError: isPlanDetailsQueryError,
          contractId,
          lineItemId,
        })
        setHasLoggedError(true)
      }
      setIsVisible(true)
    }
  }, [contractQueryError, insuranceClaimQueryError, planDetailsQueryError, hasLoggedError])

  const getProductDetails = (): ProductDetailsProp | undefined => {
    if (contract?.type === 'category') {
      if (lineItemId) {
        return (contract as CategoryContract)?.productsList?.find(
          (p) => (p as CategoryProduct).lineItemId === lineItemId,
        ) as ProductDetailsProp
      }
      return (contract as CategoryContract)?.productsList?.[0] as ProductDetailsProp
    }
    if (contract?.type === 'product_protection_bundle') {
      return (contract as ProductProtectionBundleContract)?.productsList?.find(
        (product) => product.type === 'custom_bundle',
      )
    }
    return (contract as ExtendedWarrantyContract)?.product as ProductDetailsProp
  }

  return (
    <ContentLayout pageTitle="My Contract Details Page">
      <Toast
        message="We've encountered an error. Please refresh this page."
        type="danger"
        isVisible={isVisible}
        handleDismiss={handleDismiss}
        dataCy="contract-details-page-error-toast"
      >
        <RefreshButton onClick={handleReload}>Refresh</RefreshButton>
      </Toast>
      {(isContractLoading || isClaimsLoading || isPlanDetailsLoading) && (
        <SpinnerLoading data-cy="contract-details-page-spinner">
          <Spinner size="md" />
        </SpinnerLoading>
      )}
      {contractQueryError && <ContractNotFound />}
      {contract && claims && planDetails && (
        <Wrapper>
          <ContractDetailsHeader
            contract={contract}
            isRefreshToastVisible={isVisible}
            product={getProductDetails() as ProductDetailsProp}
          />
          <ContractPlanDetails
            isADH={
              (contract as ExtendedWarrantyContract | CategoryContract)
                ? isADH((contract as ExtendedWarrantyContract | CategoryContract).planDetails?.coverage_includes || '')
                : false
            }
            contract={contract}
            product={getProductDetails() as ProductDetailsProp}
          />
          <ClaimsAndTransferPlanSection
            contract={contract}
            claims={claims}
            product={getProductDetails() as ProductDetailsProp}
          />
          <AtAGlance contract={contract} {...{ planDetails }} />
          <StyledFaqs planDetails={planDetails} />
        </Wrapper>
      )}
    </ContentLayout>
  )
}

const Wrapper = styled.div({
  backgroundColor: COLOR.WHITE,
  width: 'inherit',
  [bp.mobile]: {
    padding: 20,
  },
  [bp.desktop]: {
    padding: '0px 120px',
  },
})

export const SpinnerLoading = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: 20,
  paddingRight: 20,
})

const StyledFaqs = styled(Faqs)({
  [bp.mobile]: {
    padding: '60px 0',
  },
  [bp.desktop]: {
    padding: '80px 0',
  },
})

const RefreshButton = styled.button({
  [bp.mobile]: {
    display: 'none',
  },
  [bp.desktop]: {
    display: 'flex',
    backgroundColor: COLOR.RED['200'],
    color: COLOR.RED['700'],
    fontSize: 14,
    borderRadius: 25,
    border: `1px solid ${COLOR.RED['700']}`,
    padding: '7px 26px',
    lineHeight: '19px',
    fontWeight: 700,
    marginLeft: 44,
  },
  '&:hover': {
    cursor: 'pointer',
  },
})
