import { customLogger } from '@extend/client-helpers'
import fetch from 'cross-fetch'
import * as qs from 'querystring'
import type { ApiResponse, CustomRequestInit, DataType, Method, ResponseObject } from './types'

const ExtendAccessToken = 'X-Extend-Access-Token'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface RequestOptions<TObj = any> {
  dataType?: DataType
  body?: Record<string, TObj> | TObj[] | string
  formData?: Record<string, TObj> | TObj[]
  qs?: Record<string, string | number | boolean>
  https?: boolean
  method?: Method
  headers?: Record<string, string>
  accessToken?: string | null
  apiVersion?:
    | '2022-02-01'
    | '2021-07-01'
    | '2021-04-01'
    | '2021-01-01'
    | '2020-08-01'
    | '2019-08-01'
    | 'default'
    | 'latest'
}

export const request = async <TJSONRes = any>(
  path: string,
  options: RequestOptions = {},
): Promise<ApiResponse<TJSONRes>> => {
  const url = buildUrl(path)
  const queryStringObject = options.qs
  const dataType = options.dataType || 'json'

  const requestInit: CustomRequestInit = {
    headers: options.headers || {},
    method: options.method || 'GET',
  }

  if (options.body instanceof FormData) {
    requestInit.body = options.body
  } else {
    if (queryStringObject) {
      Object.keys(queryStringObject).forEach((key) => {
        url.searchParams.append(key, String(queryStringObject[key]))
      })
    }

    if (['POST', 'PUT', 'DELETE'].includes(requestInit.method)) {
      if (dataType === 'json') {
        if (options.body) {
          requestInit.body = JSON.stringify(options.body)
          requestInit.headers['Content-Type'] = 'application/json'
        } else if (options.formData) {
          requestInit.body = qs.stringify(options.formData as any)
          requestInit.headers['Content-Type'] = 'application/x-www-form-encoded'
        }
      } else if (options.body) {
        requestInit.body = options.body as any
      }
    }

    if (dataType === 'json') {
      requestInit.headers.Accept = 'application/json'
    }
    if (options.apiVersion) {
      requestInit.headers.Accept += `; version=${options.apiVersion}`
    }

    // this may not belong here but making a judgement call that this is primarily
    // used for requests to the extend apis
    if (options.accessToken) {
      requestInit.headers[ExtendAccessToken] = options.accessToken
    }
  }

  const fetchResponse = await fetch(url.toString(), requestInit)

  const response: ResponseObject<TJSONRes | null> = {
    status: fetchResponse.status,
    data: null,
  }

  try {
    response.data = await fetchResponse.json()
  } catch (e) {
    // not parsable response
  }

  if (response.status >= 300) {
    customLogger.error('Request failed.', { status: response.status })
  }

  return response as ApiResponse<TJSONRes>
}

function buildUrl(path: string): URL {
  return new URL(path, `https://${__EXTEND_API_HOST__}`)
}

export const post = async <TJSONRes = any>(
  path: string,
  options: RequestOptions = {},
): Promise<ApiResponse<TJSONRes>> => {
  return request(path, { ...options, method: 'POST' })
}

export const put = async <TJSONRes = any>(
  path: string,
  options: RequestOptions = {},
): Promise<ApiResponse<TJSONRes>> => {
  return request(path, { ...options, method: 'PUT' })
}

export const destroy = async <TJSONRes = any>(
  path: string,
  options: RequestOptions = {},
): Promise<ApiResponse<TJSONRes>> => {
  return request(path, { ...options, method: 'DELETE' })
}

export const get = async <TJSONRes = any>(
  path: string,
  options: RequestOptions = {},
): Promise<ApiResponse<TJSONRes>> => {
  return request(path, { ...options, method: 'GET' })
}
