import type { FC } from 'react'
import React from 'react'

export const RepairCheckmarkIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="66" viewBox="0 0 64 66">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-222 -2526)">
          <g transform="translate(223 2527)">
            <g
              stroke="#27AEE4"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.777"
              transform="translate(32 34)"
            >
              <path d="M.385 14.778C.385 6.708 6.93.162 15.001.162s14.616 6.545 14.616 14.616c0 8.07-6.545 14.616-14.616 14.616-8.07 0-14.616-6.545-14.616-14.616z" />
              <path d="M6.23177086 14.7781141L12.0780578 20.624401 23.7706316 8.93182721" />
            </g>
            <g fill="#224BC5" fillRule="nonzero" stroke="#FFF">
              <path d="M35.474 1.678c.134 0 .264.015.394.04 9.345 2.01 17.03 8.667 20.384 17.664V12.41c0-1.042.837-1.883 1.874-1.883 1.037 0 1.874.84 1.874 1.883l-.604 12.099c0 .498-.197.979-.549 1.332-.351.353-.83.551-1.325.551l-10.647-.79a1.876 1.876 0 01-1.873-1.884c0-1.042.836-1.883 1.873-1.883l6.853.613c-1.308-4.096-4.23-8.384-7.405-11.267a24.256 24.256 0 00-11.232-5.774 1.862 1.862 0 01-1.185-.817 1.882 1.882 0 011.568-2.912z" />
              <path
                d="M1.872 33.714c.133 0 .263.014.394.04 9.345 2.01 17.03 8.667 20.384 17.664v-6.972c0-1.042.837-1.884 1.874-1.884 1.037 0 1.874.841 1.874 1.884l-.605 12.099c0 .498-.197.978-.548 1.332-.352.353-.83.551-1.326.551l-10.646-.79a1.876 1.876 0 01-1.874-1.884c0-1.043.837-1.884 1.874-1.884l6.852.614c-1.308-4.096-4.23-8.384-7.404-11.267a24.256 24.256 0 00-11.233-5.774 1.862 1.862 0 01-1.184-.817 1.882 1.882 0 011.568-2.912z"
                transform="rotate(180 13.199 46.071)"
              />
              <path d="M8.333 0h10c4.603 0 8.334 3.757 8.334 8.392v10.07c0 4.635-3.731 8.393-8.334 8.393h-10C3.731 26.855 0 23.097 0 18.463V8.393C0 3.756 3.731 0 8.333 0zm0 3.357c-2.761 0-5 2.254-5 5.035v10.07c0 2.781 2.239 5.036 5 5.036h10c2.762 0 5-2.255 5-5.035V8.393c0-2.782-2.238-5.036-5-5.036h-10z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
