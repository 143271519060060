import type { FC } from 'react'
import React from 'react'

interface CheckmarkProps {
  width?: number
  height?: number
  minWidth?: number
  minHeight?: number
  fill?: string
  display?: string
  margin?: string
}

const Checkmark: FC<CheckmarkProps> = ({ width, height, minWidth, minHeight, fill, display, margin }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || 40}
      height={height || 40}
      viewBox="0 0 40 40"
      // styled components could not override
      style={{
        display: display || 'block',
        margin: margin || '8px 16px 0px 0px',
        minWidth: minWidth || 0,
        minHeight: minHeight || 0,
      }}
    >
      <g fill={fill || '#090637'} fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fillRule="nonzero"
          d="M26.495 13.115a1.5 1.5 0 012.502 1.65l-.08.12-10.44 14.29-7.514-7.183a1.5 1.5 0 011.957-2.269l.117.1 5.038 4.817 8.42-11.525z"
          transform="translate(-116 -205) translate(116 205)"
        />
        <path
          d="M20 3.2A16.803 16.803 0 0136.8 20c0 4.456-1.77 8.728-4.92 11.88a16.804 16.804 0 01-23.76 0 16.804 16.804 0 010-23.76A16.804 16.804 0 0120 3.2zM20 0a20 20 0 100 40 20 20 0 000-40z"
          transform="translate(-116 -205) translate(116 205)"
        />
      </g>
    </svg>
  )
}

export default Checkmark
