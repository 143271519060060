import type { FC } from 'react'
import React from 'react'

export const BrokenWineGlass: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 63">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#224BC5"
          fillRule="nonzero"
          d="M48 22.9V4.8C48 2 45.7 0 43 0H11C8.4 0 6 2.1 6 4.8v18.7C6 29 8.4 34.2 12.6 38a21 21 0 0012.6 5.3v16.5H14c-1 0-1.7.7-1.7 1.6 0 1 .7 1.7 1.7 1.7h26c1 0 1.7-.7 1.7-1.7 0-.9-.7-1.6-1.7-1.6H28.8V43.2A20.7 20.7 0 0048 22.9zm-38.5.6V4.8c0-.8.7-1.5 1.6-1.5h31.8c.9 0 1.6.7 1.6 1.5v18C44.5 32 37.3 39.4 28 40c-5 .3-9.6-1.2-13-4.4a15.8 15.8 0 01-5.6-12z"
        />
        <path
          fill="#27AEE4"
          d="M27.8 3.3L25 14h5c.5 0 1 .2 1.3.6l.3.5v.7l-.3 1.4-2.2 8.6-.5 1.5c-.4.6-1 .9-1.7.7-1.3-.3-1.8-.9-1.5-1.7l2.2-9.1h-4.9a2 2 0 01-.8-.2l-.5-.4c-.3-.2-.4-.6-.4-1.2l3-12h3.7z"
        />
      </g>
    </svg>
  )
}
