import { useEffect, useState } from 'react'
import type { Contract, Plan, PlanTermsSetResponse } from '@customers-api-client'
import { useLazyGetPlanTermsSetQuery, useLazyGetPlanTermsVersionLanguageUrlQuery } from '@customers-api-rtk-query'

export function usePlanTermsData({ contract, plan }: { contract?: Contract; plan?: Plan }): {
  terms: string | PlanTermsSetResponse | undefined
  isLoading: boolean
  error: unknown
} {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [getPlanTermsSet, { data: planTermsSets, error: hasPlanTermsSetError }] = useLazyGetPlanTermsSetQuery()

  const [getPlanTermsVersionLanguages, { data: planTermsVersion, error: hasPlanTermsVersionError }] =
    useLazyGetPlanTermsVersionLanguageUrlQuery()

  useEffect(() => {
    const getPlanTermsSets = async (): Promise<void> => {
      if (contract?.terms?.termsId && contract?.terms?.version) {
        try {
          const response = await getPlanTermsSet({
            termsId: contract.terms.termsId,
          }).unwrap()
          if (response.length > 0) {
            setIsLoading(false)
          } else {
            await getPlanTerms()
            setIsLoading(false)
          }
        } catch (e) {
          await getPlanTerms()
          setIsLoading(false)
        }
      } else if (plan?.termsId && plan?.termsVersion) {
        try {
          const response = await getPlanTermsSet({
            termsId: plan.termsId,
          }).unwrap()
          if (response.length > 0) {
            setIsLoading(false)
          } else {
            await getPlanTerms()
            setIsLoading(false)
          }
        } catch (e) {
          await getPlanTerms()
          setIsLoading(false)
        }
      }
    }

    const getPlanTerms = async (): Promise<void> => {
      if (contract?.terms?.termsId && contract?.terms?.version) {
        try {
          await getPlanTermsVersionLanguages({
            termsId: contract.terms.termsId,
            version: contract.terms.version,
            language: 'en',
          })
        } catch (e) {
          console.error(e)
        }
      } else if (plan?.termsId && plan?.termsVersion) {
        try {
          await getPlanTermsVersionLanguages({
            termsId: plan.termsId,
            version: plan.termsVersion,
            language: 'en',
          })
        } catch (e) {
          console.error(e)
        }
      }
    }

    getPlanTermsSets()
  }, [contract, getPlanTermsSet, getPlanTermsVersionLanguages, plan])

  return {
    terms: planTermsSets || planTermsVersion?.url,
    isLoading,
    error: hasPlanTermsSetError && hasPlanTermsVersionError,
  }
}
