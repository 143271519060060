import type { FC } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Button, COLOR, HeadingSmall } from '@extend/zen'
import type { ProductSelectPromptOption } from '@customers-api-client'
import { bp } from '@customers-ui'
import { Slot } from '@customers-api-client'
import type { ProductSelectorProps } from './product-selector-types'
import { ProductListItem } from './product-list-item'

export const ProductSelector: FC<ProductSelectorProps> = ({
  'data-cy': dataCy,
  prompt,
  isLoading,
  onSelect,
  displayBorder = false,
  color = 'blue',
}) => {
  const [selectedOptions, setSelectedOptions] = useState<Record<string, ProductSelectPromptOption>>({})

  const handleChange = (selectedValue: ProductSelectPromptOption, key: string): void => {
    setSelectedOptions((prevSelectedOptions) => {
      const updatedOptions = { ...prevSelectedOptions }
      if (updatedOptions[key]) {
        delete updatedOptions[key] // Delete the key if it exists
      } else {
        updatedOptions[key] = selectedValue // Store the key if it doesn't exist
      }
      return updatedOptions
    })
  }

  const handleNext = (): void => {
    const selectedValues = Object.values(selectedOptions)
    const selectedOptionValues = selectedValues.map((option) => option.value)
    const firstSelectedOptionTitle = selectedValues[0].title
    onSelect(Slot.Products, selectedOptionValues, firstSelectedOptionTitle)
  }
  return (
    <>
      <Wrapper data-cy={dataCy} displayBorder={displayBorder}>
        <Header>{prompt.title}</Header>
        {Boolean(prompt.options.length) &&
          prompt.options.map((product, index) => {
            const { value, title, imageUrl } = product
            const key = `${value}-${title}-${index}`
            return (
              <ProductListItem
                checked={selectedOptions[key] !== undefined}
                key={key}
                label={title}
                name={Slot.Products}
                id={key}
                onChange={() => handleChange(product, key)}
                value={key}
                imageUrl={imageUrl}
                color={color}
              />
            )
          })}
      </Wrapper>
      <Footer>
        <Button
          data-cy="product-select-btn"
          emphasis="high"
          size="regular"
          type="button"
          text="Next"
          isDisabled={isLoading || !Object.keys(selectedOptions).length}
          isProcessing={isLoading}
          onClick={handleNext}
          color={color}
        />
      </Footer>
    </>
  )
}

const Wrapper = styled.div<{ displayBorder: boolean }>(({ displayBorder }) => ({
  display: 'grid',
  grid: 'auto-flow / 1fr',
  padding: '0px 20px',
  margin: '0 auto',
  maxHeight: 400,
  overflowY: 'auto',
  ...(displayBorder && {
    border: `1px solid ${COLOR.NEUTRAL[300]}`,
    borderRadius: '8px',
    [bp.mobile]: {
      padding: '16px 20px',
    },
    [bp.desktop]: {
      padding: '32px 20px',
      maxWidth: 640,
    },
  }),
}))

const Header = styled(HeadingSmall)({
  textAlign: 'center',
  marginBottom: 16,
})

const Footer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 16,
  marginBottom: 16,
  padding: '24px 20px 24px 20px',
})
