import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Button } from '@extend/zen'
import { useHistory } from 'react-router'
import { useTheme } from '@emotion/react'
import { useIntl } from 'react-intl'

export interface VirtualCardSupportProps {}

export const VirtualCardSupport: FC<VirtualCardSupportProps> = () => {
  const history = useHistory()
  const theme = useTheme()
  const { locale } = useIntl()

  const handleContactSupport = (): void => {
    if (theme.merchantConfiguration.merchantSupportUrl) {
      window.open(theme.merchantConfiguration.merchantSupportUrl, '_blank')
    } else {
      history.push(`/${locale}/contact`)
    }
  }

  return (
    <VCSupportContainer>
      <VCSupportContent>
        <div>
          <Header>Have questions?</Header>
          <h3>Contact support for any questions about the cardholder agreement or how to get your fulfillment.</h3>
        </div>
        <Button
          text="Take me back to the cardholder agreement"
          fillContainer
          data-cy="back-to-vc-btn"
          onClick={() => history.goBack()}
        />
        <Button
          text="Contact Support"
          fillContainer
          data-cy="contact-support-btn"
          emphasis="medium"
          onClick={handleContactSupport}
        />
      </VCSupportContent>
    </VCSupportContainer>
  )
}

const VCSupportContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

const VCSupportContent = styled.div({
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  gap: '20px',
  maxWidth: '476px',
  padding: '0 8px',
})

const Header = styled.h1({
  fontSize: '44px',
  fontWeight: 600,
})
