import styled from '@emotion/styled'
import React, { useEffect, useRef, useState } from 'react'
import type { ChangeEvent, FC } from 'react'
import Select from 'react-select'
import type { SelectInstance, SingleValue, StylesConfig } from 'react-select'
import type { Address, ClaimStatus } from '@customers-api-client'
import { Button, COLOR, Input } from '@extend/zen'
import { customLogger, formatPhoneNumberIso } from '@extend/client-helpers'
import { isMobile } from 'react-device-detect'
import { getNewOptions, getDetails, getFullAddress, getPlacePredictions, initServices } from '../../lib/google-api'
import type { AutocompletePrediction, OptionType } from '../../lib/google-api'
import { bp } from '@customers-ui'
import { useUpdateClaimMutation } from '@customers-api-rtk-query'
import type { Claim, ClaimAddress } from '../../types/claim'
import { formatAddress } from '../../utils/format-address'
import { logEvent } from '../../analytics'
import { hasUnmetPhotoRequirements } from '../../lib/helper-functions'
import * as selectors from '../../reducers/selectors'
import { useSelector } from 'react-redux'
import { getAccountInfo, getShippingAddress } from '../../lib/consumer-profile-utils'
import { useHistory } from 'react-router-dom'

interface CustomerShippingAddressProps {
  claim: Claim
  handleModalOpen: () => void
  submitClaim: ({ claimId }: { claimId: string }) => void
  isClaimSubmitSuccess?: boolean
  isMissingRequiredProfileField: boolean
}

const CustomerShippingAddress: FC<CustomerShippingAddressProps> = ({
  claim,
  handleModalOpen,
  submitClaim,
  isClaimSubmitSuccess,
  isMissingRequiredProfileField,
}) => {
  const history = useHistory()
  const consumerProfile = useSelector(selectors.getConsumerProfile)

  const { phoneNumber, email, name } = getAccountInfo(consumerProfile)

  const shippingAddress = claim?.customer?.shippingAddress
    ? claim.customer.shippingAddress
    : consumerProfile && getShippingAddress(consumerProfile)

  const customerName = name || claim?.customer?.name

  const isCompleteConsumerShippingAddress = Boolean(
    shippingAddress?.address1 &&
      shippingAddress?.city &&
      shippingAddress?.countryCode &&
      shippingAddress?.provinceCode &&
      shippingAddress?.postalCode,
  )

  const [isMapsServicesLoaded, setIsMapsServicesLoaded] = useState<boolean>(false)
  const [isUserEditingAddress, setIsUserEditingAddress] = useState<boolean>(!isCompleteConsumerShippingAddress)
  const [isClaimSubmitted, setIsClaimSubmitted] = useState<boolean>(
    (claim.status as ClaimStatus) !== 'pending_adjudication',
  )
  const [shouldDisplaySubmitButtons, setShouldDisplaySubmitButtons] = useState<boolean>(!isClaimSubmitted)
  const [addressInput, setAddressInput] = useState<string>()
  const [selectedOption, setSelectedOption] = useState<OptionType>()
  const [unitNumberInput, setUnitNumberInput] = useState<string>('')
  const [customerAddress, setCustomerAddress] = useState<ClaimAddress>()
  const [options, setOptions] = useState<OptionType[]>([])

  const reactSelectRef = useRef<SelectInstance<OptionType>>(null)

  const [updateClaim, { isSuccess: isClaimUpdateSuccess, isError: isClaimUpdateError }] = useUpdateClaimMutation()

  useEffect(() => {
    if (!isMapsServicesLoaded) {
      const initGoogleApi = async (): Promise<void> => {
        await initServices()
        setIsMapsServicesLoaded(true)
      }
      initGoogleApi()
    }
    if (options.length && reactSelectRef?.current) {
      reactSelectRef.current.focusOption('last')
    }

    if (isClaimUpdateSuccess) {
      setIsUserEditingAddress(false)
    }

    if (isClaimSubmitSuccess) {
      setIsClaimSubmitted(true)
    }

    if (isClaimUpdateError) {
      history.push('/error')
    }
  }, [
    isMapsServicesLoaded,
    options,
    isClaimUpdateSuccess,
    setIsUserEditingAddress,
    isClaimSubmitSuccess,
    isClaimSubmitted,
    setIsClaimSubmitted,
    isClaimUpdateError,
    history,
  ])

  const displaySuggestions = (predictions: AutocompletePrediction[] | null): void => {
    if (!predictions?.length) {
      setOptions([])
      return
    }
    setOptions(getNewOptions(predictions))
  }

  const handleInputChange = (inputValue: string): void => {
    setIsUserEditingAddress(true)
    setAddressInput(inputValue)
    if (!inputValue) {
      setOptions([])
      return
    }
    if (!isMapsServicesLoaded) return
    getPlacePredictions({ input: inputValue, types: ['address'] }, displaySuggestions)
  }

  const handleOptionChange = (option: SingleValue<OptionType>): void => {
    if (!option) return
    getDetails({ placeId: option.value, fields: ['address_component'] }, (result) => {
      if (!result?.address_components?.length) {
        customLogger.error(`Error occurred during Google Maps attempt to get address details`, {
          timestamp: Date.now(),
          errorMessage: `No address details returned for given Google Maps Prediction: ${JSON.stringify({
            address: option.label,
            placeId: option.value,
          })}. Google Maps Result: ${JSON.stringify(result)}`,
        })
        return
      }
      setSelectedOption(option)
      const address = getFullAddress(result.address_components)
      setCustomerAddress(address)
    })
  }

  const handleUnitNumberInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setIsUserEditingAddress(true)
    event.preventDefault()
    const { value } = event.target
    setUnitNumberInput(value)
  }

  const handleUpdateAddress = (): void => {
    setIsUserEditingAddress(true)
  }

  const handleCancelAddressInput = (): void => {
    setIsUserEditingAddress(false)
  }

  const handleUpdateClaimAddress = async (address?: ClaimAddress): Promise<void> => {
    if (!shippingAddress && !address) return
    const existingAddress = { ...shippingAddress }
    delete existingAddress.isPrimary
    delete existingAddress.type
    await updateClaim({
      claimId: claim.id,
      body: {
        customer: {
          ...claim.customer,
          name: customerName,
          email: email ?? '',
          phone: phoneNumber ? formatPhoneNumberIso(phoneNumber) : '',
          shippingAddress: address || existingAddress,
        },
      },
    }).unwrap()
  }

  const handleSaveAndSubmit = async (): Promise<void> => {
    if (!customerAddress) return
    const combinedAddress: ClaimAddress = { ...customerAddress, address2: unitNumberInput || ' ' }
    if (selectedOption) {
      logEvent('Review Claim - Update Address - Click', 'Save and submit claim')
      await handleUpdateClaimAddress(combinedAddress)

      await handleSubmitClaim(true)
    }
  }

  const handleSubmitClaim = async (wasAddressUpdated = false): Promise<void> => {
    logEvent('Review Claim - Click', 'Yes, submit claim')
    setShouldDisplaySubmitButtons(false)
    handleModalOpen()

    // prevent updating the claim address twice
    if (!wasAddressUpdated) {
      await handleUpdateClaimAddress()
    }

    logEvent('Review Claim - Submit Claim - Click', 'Address verified & claim submitted')

    if (!hasUnmetPhotoRequirements(claim)) {
      submitClaim({ claimId: claim.id })
    }
  }

  return (
    <ShippingAddressWrapper data-cy="shipping-address-wrapper">
      <InfoWrapper>
        {!isClaimSubmitted && !isUserEditingAddress && (
          <AddressVerificationQuestion data-cy="address-verification-question">
            Is this address up to date?
          </AddressVerificationQuestion>
        )}
        {!isUserEditingAddress && (
          <>
            <Header data-cy="shipping-address-header">Shipping Address</Header>
            <ShippingAddressString data-cy="shipping-address-string">
              {formatAddress(customerAddress ?? (shippingAddress as Address))}
            </ShippingAddressString>
          </>
        )}
        {isUserEditingAddress && (
          <AddressForm data-cy="address-form">
            <FieldWrapper>
              <FieldHeader>
                <Label>Address</Label>
              </FieldHeader>
              <Select
                ref={reactSelectRef}
                styles={customSelectStyles}
                value={selectedOption}
                placeholder=""
                onChange={handleOptionChange}
                onInputChange={handleInputChange}
                options={options}
                menuPlacement="top"
                menuPortalTarget={document.body}
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                menuIsOpen={!!addressInput}
                data-cy="address-select"
              />
            </FieldWrapper>
            <Input
              id="apt-unit-input"
              value={unitNumberInput}
              label="Apt, suite, etc. (Optional)"
              onChange={handleUnitNumberInputChange}
              data-cy="apt-unit-input"
            />
          </AddressForm>
        )}
      </InfoWrapper>
      {!isClaimSubmitted && (
        <ButtonContainer>
          {!isUserEditingAddress && shouldDisplaySubmitButtons && (
            <>
              <Button
                emphasis="medium"
                text="Yes, submit claim"
                fillContainer={isMobile}
                onClick={() => handleSubmitClaim()}
                data-cy="submit-claim-button"
                isDisabled={isMissingRequiredProfileField}
                color="neutral"
              />
              <Button
                emphasis="medium"
                text="No, update address"
                onClick={handleUpdateAddress}
                fillContainer={isMobile}
                data-cy="update-address-button"
                isDisabled={isMissingRequiredProfileField}
                color="neutral"
              />
            </>
          )}
          {isUserEditingAddress && (
            <>
              <Button
                emphasis="high"
                text="Save and submit claim"
                fillContainer={isMobile}
                onClick={handleSaveAndSubmit}
                data-cy="save-and-submit-button"
                isDisabled={isMissingRequiredProfileField}
                color="neutral"
              />
              <Button
                emphasis="medium"
                text="Cancel"
                isDisabled={!isCompleteConsumerShippingAddress || isMissingRequiredProfileField}
                onClick={handleCancelAddressInput}
                fillContainer={isMobile}
                data-cy="cancel-button"
                color="neutral"
              />
            </>
          )}
        </ButtonContainer>
      )}
    </ShippingAddressWrapper>
  )
}

const ShippingAddressWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
})

const InfoWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
})

const FieldWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
})

const FieldHeader = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
})

const Label = styled.label({
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '22px',
  color: COLOR.NEUTRAL[1000],
})

const AddressVerificationQuestion = styled.div({
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '24px',
  color: COLOR.BLACK,
})

const AddressForm = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flexStart',
  gap: '16px',
  alignSelf: 'stretch',
})

const Header = styled.div({
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '22px',
  color: COLOR.NEUTRAL[600],
})

const ShippingAddressString = styled.div({
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: COLOR.NEUTRAL[1000],
})

const ButtonContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  alignSelf: 'stretch',
  [bp.mobile]: {
    flexDirection: 'column',
  },
  [bp.desktop]: {
    flexDirection: 'row',
  },
})

const customSelectStyles: StylesConfig<OptionType, false> = {
  container: (defaultProvidedStyles) => ({
    ...defaultProvidedStyles,
    flex: 1,
    [bp.mobile]: {
      flex: 2,
    },
  }),
  control: (defaultProvidedStyles, state) => ({
    ...defaultProvidedStyles,
    height: 40,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: COLOR.NEUTRAL[400],
    boxShadow: 'none',
    ...(state.isFocused && {
      outline: 'none',
      borderColor: COLOR.BLUE[700],
    }),
    '&:hover': {
      borderColor: COLOR.BLUE[700],
    },
    borderRadius: 8,
  }),

  option: (defaultProvidedStyles, { isFocused }) => ({
    ...defaultProvidedStyles,
    padding: 8,
    backgroundColor: isFocused ? COLOR.BLUE[100] : 'white',
    color: isFocused ? COLOR.BLUE[700] : 'black',
    ':last-child': {
      border: 'none',
    },
    [bp.mobile]: {
      ':first-of-type': {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
      },
      ':last-child': {
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
      },
    },
  }),
}

export { CustomerShippingAddress }
