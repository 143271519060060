import React from 'react'
import type { FC } from 'react'
import type { ProductSelectPrompt, Reply } from '@extend-incredibot/types'
import styled from '@emotion/styled'
import { bp } from '@customers-ui'
import { ProductSelector } from '../../chat-portal/chat-user-input/user-inputs/chat-product-select/product-selector'
import type { UserInputComponentProps } from './types'

export interface ProductSelectProps extends UserInputComponentProps<Reply & { prompt: ProductSelectPrompt }> {}

const ProductSelect: FC<ProductSelectProps> = ({ isLoading, onSubmit, reply }) => {
  return (
    <ContentWrapper data-cy="product-select-content-wrapper">
      <SelectorWrapper>
        <ProductSelector
          prompt={reply.prompt}
          onSelect={onSubmit as any}
          isLoading={isLoading || false}
          data-cy="product-selector"
          displayBorder
          color="neutral"
        />
      </SelectorWrapper>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  width: '100%',
  display: 'inline-flex',
  justifyContent: 'center',
  flexDirection: 'column',
  [bp.desktop]: {
    gap: 32,
  },
  [bp.mobile]: {
    gap: 16,
  },
})

const SelectorWrapper = styled.div({
  width: '100%',
})

export { ProductSelect }
