import React from 'react'

const External: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 13 13">
      <g fill="#224BC5" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          d="M12.435.055a.536.536 0 00-.055.004H8.482a.496.496 0 100 .991h2.767L5.16 7.14a.495.495 0 10.7.701l6.09-6.09V4.52a.496.496 0 10.992 0V.617a.496.496 0 00-.506-.564z"
          transform="translate(-820 -1853) translate(820 1853)"
        />
        <path
          d="M5.51 2.041c-1.238 0-2.172-.002-2.916.083-.745.084-1.341.26-1.767.688C.4 3.238.225 3.833.142 4.578.058 5.322.059 6.256.059 7.492c0 1.235 0 2.17.085 2.914.085.744.264 1.34.691 1.766.428.426 1.023.603 1.767.687.743.084 1.675.082 2.907.082s2.164 0 2.908-.084c.744-.085 1.34-.262 1.767-.689.427-.426.604-1.021.69-1.766.085-.744.085-1.677.085-2.91V7.49a.497.497 0 00-.849-.355.497.497 0 00-.142.355c0 1.232-.004 2.151-.079 2.798-.074.648-.21.983-.406 1.178-.196.196-.53.332-1.178.406-.647.074-1.565.077-2.796.077-1.232 0-2.15-.003-2.797-.076-.647-.073-.982-.208-1.178-.404-.196-.195-.333-.53-.406-1.177-.075-.648-.079-1.567-.079-2.801 0-1.235.002-2.155.076-2.803.072-.648.208-.982.402-1.176.194-.195.529-.33 1.177-.404.647-.073 1.568-.076 2.804-.076h.001a.496.496 0 100-.992z"
          transform="translate(-820 -1853) translate(820 1853)"
        />
      </g>
    </svg>
  )
}

export default External
