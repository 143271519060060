import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import React from 'react'
import type { FC } from 'react'
import type { TransformedLog } from '../../lib/helper-functions'

interface ClaimSnapshotProps {
  sessionLogs: TransformedLog[]
}

const ClaimSnapshot: FC<ClaimSnapshotProps> = ({ sessionLogs }) => {
  return (
    <SnapshotWrapper data-cy="claim-snapshot-wrapper">
      {sessionLogs.map((log) => (
        <LogGroup data-cy="claim-snapshot-log-group" key={log.id}>
          <Question data-cy="log-group-question">{log.question}</Question>
          <Answer data-cy="log-group-answer">{log.answer}</Answer>
        </LogGroup>
      ))}
    </SnapshotWrapper>
  )
}

const SnapshotWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
})

const LogGroup = styled.div({
  gap: '4px',
})

const Question = styled.div({
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '22px',
  color: COLOR.NEUTRAL[600],
})

const Answer = styled.div({
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
})

export { ClaimSnapshot }
