import type { FC } from 'react'
import React from 'react'
import { Toast } from '@customers-ui'

const SuccessErrorComponent: FC<{
  successMessage?: string
  errorMessage?: string
  isSuccess?: boolean
  isError?: boolean
  isVisible: boolean
  handleDismiss: () => void
}> = ({
  successMessage = 'Your contact information has been saved.',
  errorMessage = 'We were unable to update your contact information due to an error. Please try again.',
  isSuccess,
  isError,
  isVisible,
  handleDismiss,
}) => {
  if (!isSuccess && !isError) {
    return null
  }

  if (isError) {
    return <Toast isDismissible type="danger" message={errorMessage} {...{ isVisible, handleDismiss }} />
  }

  return <Toast isDismissible type="info" message={successMessage} {...{ isVisible, handleDismiss }} />
}

export { SuccessErrorComponent }
