import { union } from 'lodash/fp'
import type { Slot } from '@customers-api-client'
import type { Action } from '../../types/action-type'

export type SlotsReducerState = Slot[]

export const initialState: SlotsReducerState = []

export default function slots(state = initialState, action: Action): SlotsReducerState {
  switch (action.type) {
    case 'CHAT_SLOTS_SET':
      return union(action.payload, state)
    case 'CHAT_SESSION_RESTART':
      return initialState
    default:
      return state
  }
}
