import React from 'react'

const CalendarIcon: React.FC = () => {
  return (
    <svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="calendar-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Desktop_Date-picker-opened" transform="translate(-747.000000, -963.000000)" fill="#224BC5">
          <g id="calendar-icon-Group-9" transform="translate(747.000000, 963.000000)">
            <path
              d="M15.8535208,1.46426667 L14.0328458,1.46426667 L14.032072,0.653263542 C14.032072,0.356388542 13.7947303,0.119046875 13.4978553,0.119046875 C13.2009803,0.119046875 12.9636386,0.356388542 12.9636386,0.653263542 L12.9636386,3.44428438 C12.6466553,3.62210062 12.429422,3.95918438 12.429422,4.35501771 C12.429422,4.94876771 12.9041053,5.42345104 13.4978553,5.42345104 C14.0916053,5.42345104 14.5662886,4.94876771 14.5662886,4.35501771 C14.5662886,3.95918438 14.3482636,3.64220104 14.032072,3.44428438 L14.032072,2.53355104 L15.852747,2.53355104 C16.961397,2.53355104 17.8319136,3.42417604 17.8319136,4.51271771 L17.8319136,6.15558438 L1.16752902,6.15558438 L1.16752902,4.51271771 C1.16752902,3.42417604 2.05815323,2.53355104 3.1466949,2.53355104 L4.0767449,2.53355104 L4.07751875,1.46434583 L3.14746875,1.46434583 C1.46517708,1.46434583 0.0999479167,2.82965417 0.0999479167,4.51186667 L0.0991748542,15.8332938 C0.0991748542,17.5155852 1.46448319,18.8808144 3.1466949,18.8808144 L15.852747,18.8808144 C17.5350386,18.8808144 18.9002678,17.5155061 18.9002678,15.8332938 L18.9010417,4.51265833 C18.9010417,2.83036667 17.5357333,1.46434583 15.8535208,1.46434583 L15.8535208,1.46426667 Z M17.8326875,15.8334125 C17.8326875,16.9420625 16.9420625,17.8125788 15.8535208,17.8125788 L3.1466751,17.8125788 C2.0380251,17.8125788 1.16750923,16.9219542 1.16750923,15.8334125 L1.16750923,7.2240375 L17.8520833,7.2240375 L17.8520833,15.8334125 L17.8326875,15.8334125 Z"
              id="Fill-1"
            />
            <path
              d="M4.4733125,11.6175104 L5.3639375,11.6175104 C5.9971125,11.6175104 6.5120125,11.1026104 6.5120125,10.4694354 C6.5120125,9.83626042 5.9971125,9.32136042 5.3639375,9.32136042 L4.4733125,9.32136042 C3.8401375,9.32136042 3.3252375,9.83626042 3.3252375,10.4694354 C3.34456604,11.1033823 3.83936563,11.6175104 4.4733125,11.6175104 Z"
              id="Fill-2"
            />
            <path
              d="M9.06478125,11.6175104 L9.95540625,11.6175104 C10.5885813,11.6175104 11.1034813,11.1026104 11.1034813,10.4694354 C11.1034813,9.83626042 10.5885813,9.32136042 9.95540625,9.32136042 L9.06478125,9.32136042 C8.43160625,9.32136042 7.91670625,9.83626042 7.91670625,10.4694354 C7.91670625,11.1033823 8.43160625,11.6175104 9.06478125,11.6175104 Z"
              id="Fill-3"
            />
            <path
              d="M13.65625,11.6175124 L14.546875,11.6175124 C15.18005,11.6175124 15.69495,11.1026104 15.69495,10.4694354 C15.69495,9.83626042 15.18005,9.32136042 14.546875,9.32136042 L13.65625,9.32213427 C13.023075,9.32213427 12.508175,9.83703427 12.508175,10.4702093 C12.508175,11.1033843 13.023075,11.6175124 13.65625,11.6175124 L13.65625,11.6175124 Z"
              id="Fill-4"
            />
            <path
              d="M4.4733125,15.6748021 L5.3639375,15.6748021 C5.9971125,15.6748021 6.5120125,15.1599021 6.5120125,14.5267271 C6.5120125,13.8935521 5.9971125,13.3786521 5.3639375,13.3786521 L4.4733125,13.3786521 C3.8401375,13.3786521 3.3252375,13.8935521 3.3252375,14.5267271 C3.34456604,15.160674 3.83936563,15.6748021 4.4733125,15.6748021 Z"
              id="Fill-5"
            />
            <path
              d="M9.06478125,15.6748021 L9.95540625,15.6748021 C10.5885813,15.6748021 11.1034813,15.1599021 11.1034813,14.5267271 C11.1034813,13.8935521 10.5885813,13.3786521 9.95540625,13.3786521 L9.06478125,13.3786521 C8.43160625,13.3786521 7.91670625,13.8935521 7.91670625,14.5267271 C7.91670625,15.160674 8.43160625,15.6748021 9.06478125,15.6748021 Z"
              id="Fill-6"
            />
            <path
              d="M13.65625,15.6748021 L14.546875,15.6748021 C15.18005,15.6748021 15.69495,15.1599021 15.69495,14.5267271 C15.69495,13.8935521 15.18005,13.3786521 14.546875,13.3786521 L13.65625,13.379424 C13.023075,13.379424 12.508175,13.894324 12.508175,14.527499 C12.508175,15.160674 13.023075,15.6748021 13.65625,15.6748021 Z"
              id="Fill-7"
            />
            <path
              d="M5.50228125,5.40332292 C6.09603125,5.40332292 6.57071458,4.92863958 6.57071458,4.33488958 C6.57071458,3.93905625 6.35268958,3.62207292 6.03649792,3.42415625 L6.03649792,2.51342292 L12.0931437,2.51342292 L12.0931437,1.46430625 L6.03649792,1.46430625 L6.03649792,0.653303125 C6.03649792,0.356428125 5.79915625,0.119086458 5.50228125,0.119086458 C5.20540625,0.119086458 4.96806458,0.356428125 4.96806458,0.653303125 L4.96806458,3.44432396 C4.65108125,3.62214021 4.43384697,3.95922396 4.43384697,4.35505729 C4.43307406,4.92792708 4.90853125,5.40340208 5.50228125,5.40340208 L5.50228125,5.40332292 Z"
              id="Fill-8"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default CalendarIcon
