import React from 'react'

const Replacement: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55">
      <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1">
        <path
          fill="#224BC5"
          d="M24.161 50.587a1.723 1.723 0 01-2.04 1.322A25.683 25.683 0 013.435 35.83v6.347c0 .949-.767 1.714-1.717 1.714C.767 43.89 0 43.125 0 42.176V31.883a1.715 1.715 0 011.718-1.715H12.03a1.713 1.713 0 110 3.43H6.265a22.215 22.215 0 006.272 9.697 22.29 22.29 0 0010.296 5.256c.448.093.838.36 1.086.743.249.383.336.846.242 1.293zM30.84 2.885a1.723 1.723 0 012.04-1.322 25.683 25.683 0 0118.686 16.08v-6.347c0-.949.767-1.714 1.717-1.714.951 0 1.718.765 1.718 1.714V21.59a1.715 1.715 0 01-1.718 1.715H42.97a1.713 1.713 0 110-3.429h5.766a22.215 22.215 0 00-6.272-9.698 22.29 22.29 0 00-10.296-5.256 1.707 1.707 0 01-1.086-.743 1.724 1.724 0 01-.242-1.293z"
          transform="translate(-145 -1182) translate(145 1182)"
        />
        <path
          fill="#27AEE4"
          d="M38.194 30.556h9.167A7.639 7.639 0 0155 38.194v9.167A7.639 7.639 0 0147.361 55h-9.167a7.639 7.639 0 01-7.638-7.639v-9.167a7.639 7.639 0 017.638-7.638zm0 3.055a4.583 4.583 0 00-4.583 4.583v9.167a4.583 4.583 0 004.583 4.583h9.167a4.583 4.583 0 004.583-4.583v-9.167a4.583 4.583 0 00-4.583-4.583h-9.167z"
          transform="translate(-145 -1182) translate(145 1182)"
        />
        <path
          fill="#224BC5"
          d="M7.639 0h9.167a7.639 7.639 0 017.638 7.639v9.167a7.639 7.639 0 01-7.638 7.638H7.639A7.639 7.639 0 010 16.806V7.639A7.639 7.639 0 017.639 0zm0 3.056a4.583 4.583 0 00-4.583 4.583v9.167a4.583 4.583 0 004.583 4.583h9.167a4.583 4.583 0 004.583-4.583V7.639a4.583 4.583 0 00-4.583-4.583H7.639z"
          transform="translate(-145 -1182) translate(145 1182)"
        />
      </g>
    </svg>
  )
}

export default Replacement
