import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { bp } from '@extend/client-helpers'

const Section: FC<{ title?: JSX.Element | string }> = ({ children, title }) => (
  <Row>
    <ColHeader>{title && <Title>{title}</Title>}</ColHeader>
    <Col>{children}</Col>
  </Row>
)

const Row = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '1380px',
  justifyItems: 'center',
  margin: '0 auto',
  padding: '40px 0',
  minWidth: 300,

  '& + &': {
    paddingTop: 0,
  },

  [bp.md]: {
    flexDirection: 'row',
    maxWidth: 1024,
  },
})

const ColHeader = styled.div({
  minWidth: 300,
  paddingLeft: 0,
  [bp.md]: {
    paddingLeft: 40,
  },
})

const Col = styled.div({
  flex: 1,
})

const H2 = styled.h2({
  fontSize: 22,
  lineHeight: 1,
  margin: '0 0 18px 0',
  whiteSpace: 'nowrap',
})

const Title = styled(H2)({
  fontWeight: 300,
  marginBottom: 40,
})

const DescriptionList = styled.dl({
  marginTop: 0,
  marginBottom: 0,

  '& dt': {
    fontSize: 18,
    fontWeight: 700,
    width: 200,
  },

  '& dd': {
    fontSize: 18,
    marginLeft: 20,
    '&:after': {
      content: '""',
      display: 'block',
      marginBottom: 16,
    },
  },

  [bp.md]: {
    '& dt': {
      display: 'inline-block',
    },
    '& dd': {
      display: 'inline',

      '&:last-child:after': {
        margin: 0,
      },
    },
  },
})

export { Section, DescriptionList }
