import type { Locale } from '../types/localization'
import { SUPPORTED_LANGUAGES } from '../types/localization'

export const injectParams = (params: { [param: string]: string | undefined }, path: string): string => {
  const keys = Object.keys(params)

  if (!keys.length) {
    return path
  }

  return keys.reduce((str, key) => str.replace(`:${key}`, params[key] as string), path)
}

export const determineLocale = (pathname: string): Locale => {
  const locale = pathname.split('/')[1]

  if (SUPPORTED_LANGUAGES.includes(locale)) {
    return locale as Locale
  }

  const browserLanguage = navigator.language
  if (browserLanguage && SUPPORTED_LANGUAGES.includes(browserLanguage)) {
    return browserLanguage as Locale
  }

  return 'en-US'
}

export const getSubDomainName = (): string => {
  return window.location.host.split('.')[0]
}

export const isExtendDomain = (subdomainName: string): subdomainName is 'customers' | 'localhost:3000' => {
  return subdomainName === 'customers' || subdomainName === 'localhost:3000'
}
