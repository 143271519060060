import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { bp } from '@extend/client-helpers'

export const DefectiveProductDetails: FC = () => {
  return (
    <DetailsWrapper>
      Your defective product is on it’s way back to us! We’ll notify you with next steps once your replacement has been
      processed.
    </DetailsWrapper>
  )
}

const DetailsWrapper = styled.div({
  [bp.lg]: {
    width: 400,
    fontSize: 16,
    lineHeight: '24px',
  },
  [bp.md]: {
    width: 270,
  },
  fontSize: 15,
  lineHeight: '20px',
  width: 307,
  height: '100%',
})
