import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Badge } from '@extend/zen'
import { COLOR, bp, Text } from '@customers-ui'
import { mapContractStatusBadge } from '../../lib/helper-functions'
import type { ProductDetailsProp } from './contract-details-page'
import type { CategoryContract, ContractsSearchIndividual, ExtendedWarrantyContract } from '../../types/contract'

export interface ContractDetailsHeaderProps {
  contract: ContractsSearchIndividual
  isRefreshToastVisible: boolean
  product: ProductDetailsProp
}

export const isADH = (coverage: string): boolean => coverage === 'adh'

const ContractDetailsHeader: FC<ContractDetailsHeaderProps> = ({ contract, isRefreshToastVisible, product }) => {
  const { planDetails } = contract as ExtendedWarrantyContract | CategoryContract
  const isADHPlan = planDetails ? isADH(planDetails.coverage_includes) : false
  const statusBadge = mapContractStatusBadge(contract.status)

  return (
    <ContractDetailsHeaderWrapper data-cy="contract-details-header">
      <StatusWrapper>
        <ContractStatusBadge
          {...{
            'data-cy': 'contractStatus',
            color: statusBadge === 'Active' ? 'green' : 'neutral',
            isRefreshToastVisible: isRefreshToastVisible,
            text: statusBadge,
          }}
        />
        <PlanType isRefreshToastVisible={isRefreshToastVisible} data-cy="plan-type">
          {isADHPlan ? 'Extend Protection Plan' : 'Base Plan'}
        </PlanType>
      </StatusWrapper>
      <ProductName numberOfLines={2} data-cy="covered-product">
        COVERED PRODUCT: {product.name ?? product.title}
      </ProductName>
    </ContractDetailsHeaderWrapper>
  )
}

const ContractDetailsHeaderWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  margin: 0,
  color: COLOR.NEUTRAL[1000],
  boxSizing: 'border-box',
  [bp.mobile]: {
    padding: '24px 0',
  },
  [bp.desktop]: {
    padding: '60px 0 40px',
  },
})

const StatusWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  [bp.mobile]: {
    gap: '8px',
  },
  [bp.desktop]: {
    gap: '16px',
  },
})

const PlanType = styled.div<{ isRefreshToastVisible: boolean }>(({ isRefreshToastVisible }) => ({
  textTransform: 'uppercase',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '18px',
  [bp.mobile]: {
    marginTop: '19px',
    marginBottom: '16px',
    display: isRefreshToastVisible ? 'none' : 'block',
  },
  [bp.desktop]: {
    display: 'flex',
    marginTop: 0,
    marginBottom: 0,
  },
}))

const ProductName = styled(Text)({
  [bp.mobile]: {
    fontSize: 24,
    lineHeight: '32px',
  },
  [bp.desktop]: {
    fontSize: 36,
    lineHeight: '48px',
  },
})

const ContractStatusBadge = styled(Badge)<{ isRefreshToastVisible: boolean }>(({ isRefreshToastVisible }) => ({
  alignSelf: 'flex-start',
  [bp.desktop]: {
    padding: '8px 12px',
    borderRadius: '17px',
  },
  [bp.desktop]: {
    display: isRefreshToastVisible ? 'none' : 'block',
  },
}))

export default ContractDetailsHeader
