import type { Action } from 'redux'

interface ErrorAction<T extends string> extends Action {
  type: T
  payload: ErrorObject
}

interface ErrorObject {
  name?: string
  message: string
  statusCode?: number
}

export type ErrorReducerState = ErrorObject | null

type ErrorReducer<TAction extends Action> = (state: ErrorReducerState | undefined, action: TAction) => ErrorReducerState

type BooleanReducer<TAction extends Action> = (state: boolean | undefined, action: TAction) => boolean

interface FSAction<T = string, P = any> {
  type: T
  payload?: P
}

export function createBooleanReducer<TAction extends Action>(
  onTypes: Array<TAction['type']>,
  offTypes: Array<TAction['type']>,
): BooleanReducer<TAction> {
  return function reducer(state = false, action: TAction): boolean {
    if (onTypes.includes(action.type)) {
      return true
    }
    if (offTypes.includes(action.type)) {
      return false
    }
    return state
  }
}

export function createErrorReducer<TAction extends FSAction>(
  errorTypes: Array<TAction['type']>,
  resetTypes: Array<TAction['type']>,
): ErrorReducer<TAction> {
  return function reducer(state = null, action: TAction): ErrorReducerState {
    if (errorTypes.includes(action.type)) {
      return (action as ErrorAction<string>).payload
    }
    if (resetTypes.includes(action.type)) {
      return null
    }
    return state
  }
}
