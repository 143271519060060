import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { GlobalStyles } from '@customers-ui'

export interface PageLayoutProps {
  className?: string
}

const PageLayout: FC<PageLayoutProps> = ({ children, className }) => {
  return (
    <StyledPageLayout className={className}>
      <GlobalStyles />
      {children}
    </StyledPageLayout>
  )
}

const StyledPageLayout = styled.div({
  position: 'relative',
  overflow: 'auto',
  minHeight: 'calc(100vh - 86px)',
  minWidth: '100%',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

export { PageLayout }
