import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { bp, COLOR, ContentLayout, Spinner, Toast } from '@customers-ui'
import styled from '@emotion/styled'
import {
  useSearchContractsV1Query,
  getRTKQueryErrorMessage,
  useListOrdersQuery,
  adjudicationManagementApi,
} from '@customers-api-rtk-query'
import { ContractType } from '@customers-api-client'
import { InlineAlert, InlineAlertColor } from '@extend/zen'
import { useHistory } from 'react-router-dom'
import { customLogger, formatPhoneNumberLocal } from '@extend/client-helpers'
import * as selectors from '../../reducers/selectors'
import { EmptyPageContent } from './empty-page-content'
import { logEvent } from '../../analytics'
import { PlansPageHeader } from './plans-page-header'
import type { RootState } from '../../reducers'
import type { ContractsSearchIndividual } from '../../types/contract'
import { ContractListContainer } from './contract-list-container'
import { getAccountInfo } from '../../lib/consumer-profile-utils'

interface PlansPageProps {
  decodedAccessToken: ReturnType<typeof selectors.getDecodedAccessToken>
}

const Component: FC<PlansPageProps> = ({ decodedAccessToken }) => {
  const dispatch = useDispatch()
  const [isVisible, setIsVisible] = useState(false)
  const email = decodedAccessToken ? decodedAccessToken.email : ''
  const phoneNumber = decodedAccessToken ? decodedAccessToken.phone_number : ''
  const history = useHistory()
  const consumerProfile = useSelector(selectors.getConsumerProfile)
  const { isPhoneNumberVerified, isEmailVerified } = getAccountInfo(consumerProfile)

  const userContact = decodedAccessToken?.email || formatPhoneNumberLocal(decodedAccessToken?.phone_number as string)

  const {
    data: { contracts } = {},
    isLoading: isContractsSearchLoading,
    error,
  } = useSearchContractsV1Query({
    customerEmail: email,
    customerPhone: phoneNumber,
    typeFilter: [
      ContractType.PCRS,
      ContractType.SHIPPING_PROTECTION,
      ContractType.CATEGORY,
      ContractType.PRODUCT_PROTECTION_BUNDLE,
    ],
    // we need this to see the productsList for category/pp_bundle contracts.
    showAll: true,
  })

  const { data: emsOrdersResponse, isLoading: isListOrdersLoading } = useListOrdersQuery({})
  const isLoading = isContractsSearchLoading || isListOrdersLoading

  // Error logging for requests
  const [hasLoggedError, setHasLoggedError] = useState(false)
  if (error && !hasLoggedError) {
    const errorMessage = getRTKQueryErrorMessage(error)
    customLogger.warn(`[Err: Plans Page]: ${errorMessage}`)
    setHasLoggedError(true)
  }

  useEffect(() => {
    if (error && !isVisible) {
      setIsVisible(true)
    }
    if (!isLoading && isEmpty(contracts)) {
      logEvent('My Orders - No Contracts Found', 'View My Orders')
    }
    if (!isLoading && !isEmpty(contracts)) {
      logEvent('My Orders - Contracts Found for User', 'View My Orders')
    }
  }, [error, isVisible, isLoading, contracts])

  // Invalidate cache to execute precheck every time the user navigates to this page to update it after a claim is filed
  useEffect(() => {
    setTimeout(() => dispatch(adjudicationManagementApi.util.invalidateTags(['adjudication-management'])))
  }, [])

  const handleReload = (): void => {
    window.location.reload()
  }

  const handleVerifyClick = (): void => {
    history.push('/profile')
  }

  return (
    <ContentLayout pageTitle="My Orders" data-cy="plans-page-content-layout">
      <Toast message="We've encountered an error. Please refresh this page." type="danger" isVisible={isVisible}>
        <RefreshButton onClick={handleReload}>Refresh</RefreshButton>
      </Toast>
      <PlansPageContentWrapper>
        <PlansPageContent data-cy="plans-page-content">
          {!isEmpty(consumerProfile) && !isEmailVerified && (
            <InlineAlert
              data-cy="no-email-alert"
              color={InlineAlertColor.red}
              primaryButtonProps={{
                onClick: handleVerifyClick,
                text: 'Verify Now',
                'data-cy': 'verify-email-button',
              }}
            >
              To process your claim, let&apos;s verify your email. We&apos;ll never share your information or spam you.
            </InlineAlert>
          )}

          {!isEmpty(consumerProfile) && !isPhoneNumberVerified && (
            <InlineAlert
              data-cy="no-phone-number-alert"
              color={InlineAlertColor.red}
              primaryButtonProps={{
                onClick: handleVerifyClick,
                text: 'Verify Now',
                'data-cy': 'verify-phone-button',
              }}
            >
              To process your claim, let&apos;s verify your phone number. We&apos;ll never share your information or
              spam you.
            </InlineAlert>
          )}
          {!isLoading && !error && <PlansPageHeader />}
          {isLoading && (
            <SpinnerLoading>
              <Spinner size="md" />
            </SpinnerLoading>
          )}
          {!isLoading && !!contracts && !!emsOrdersResponse && (!!contracts.length || !!emsOrdersResponse.length) && (
            <ContractListContainer
              contracts={contracts as unknown as ContractsSearchIndividual[]}
              emsOrdersResponse={emsOrdersResponse}
            />
          )}
          {!isLoading && !error && isEmpty(contracts) && isEmpty(emsOrdersResponse) && (
            <EmptyPageContent
              primaryButtonText="Log in with a different account"
              primaryButtonUrl="/authentication"
              headerText="No orders found."
            >
              We couldn&apos;t find any orders for <b>{userContact}</b>. Try logging in with the phone or email linked
              to your order.
            </EmptyPageContent>
          )}
        </PlansPageContent>
      </PlansPageContentWrapper>
    </ContentLayout>
  )
}

const PlansPageContentWrapper = styled.div({
  [bp.desktop]: {
    display: 'flex',
    justifyContent: 'center',
  },
})

const PlansPageContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  [bp.mobile]: {
    margin: '32px 10px 40px',
  },
  [bp.desktop]: {
    margin: '56px 156px 24px',
    width: '1134px',
  },
})

const SpinnerLoading = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 20px',
})

const RefreshButton = styled.button({
  [bp.mobile]: {
    display: 'none',
  },
  [bp.desktop]: {
    display: 'flex',
    backgroundColor: COLOR.RED['200'],
    color: COLOR.RED['700'],
    fontSize: 14,
    borderRadius: 25,
    border: `1px solid ${COLOR.RED['700']}`,
    padding: '7px 26px',
    lineHeight: '19px',
    fontWeight: 700,
    marginLeft: 44,
  },
  '&:hover': {
    cursor: 'pointer',
  },
})

const PlansPage = connect(
  (state: RootState): PlansPageProps => ({
    decodedAccessToken: selectors.getDecodedAccessToken(state),
  }),
)(Component)

export type { PlansPageProps }
export { Component, PlansPage }
