import type { Action } from '../../types/action-type'

export type AccessTokenReducerState = string | null

export const initialState: AccessTokenReducerState = null

export default function accessToken(state = initialState, action: Action): AccessTokenReducerState {
  switch (action.type) {
    case 'CHAT_SESSION_CONNECT_SUCCESS':
      return action.payload.accessToken
    default:
      return state
  }
}
