import React from 'react'

const FreeShipping: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="48" viewBox="0 0 57 48">
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        transform="translate(-735 -1331) translate(737 1333)"
      >
        <path
          stroke="#224BC5"
          d="M48.27 39.104h2.292a2.291 2.291 0 002.292-2.291V25.354l-4.643-13a4.583 4.583 0 00-4.315-3.041h-9.375v20.624H.146v6.875a2.291 2.291 0 002.292 2.292H7.02"
        />
        <path stroke="#224BC5" d="M16.188 39.104L39.104 39.104" />
        <path
          stroke="#224BC5"
          d="M.146 29.938V4.729A4.585 4.585 0 014.729.146h25.209a4.585 4.585 0 014.583 4.583v4.583"
        />
        <path stroke="#27AEE4" d="M39.1041667 13.8958333L39.1041667 23.0625 47.125 23.0625" />
        <path
          stroke="#224BC5"
          d="M16.188 39.104a4.585 4.585 0 01-4.584 4.584 4.585 4.585 0 01-4.583-4.584 4.585 4.585 0 014.583-4.583 4.585 4.585 0 014.584 4.583zM48.27 39.104a4.585 4.585 0 01-4.583 4.584 4.585 4.585 0 01-4.583-4.584 4.585 4.585 0 014.584-4.583 4.585 4.585 0 014.583 4.583z"
        />
      </g>
    </svg>
  )
}

export default FreeShipping
