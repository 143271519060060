import type { Prompt, Slot } from '@customers-api-client'
import { getOr } from 'lodash/fp'
import type { Action } from '../../types/action-type'

export interface PromptReducerState {
  prompt: Prompt | null
  lastPromptSlot: Slot | null
  isPromptHidden: boolean
}

export const initialState: PromptReducerState = {
  prompt: null,
  lastPromptSlot: null,
  isPromptHidden: false,
}

export default function prompt(state = initialState, action: Action): PromptReducerState {
  switch (action.type) {
    case 'CHAT_PROMPT_CLEAR':
      return { ...state, isPromptHidden: false, prompt: null }
    case 'CHAT_SESSION_UPDATE_REQUEST':
      return { ...state, isPromptHidden: true }
    case 'CHAT_SESSION_UPDATE_SUCCESS':
      return {
        ...state,
        prompt: null,
        // The ending messages from Kaley do not have a `prompt` value this adds a
        // `null` value for the prompt, allowing the program to end without a `slot`
        lastPromptSlot: getOr(null, 'slot', action.payload.prompt),
      }
    case 'CHAT_SESSION_CONNECT_SUCCESS':
      return {
        ...state,
        lastPromptSlot: getOr(null, 'slot', action.payload.prompt as unknown as Slot),
      }
    case 'CHAT_SESSION_CONNECT_FAILURE':
      return {
        ...state,
        prompt: null,
      }
    case 'CHAT_PROMPT_SET':
      return { ...state, prompt: action.payload, isPromptHidden: false }
    case 'CHAT_PROMPT_MULTISELECT_INIT':
      return { ...state, prompt: action.payload, isPromptHidden: true }
    case 'CHAT_PROMPT_MULTISELECT_SHOW':
      return { ...state, isPromptHidden: false }
    case 'CHAT_PROMPT_MULTISELECT_HIDE':
      return { ...state, isPromptHidden: true }
    case 'CHAT_SESSION_SET_LAST_SLOT':
      return { ...state, lastPromptSlot: action.payload }
    default:
      return state
  }
}
