import React from 'react'

const MailEnabled: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17" width="17px">
      <path
        fill="#224BC5"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        d="M1031.531 971.822l-15.32-7.76c-.687-.22-1.344.158-1.188.947l2.189 5.93 9.379 1.546-9.379 1.576-2.189 5.93c-.156.79.5 1.167 1.189.947l15.32-7.79c.624-.253.624-1.041 0-1.325v-.001z"
        transform="translate(-1015 -964)"
      />
    </svg>
  )
}

export default MailEnabled
