import styled from '@emotion/styled'
import type { ColorGroupKeys } from '@extend/zen'
import { ArrowDropDown, ArrowDropDownClose, Button, COLOR } from '@extend/zen'
import type { FC } from 'react'
import React from 'react'
import { bp } from '@customers-ui'
import { isMobile } from 'react-device-detect'
import { images } from '../../lib/assets'
import type { CardListItemProps } from './types'
import { CardMenuItems } from './card-menu-items'
import { getDisplayItems } from './utils'

export const CardListItems: FC<{
  color: ColorGroupKeys
  'data-cy': string
  listItems: CardListItemProps[]
  listKey: string
  showMoreThreshold?: number
  showMoreText?: string
  showLessText?: string
  isExpanded: boolean
  toggleExpand: (key: string) => void
  extendLiveOverride?: Partial<Record<keyof CardListItemProps, string>>
}> = ({
  color,
  'data-cy': dataCy,
  listKey,
  listItems,
  showMoreThreshold = 3,
  showMoreText = 'View All',
  showLessText = 'View Less',
  isExpanded,
  toggleExpand,
  extendLiveOverride,
}) => {
  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>): void => {
    event.currentTarget.src = images.fallbackImage
  }

  const hasManyItems = listItems.length > showMoreThreshold
  let numberOfItemsToShow = showMoreThreshold

  const displayItems = getDisplayItems<CardListItemProps>(listItems, numberOfItemsToShow, showMoreThreshold, isExpanded)

  return (
    <ItemListWrapper data-cy={`${dataCy}-item-list-wrapper`}>
      <ItemsList data-cy={`${dataCy}-items-list`}>
        {displayItems.map(({ image, text, subText, primaryButtonProps, secondaryButtonProps, menuItems }, index) => {
          numberOfItemsToShow--
          const key = `${dataCy}-list-item-${listKey}-${index}`
          return (
            <Item key={key} data-cy={`item-${key}`}>
              <ItemDetailsLeft>
                {image && (
                  <Image
                    src={image || images.defaultCarouselImage}
                    onError={(e) => handleImageError(e)}
                    data-cy={extendLiveOverride?.image || `${key}-image`}
                    data-extend-live={`${key}-image`}
                  />
                )}
                <TextWrapper>
                  {text && <Text data-cy={`${key}-text`}>{text}</Text>}
                  {subText && <SubText data-cy={`${key}-subtext`}>{subText}</SubText>}
                </TextWrapper>
                {secondaryButtonProps && (
                  <Button
                    {...secondaryButtonProps}
                    color={color}
                    data-cy={`${key}-secondary-button`}
                    fillContainer={isMobile ? true : false}
                  />
                )}
              </ItemDetailsLeft>
              <ItemDetailsRight isMobile={isMobile}>
                {primaryButtonProps && (
                  <Button {...primaryButtonProps} color={color} data-cy={`${key}-primary-button`} fillContainer />
                )}
                {menuItems && menuItems.length > 0 && (
                  <CardMenuItems
                    menuKey={`${key}-menu-items`}
                    data-cy={`${key}-menu-items`}
                    color={color}
                    menuItems={menuItems}
                  />
                )}
              </ItemDetailsRight>
            </Item>
          )
        })}
      </ItemsList>
      {hasManyItems && (
        <Button
          key={listKey}
          color={color}
          data-cy={`${listKey}-${dataCy}-show-more-items-button`}
          text={isExpanded ? showLessText : showMoreText}
          icon={isExpanded ? ArrowDropDownClose : ArrowDropDown}
          onClick={() => toggleExpand(listKey)}
          emphasis="low"
        />
      )}
    </ItemListWrapper>
  )
}

const ItemListWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
})

const ItemsList = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
})

const Item = styled.div({
  display: 'flex',
  gap: '8px',
  justifyContent: 'space-between',
  [bp.mobile]: {
    flexDirection: 'column',
  },
  [bp.desktop]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  transition: 'all 250ms ease-in-out',
})

const Image = styled.img({
  width: '56px',
  height: '56px',
  borderRadius: '8px',
})

const Text = styled.div({
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
})

const SubText = styled.div({
  color: COLOR.NEUTRAL[700],
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '22px',
})

const TextWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
})

const ItemDetailsLeft = styled.div({
  display: 'flex',
  gap: '16px',
  flexDirection: 'row',
  [bp.mobile]: {
    alignItems: 'flex-start',
  },
  [bp.desktop]: {
    alignItems: 'center',
  },
})

const ItemDetailsRight = styled.div<{ isMobile: boolean }>(({ isMobile }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  justifyContent: 'flex-end',
  width: isMobile ? '100%' : '234px',
}))
