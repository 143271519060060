import React from 'react'

const QuoteRight: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="43" viewBox="0 0 54 43">
      <path
        fill="#385DCA"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        d="M1075.552 2919v14.267c0 5.549-.906 10.635-2.72 15.258-1.813 4.624-4.936 9.116-9.37 13.475l-7.656-5.945c5.239-5.68 8.194-11.295 8.866-16.843H1055V2919h20.552zm33.448 0v14.267c0 5.549-.907 10.635-2.72 15.258-1.814 4.624-4.937 9.116-9.37 13.475l-7.656-5.945c5.239-5.68 8.194-11.295 8.865-16.843h-9.671V2919H1109z"
        transform="translate(-1055 -2919)"
      />
    </svg>
  )
}

export default QuoteRight
