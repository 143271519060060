import * as pages from '../pages'

// Routes in this file are for Shipping Protection
// Should be conditionally used via FF
// Once FF removed, move into routes.ts

export const routes = [
  {
    path: '/shipping_protection_plan_details/:id',
    component: pages.ShippingProtectionPlanDetailsPage,
  },
  {
    path: '/shipping_protection_plan_details',
    component: pages.ShippingProtectionPlanDetailsPage,
  },
]
