import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Helmet } from 'react-helmet'
import { configureDataDogRum } from '@extend/client-helpers'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { LD_CLIENT_ID_CUSTOMERS } from '@helloextend/client-constants'
import { ChakraProvider, GlobalTheme } from '@customers-ui'
import { ThemeProvider } from '@emotion/react'
import { Tooltip, Toaster, ToasterPosition, getThemeByName } from '@extend/zen'
import { ErrorBoundary } from './components/common'
import { SystemError } from './components/system-error'
import { Router } from './router/router'
import { configureStore } from './store'
import { defaultLDFlagValues } from './constants/ld-flags'
import '@extend/zen/dist/style.css'

const store = configureStore()
;(async () => {
  configureDataDogRum(10, 10)
  const LDProvider = await asyncWithLDProvider({
    clientSideID: LD_CLIENT_ID_CUSTOMERS,
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
    flags: defaultLDFlagValues,
    // any FF values that are set as a property here will be the enabled and the default value that LD listens to and acts upon. Upon first network call LaunchDarkly calls all FF, then only listens to FF specified here
  })
  ReactDOM.render(
    <ErrorBoundary page={SystemError}>
      <LDProvider>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        </Helmet>
        <Provider store={store}>
          <ThemeProvider theme={getThemeByName('consumers')}>
            <ChakraProvider theme={GlobalTheme}>
              <Toaster data-cy="toaster" toasterPosition={ToasterPosition.left}>
                <Tooltip />
                <Router />
              </Toaster>
            </ChakraProvider>
          </ThemeProvider>
        </Provider>
      </LDProvider>
    </ErrorBoundary>,
    document.getElementById('root'),
  )
})()
