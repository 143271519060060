import type { FC } from 'react'
import React from 'react'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { COLOR } from '@customers-ui'
import type { Card } from '@customers-api-client'

interface TrackCardDetailsProps {
  cardData: Card
}

export const TrackCardDetails: FC<TrackCardDetailsProps> = ({ cardData }) => {
  const { sellerName, updatedAt, title, imageUrl } = cardData

  return (
    <Flex>
      <ProductImage src={imageUrl} alt={title} />
      <ProductDescription>
        <ProductTitle>{title}</ProductTitle>
        <ProductHeader>STORE:</ProductHeader>
        <div>{sellerName}</div>
        <ProductHeader>LAST UPDATE:</ProductHeader>
        <div>{dayjs(updatedAt).format('MMM DD, YYYY')}</div>
      </ProductDescription>
    </Flex>
  )
}

const Flex = styled.div({
  display: 'flex',
})

const ProductTitle = styled.div({
  fontWeight: 700,
})

const ProductHeader = styled.div({
  color: COLOR.NEUTRAL[600],
  fontSize: 10,
  fontWeight: 700,
})

const ProductDescription = styled.div({
  fontSize: 13,
  marginBottom: 26,
  '*': {
    marginBottom: 3,
  },
})

const ProductImage = styled.img({
  maxWidth: 84,
  marginRight: 16,
  objectFit: 'contain',
  alignSelf: 'flex-start',
})
