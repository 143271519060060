import { COLOR, Dots, Modal } from '@extend/zen'
import { useGetStoreConsumerQuery } from '@customers-api-rtk-query'
import React from 'react'
import { formatPhoneNumberLocal } from '@extend/client-helpers'
import { isMobile } from 'react-device-detect'
import styled from '@emotion/styled'

interface ContactMerchantModalProps {
  onDismissRequest: () => void
  storeId: string
}

const ContactMerchantModal: React.FC<ContactMerchantModalProps> = ({ onDismissRequest, storeId }) => {
  const { data: store, isFetching: isFetchingStoreInfo } = useGetStoreConsumerQuery({
    storeId,
    includeDeleted: 'true',
  })

  return (
    <Modal
      heading="Contact merchant to file a claim"
      onDismissRequest={() => onDismissRequest()}
      primaryButtonProps={{
        onClick: () => onDismissRequest(),
        text: 'Close',
        emphasis: 'medium',
      }}
      data-cy="contact-merchant-modal"
      isMobile={isMobile}
    >
      {isFetchingStoreInfo ? (
        <Dots isCenteredInParent={true} speed="fast" data-cy="dots" />
      ) : (
        <>
          To file a claim for {store?.name} products, you&apos;ll need to contact {store?.name} Customer Service via:
          <ListContainer>
            <ul>
              {store?.supportContact?.phoneNumber?.number && (
                <li data-cy="phoneListItem">
                  Phone: {formatPhoneNumberLocal(store.supportContact.phoneNumber.number)}
                </li>
              )}
              {store?.supportContact?.email && <li data-cy="emailListItem">Email: {store.supportContact.email}</li>}

              {store?.supportContact?.url && (
                <li data-cy="websiteListItem">
                  Website:{' '}
                  <SupportContactLink href={store.supportContact.url} target="_blank" referrerPolicy="no-referrer">
                    {store.supportContact.url}
                  </SupportContactLink>
                </li>
              )}
            </ul>
          </ListContainer>
        </>
      )}
    </Modal>
  )
}

const SupportContactLink = styled.a({
  color: COLOR.BLUE[800],
})

const ListContainer = styled.div({
  listStyleType: 'none',
  padding: 0,
  margin: 0,
  textAlign: 'left',
  '& li': {
    marginLeft: 24,
  },
})

export default ContactMerchantModal
