import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { bp } from '@extend/client-helpers'
import { string } from '@extend/client-helpers'
import { COLOR } from '@customers-ui'
import type { PlanDetails } from '@customers-api-client'

import { svgs } from '../../../lib/assets'
import { Logo } from '../../../components/common'

export const BAR_GRAPH_MAX_WIDTH = 897

export interface PlanBarGraphProps {
  isAdh: boolean
  planDetails: PlanDetails['planDetails']
  termLengthInYears: number
}

const PlanBarGraph: FC<PlanBarGraphProps> = ({ isAdh, planDetails, termLengthInYears }) => {
  const { percentRemaining, percentage } = string.getPercentageCovered({
    planYears: termLengthInYears,
    isAdh,
  })

  if (isAdh) {
    return (
      <PlanBarGraphWrapper isAdh>
        <Wrapper>
          <Bar bgColor={COLOR.BLUE[500]} width={percentRemaining}>
            <svgs.Manufactured />
            <WarrantyName>{planDetails.calculatorManufacturersWarrantyLabel}</WarrantyName>
          </Bar>
          <Bar width={percentage} bgColor={COLOR.BLUE[700]} />
        </Wrapper>
        <Wrapper>
          <Bar color={COLOR.BLUE[1000]} width={percentRemaining}>
            <Logo isShield />
            <WarrantyName>{planDetails.calculatorAccidentalProtectionLabel}</WarrantyName>
          </Bar>
          <Bar width={percentage} color={COLOR.BLUE[1000]}>
            <Logo isShield />
            <WarrantyName>{planDetails.calculatorAccidentMalfunctionProtectionLabel}</WarrantyName>
          </Bar>
        </Wrapper>
      </PlanBarGraphWrapper>
    )
  }

  return (
    <PlanBarGraphWrapper>
      <Bar bgColor={COLOR.BLUE[500]} width={percentRemaining}>
        <svgs.Manufactured />
        <WarrantyName>{planDetails.calculatorManufacturersWarrantyLabel}</WarrantyName>
      </Bar>
      <Bar width={percentage} color={COLOR.BLUE[1000]}>
        <Logo isShield />
        <WarrantyName>{planDetails.calculatorExtendProtectionLabel}</WarrantyName>
      </Bar>
    </PlanBarGraphWrapper>
  )
}

const PlanBarGraphWrapper = styled.div<{ isAdh?: boolean }>(
  {
    display: 'none',
    marginTop: 24,
  },
  ({ isAdh }) => ({
    [bp.md]: {
      display: isAdh ? 'block' : 'flex',
    },
  }),
)

const WarrantyName = styled.p({
  textAlign: 'center',
  padding: '2px 8px 0',
})

const Wrapper = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  height: 'auto',
  marginTop: 1,
  '& div:first-of-type': {
    marginRight: 1,
  },
  [bp.md]: {
    display: 'flex',
  },
  [bp.xl]: {
    width: '89%',
  },
  [bp.xxl]: {
    width: '100%',
  },
})

const Bar = styled.div<{ bgColor?: string; width: string; color?: string }>(
  {
    padding: '0 8px',
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    fontSize: 14,
    lineHeight: 1,
    '& svg': {
      width: 19,
      height: 22,
    },
  },
  ({ bgColor, width, color }) => ({
    background: bgColor || COLOR.WHITE,
    width,
    color: color || 'inherit',
    padding: width === '0%' ? '0' : '0 8px',
    visibility: width === '0%' ? 'hidden' : 'visible',
  }),
)

export { PlanBarGraph }
