import type { FC, KeyboardEvent } from 'react'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { Slot } from '@customers-api-client'
import { COLOR } from '@customers-ui'

import { chatActions } from '../../../actions'

export interface ChatResendCodeProps {
  text: string
  value: string
}

const ChatResendCode: FC<ChatResendCodeProps> = ({ text, value }) => {
  const dispatch = useDispatch()

  const handleClick = useCallback((): void => {
    dispatch(
      chatActions.chatSessionUpdate({
        slot: Slot.VerificationCode,
        message: 'Send me a new code.',
        value,
      }),
    )
  }, [dispatch, value])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent): void => {
      if ([' ', 'Enter', 'Spacebar'].includes(event.key)) {
        event.preventDefault()
        handleClick()
      }
    },
    [handleClick],
  )

  return (
    <ResendCode data-cy="chat-resend-code" role="button" tabIndex={0} onClick={handleClick} onKeyDown={handleKeyDown}>
      {text}
    </ResendCode>
  )
}

const ResendCode = styled.span({
  position: 'relative',
  textDecoration: 'none',
  color: COLOR.BLUE[800],
  '&:visited': {
    color: COLOR.BLUE[800],
  },
  '&:hover': {
    cursor: 'pointer',
  },
})

export { ChatResendCode }
