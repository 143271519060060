import React from 'react'
import type { FC } from 'react'
import type { Claim as ApiClientClaim, ClaimPhotosGetResponse, ServiceOrder } from '@customers-api-client'
import type { MerchantServicingSettings } from '@customers-api-rtk-query'
import {
  ClaimPreparingState,
  ClaimDenied,
  ClaimPendingExtendOwnedLabelTimeline,
  CustomerPayoutTimeline,
  ClaimManualReviewTimeline,
  ClaimClosedState,
  FulfilledTimeline,
  RepairAcceptedServicerOwnedTimeline,
  ManualReplacementApprovedTimeline,
  RepairInProgressTimeline,
  ClaimPendingAdjudication,
} from './timelines'
import type { EntitlementSearchByOrdersResponse } from '@customers-api-rtk-query'
import type { Claim } from '../../types/claim'
import { hasUnmetPhotoRequirements } from '../../lib/helper-functions'
import { ClaimPendingPhotoRequirements } from './timelines/claim-pending-photo-requirements'
import { isDepotRepair, isOnsiteRepair } from '../../lib/type-guards'
import { AutomatedReplacementApprovedTimeline } from './timelines/automated-replacement-approved-timeline'

export interface ClaimStateMachineProps {
  claim: ApiClientClaim
  serviceOrders?: ServiceOrder[]
  merchantServicingSettings?: MerchantServicingSettings
  emsData?: EntitlementSearchByOrdersResponse
  photoData?: ClaimPhotosGetResponse
  handleResultModalOpen: () => void
}

export const ClaimStateMachine: FC<ClaimStateMachineProps> = ({
  claim,
  serviceOrders,
  merchantServicingSettings,
  emsData,
  photoData,
  handleResultModalOpen,
}) => {
  const getActiveServiceOrder = (): ServiceOrder | undefined => {
    if (!serviceOrders?.length) return undefined
    return [...serviceOrders].sort((a, b) => b.updatedAt - a.updatedAt)[0]
  }
  // Claim pending_adjudication, denied, review, and closed don't need SO render before trying to load
  // service orders or merchant servicing settings
  if (claim.status === 'pending_adjudication') {
    if (hasUnmetPhotoRequirements(claim as unknown as Claim, photoData) && !!claim?.customer.shippingAddress) {
      return (
        <ClaimPendingPhotoRequirements
          claim={claim as unknown as Claim}
          data-cy="claim-pending-photo-requirements"
          photoData={photoData}
          handleResultModalOpen={handleResultModalOpen}
        />
      )
    }

    return <ClaimPendingAdjudication data-cy="claim-pending-no-requirements" claim={claim as unknown as Claim} />
  }

  if (claim.status === 'denied') {
    return <ClaimDenied data-cy="claim-denied" claim={claim} />
  }
  if (claim.status === 'review') {
    return <ClaimManualReviewTimeline />
  }
  if (claim.status === 'closed') {
    return <ClaimClosedState claim={claim} data-cy="claim-closed" />
  }

  const activeServiceOrder = getActiveServiceOrder()

  if (
    claim.serviceType === 'repair' &&
    claim.status === 'approved' &&
    (!activeServiceOrder || activeServiceOrder.status === 'created')
  ) {
    return <RepairAcceptedServicerOwnedTimeline claim={claim} serviceOrder={activeServiceOrder} />
  }

  // Display preparing state if service order or
  // merchant servicing settings are not yet loaded
  // Previous claim statuses above did not require SO or merchant servicing settings
  if (!activeServiceOrder || !merchantServicingSettings) {
    return <ClaimPreparingState />
  }

  const fulfilledServiceOrderStatuses = ['fulfilled', 'payment_requested', 'payment_approved', 'paid']

  const selectableReplacementFulfillmentMethods = ['automated_replacement', 'direct_payment', 'virtual_card']

  if (activeServiceOrder.configurations?.replacementFulfillmentMethod === 'automated_replacement') {
    return <AutomatedReplacementApprovedTimeline serviceOrder={activeServiceOrder} />
  }

  if (
    claim.status === 'fulfilled' ||
    fulfilledServiceOrderStatuses.includes(activeServiceOrder.status) ||
    selectableReplacementFulfillmentMethods.includes(
      activeServiceOrder.configurations?.replacementFulfillmentMethod ?? '',
    )
  ) {
    return <FulfilledTimeline claim={claim} serviceOrder={activeServiceOrder} />
  }

  if (
    activeServiceOrder.configurations?.hasCustomDefectiveLabel &&
    isDepotRepair(activeServiceOrder) &&
    activeServiceOrder.status === 'accepted'
  ) {
    return <RepairAcceptedServicerOwnedTimeline claim={claim} serviceOrder={activeServiceOrder} />
  }

  if (
    activeServiceOrder.configurations?.repairFulfillmentMethod &&
    activeServiceOrder.serviceType === 'repair' &&
    (activeServiceOrder.status === 'assigned' || activeServiceOrder.status === 'accepted')
  ) {
    return <RepairAcceptedServicerOwnedTimeline claim={claim} serviceOrder={activeServiceOrder} />
  }

  if (claim.status === 'approved' && isOnsiteRepair(activeServiceOrder) && activeServiceOrder.status === 'accepted') {
    return <RepairAcceptedServicerOwnedTimeline claim={claim} serviceOrder={activeServiceOrder} />
  }

  if (
    activeServiceOrder.status === 'accepted' &&
    activeServiceOrder.configurations?.productReturnRequired &&
    !activeServiceOrder.configurations?.hasCustomDefectiveLabel
  ) {
    return (
      <ClaimPendingExtendOwnedLabelTimeline
        data-cy="pending-claim-extend-owned-label"
        serviceOrder={activeServiceOrder}
        merchantServicingSettings={merchantServicingSettings}
      />
    )
  }

  if (
    claim.status === 'approved' &&
    activeServiceOrder.status === 'created' &&
    activeServiceOrder.serviceType === 'replace'
  ) {
    return (
      <CustomerPayoutTimeline
        claim={claim}
        merchantServicingSettings={merchantServicingSettings}
        serviceOrder={activeServiceOrder}
        contract={Object.values(emsData?.contracts || {})[0]}
      />
    )
  }
  if (
    claim.status === 'approved' &&
    activeServiceOrder.status === 'replacement_approved' &&
    activeServiceOrder.configurations?.replacementFulfillmentMethod === 'manual'
  ) {
    return <ManualReplacementApprovedTimeline />
  }

  if (
    claim.status === 'approved' &&
    isDepotRepair(activeServiceOrder) &&
    (activeServiceOrder.status === 'repair_started' || activeServiceOrder.status === 'repair_shipped')
  ) {
    return <RepairInProgressTimeline serviceOrder={activeServiceOrder} />
  }
  return <ClaimPreparingState />
}
