import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { bp } from '@extend/client-helpers'
import type { Card } from '@customers-api-client'
import { COLOR } from '@customers-ui'
import { generateCardTitle } from '../../../../../lib/chat-utils'
import { ClaimTrackingActivity } from './claim-tracking-activity'
import { TrackCardDetails } from './track-card-details'
import { colorScheme } from './color-scheme'

interface ChatTrackCardProps {
  cardData: Card
}

export const ChatTrackCard: FC<ChatTrackCardProps> = ({ cardData }) => {
  const { status, claimId, claimTrackingActivity } = cardData
  const { primary } = colorScheme[status]

  return (
    <Wrapper>
      <TrackCardHeader data-cy="chat-track-card-header" color={primary}>
        {generateCardTitle(status)}
      </TrackCardHeader>
      <TrackDetails color={primary} data-cy="chat-track-card-details">
        <TrackCardDetails cardData={cardData} />
        <ClaimIdWrapper>
          <BoldText>Claim ID:</BoldText>
          <ClaimId>{claimId}</ClaimId>
        </ClaimIdWrapper>
        <ClaimTrackingActivity claimTrackingActivity={claimTrackingActivity} status={status} />
      </TrackDetails>
    </Wrapper>
  )
}

const ClaimIdWrapper = styled.div({
  fontSize: 11,
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 16,
  [bp.sm]: {
    flexDirection: 'row',
  },
  [bp.md]: {
    fontSize: 13,
  },
})

const ClaimId = styled.div({
  alignSelf: 'flex-start',
  [bp.sm]: {
    alignSelf: 'center',
  },
})

const BoldText = styled.span({
  fontWeight: 700,
  fontSize: 13,
  marginRight: 8,
})

const Wrapper = styled.div({
  margin: '20px auto 0px auto',
  width: '100%',
  maxWidth: 380,
})

interface ColorProps {
  color: string
}

const TrackDetails = styled.div<ColorProps>(
  {
    width: '100%',
    border: '1px solid',
    borderBottomRightRadius: 6,
    borderBottomLeftRadius: 6,
    padding: 16,
  },
  (props) => ({
    borderColor: props.color,
  }),
)

const TrackCardHeader = styled.div<ColorProps>(
  {
    width: '100%',
    display: 'flex',
    padding: '10px 16px',
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
    color: COLOR.WHITE,
    fontWeight: 700,
    fontSize: 16,
  },
  (props) => ({
    background: props.color,
  }),
)
