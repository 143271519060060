import type { FC } from 'react'
import React, { useState } from 'react'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { bp } from '@extend/client-helpers'
import type { ClaimItem, Contract, ContractCoveredProduct, ContractProduct, Claim } from '@customers-api-client'
import { onImageError, currency } from '@extend/client-helpers'
import { COLOR, ContentLayout } from '@customers-ui'
import { SplashScreen } from '../../../components/common'
import type { Locale } from '../../../types/localization'
import { svgs } from '../../../lib/assets'
import * as selectors from '../../../reducers/selectors'
import type { RootState } from '../../../reducers'
import { getIsRepairClaim } from '../../../utils/get-is-repair'

interface ProductDetailsProps {
  accessToken?: string
  contract: Omit<Contract, 'product'> & { product?: ContractProduct }
  claim: Claim
  locale?: Locale
}

const pageText = {
  'en-US': {
    productTitle: 'Product: ',
    claimTitle: 'CLAIM ID: ',
    contractTitle: 'CONTRACT ID: ',
    storeTitle: 'STORE: ',
    purchaseTitle: 'DATE OF PURCHASE: ',
    coverage: {
      title: 'Remaining Coverage Amount: ',
      description: 'Your coverage amount is determined by the sale price of your product, minus the cost of repairs.',
    },
  },
  'en-CA': {
    productTitle: 'Product: ',
    claimTitle: 'CLAIM ID: ',
    contractTitle: 'CONTRACT ID: ',
    storeTitle: 'STORE: ',
    purchaseTitle: 'DATE OF PURCHASE: ',
    coverage: {
      title: 'Remaining Coverage Amount: ',
      description: 'Your coverage amount is determined by the sale price of your product, minus the cost of repairs.',
    },
  },
  'fr-CA': {
    productTitle: 'PRODUIT: ',
    claimTitle: '',
    contractTitle: 'IDENTIFIANT DE CONTRAT: ',
    storeTitle: 'COMMERÇANT: ',
    purchaseTitle: `DATE D'ACHAT: `,
    coverage: {
      title: '',
      description: '',
    },
  },
}

const ProductDetails: FC<ProductDetailsProps> = ({ contract, claim, locale = 'en-US', accessToken: passedInToken }) => {
  const [showImage, setShowImage] = useState(true)

  const accessToken = passedInToken ?? useSelector((state: RootState) => selectors.getAccessToken(state))

  if (!claim) {
    return (
      <ContentLayout>
        <SplashScreen data-cy="splash-screen" />
      </ContentLayout>
    )
  }
  const isRepair = getIsRepairClaim(claim)

  /**
   * First claim item will either be individual component getting serviced
   * or the product on the contract if multiple items or an unsure option
   * was selected in Kaley
   *
   * If feature flag is off or we can't get the claim item for some reason
   * we fall back to the product on the contract.
   */

  let product:
    | ClaimItem
    | ContractProduct
    | ContractCoveredProduct
    | { imageUrl: string; title: string; purchasePrice: number }

  if (claim?.items?.length) {
    ;[product] = claim.items
  } else if (contract.product) {
    product = contract.product
  } else if (contract.productsList?.length) {
    ;[product] = contract.productsList
  } else {
    product = { imageUrl: '', title: '', purchasePrice: 0 }
  }

  const { imageUrl = '', title } = product

  const handleImageError = (): void => {
    onImageError(setShowImage)
  }

  const { productTitle, claimTitle, contractTitle, storeTitle, purchaseTitle, coverage } = pageText[locale]

  return (
    <ProductDetailsSection>
      {showImage && imageUrl && <ProductImage data-cy="product-image" src={imageUrl} onError={handleImageError} />}
      <DetailsList>
        <DetailItem topRow order={1} mobileOrder={1}>
          <DetailTitle>{productTitle}</DetailTitle>
          <Detail data-cy="product-title">{title}</Detail>
        </DetailItem>
        {isRepair && (
          <DetailItem topRow order={2} mobileOrder={4}>
            <DetailTitle>{claimTitle}</DetailTitle>
            <Detail>{claim?.id}</Detail>
          </DetailItem>
        )}
        <DetailItem order={3} mobileOrder={2}>
          <DetailTitle>{storeTitle}</DetailTitle>
          <Detail>{contract.sellerName}</Detail>
        </DetailItem>
        <DetailItem topRow={!isRepair} order={isRepair ? 4 : 2} mobileOrder={5}>
          <DetailTitle>{contractTitle}</DetailTitle>
          <Detail>
            <ContractLink href={`/warranty_terms?contractId=${contract.id}&accessToken=${accessToken}`} target="_blank">
              {contract.id}
              <svgs.External />
            </ContractLink>
          </Detail>
        </DetailItem>
        <DetailItem order={5} mobileOrder={isRepair ? 3 : 6}>
          <DetailTitle>{purchaseTitle}</DetailTitle>
          <Detail>{dayjs(contract.transactionDate).format('MMM DD, YYYY')}</Detail>
        </DetailItem>
        {isRepair && (
          <DetailItem order={6} mobileOrder={6}>
            <DetailTitle>
              {coverage.title}
              <svgs.InfoCircleIcon />
              <CoverageTooltip>{coverage.description}</CoverageTooltip>
            </DetailTitle>
            <Detail>{currency.format(product.purchasePrice ?? 0)}</Detail>
          </DetailItem>
        )}
      </DetailsList>
    </ProductDetailsSection>
  )
}

const CoverageTooltip = styled.div({
  textTransform: 'initial',
  fontWeight: 300,
  fontSize: 14,
  padding: 12,
  border: `1px solid ${COLOR.BLUE[400]}`,
  borderRadius: 6,
  maxWidth: 260,
  position: 'absolute',
  top: 0,
  left: 180,
  display: 'none',
  background: COLOR.WHITE,
  [bp.md]: {
    left: 200,
  },
})

const ContractLink = styled.a({
  color: COLOR.BLUE[700],
  '&:hover': {
    textDecoration: 'underline',
  },
  '& svg': {
    width: 13,
    transform: 'translate(10px, 3px)',
  },
})

const ProductImage = styled.img({
  height: 'auto',
  width: 68,
  [bp.md]: {
    marginRight: 56,
    height: 107,
    width: 'auto',
    alignSelf: 'flex-start',
  },
})

const ProductDetailsSection = styled.section({
  marginTop: 24,
  marginBottom: 30,
  [bp.md]: {
    display: 'flex',
    margin: '48px 50px',
  },
})

const DetailsList = styled.ul({
  listStyleType: 'none',
  margin: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  [bp.md]: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
  },
})

const DetailItem = styled.li<{ topRow?: boolean; order: number; mobileOrder: number }>(
  {
    [bp.md]: {
      width: '48%',
    },
  },
  ({ topRow, order, mobileOrder }) => ({
    order: mobileOrder,
    [bp.md]: {
      marginTop: topRow ? 0 : 32,
      order,
    },
  }),
)

const DetailTitle = styled.h3({
  position: 'relative',
  fontSize: 14,
  fontWeight: 'bold',
  height: 19,
  lineHeight: '19px',
  margin: 0,
  marginTop: 20,
  textTransform: 'uppercase',
  '& svg': {
    transform: 'translate(3px, 2px)',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  '& svg:hover + div': {
    display: 'block',
  },
  [bp.md]: {
    marginTop: 0,
    fontSize: 16,
  },
})

const Detail = styled.p({
  fontSize: 14,
  lineHeight: '19px',
  padding: 0,
  margin: 0,
  paddingTop: 6,
  [bp.md]: {
    fontSize: 16,
  },
})

export type { ProductDetailsProps }
export { ProductDetails }
