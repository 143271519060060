import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR, bp, Grid } from '@customers-ui'
import { formatPhoneNumberLocal } from '@extend/client-helpers'
import { formatAddress } from '../../utils/format-address'
import type { FormSubmitData } from './customer-form-template.types'

export interface CustomerFormViewProps {
  className?: string
  formData: FormSubmitData
}

const CustomerFormView: FC<CustomerFormViewProps> = ({ className, formData }) => {
  const { customer } = formData
  const { shippingAddress } = customer

  return (
    <DetailsGrid className={className}>
      <DetailRow>
        <DetailTitle>Name</DetailTitle>
        <DetailContent data-testid="user-name">{customer.name}</DetailContent>
      </DetailRow>
      <DetailRow>
        <DetailTitle>Email</DetailTitle>
        <DetailContent data-testid="user-email">{customer.email}</DetailContent>
      </DetailRow>
      <DetailRow>
        <DetailTitle>Shipping Address</DetailTitle>
        <DetailContent data-testid="user-address">
          {shippingAddress?.address1 ? <AddressWrapper>{formatAddress(shippingAddress)}</AddressWrapper> : 'None'}
        </DetailContent>
      </DetailRow>
      <DetailRow>
        <DetailTitle>Phone Number</DetailTitle>
        <DetailContent data-testid="user-number">
          {customer.phone ? formatPhoneNumberLocal(customer.phone) : 'None'}
        </DetailContent>
      </DetailRow>
    </DetailsGrid>
  )
}

const DetailsGrid = styled(Grid)({
  flexDirection: 'column',
})

const DetailRow = styled(Grid)({
  [bp.mobile]: {
    flexDirection: 'column',
  },
  [bp.desktop]: {
    flexDirection: 'row',
  },
})

const DetailTitle = styled.div({
  color: COLOR.NEUTRAL[1000],
  fontWeight: 700,
  [bp.mobile]: {
    marginBottom: 8,
    fontSize: 13,
    lineHeight: '16px',
  },
  [bp.desktop]: {
    width: 257,
    marginBottom: 24,
    fontSize: 14,
    lineHeight: '24px',
  },
})

const DetailContent = styled.div({
  color: COLOR.NEUTRAL[800],
  marginBottom: 24,
  [bp.mobile]: {
    fontSize: 15,
    lineHeight: '20px',
  },
  [bp.desktop]: {
    fontSize: 16,
    lineHeight: '24px',
  },
})

const AddressWrapper = styled.div({
  whiteSpace: 'pre-wrap',
})

export { CustomerFormView }
