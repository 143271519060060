const convertToRGB = (hexColor: string): string => {
  if (hexColor.length !== 7) {
    return 'Only six-digit hex colors are accepted. Example: "#000000"'
  }

  const splitHex = hexColor.substring(1).match(/.{1,2}/g)

  if (!splitHex) {
    return 'Only six-digit hex colors are accepted. Example: "#000000"'
  }

  return `rgb(${parseInt(splitHex[0], 16)}, ${parseInt(splitHex[1], 16)}, ${parseInt(splitHex[2], 16)})`
}

export { convertToRGB }
