import type { FC } from 'react'
import React, { useState, useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import { bp } from '@customers-ui'
import type { ConnectRequest } from '@extend-incredibot/types'
import { useTheme } from '@emotion/react'
import { useHistory } from 'react-router'
import { useIntl } from 'react-intl'
import { ModalController } from '@extend/zen'
import type { EntitlementSearchByOrdersResponse } from '@customers-api-rtk-query'
import type { ContractsSearchIndividual } from '../../types/contract'
import { logEvent } from '../../analytics'
import { FileClaimLanguageOptionModal } from '../../components/file-claim-language-option-modal/file-claim-language-option-modal'
import CardMapper from './card-mapper'
import ContactMerchantModal from '../../components/contact-merchant-modal'

export type ContractListProps = {
  groupedContracts: Record<string, Array<ContractsSearchIndividual>>
  groupedEmsData: Partial<Record<ContractsSearchIndividual['type'], Array<EntitlementSearchByOrdersResponse>>>
}

const ContractList: FC<ContractListProps> = ({ groupedContracts, groupedEmsData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCustomerSupportModalOpen, setIsCustomerSupportModalOpen] = useState(false)
  const [isFilingClaim, setIsFilingClaim] = useState(false)
  const [connectData, setConnectData] = useState<Partial<ConnectRequest>>()
  const theme = useTheme()
  const { isExtend } = theme.merchantConfiguration

  const history = useHistory()
  const { locale } = useIntl()

  const handleModalOpen = (): void => {
    setIsModalOpen(true)
  }

  const handleModalClose = (): void => {
    setIsModalOpen(false)
  }

  const handleFileClaim = useCallback((): void => {
    if (!connectData?.contractId && !connectData?.orderId) return

    logEvent('My Plans - Contract Card - Clicks', 'File a Claim')
    history.push(!isExtend ? `/${locale}/my_claims/file-claim` : `/${locale}/kaley`, {
      orderId: connectData?.orderId,
      contractId: connectData?.contractId,
      lineItemIds: connectData?.lineItemIds,
      shipmentId: connectData?.shipmentId,
    })
  }, [connectData, history, locale])

  const handleFileClaimClick = (
    handlerArgs: Partial<ConnectRequest> & {
      isMerchantOwnedIntake?: boolean
    },
  ): void => {
    setConnectData({
      orderId: handlerArgs.orderId,
      contractId: handlerArgs.contractId,
      lineItemIds: handlerArgs.lineItemIds,
      shipmentId: handlerArgs.shipmentId,
    })
    if (
      handlerArgs.isMerchantOwnedIntake &&
      (handlerArgs.contractId === connectData?.contractId || handlerArgs.orderId === connectData?.orderId)
    ) {
      setIsCustomerSupportModalOpen(true)
    } else if (['fr-CA', 'en-CA'].includes(locale)) {
      handleModalOpen()
    } else {
      setIsFilingClaim(true)
    }
  }

  useEffect(() => {
    if (isFilingClaim && (connectData?.contractId || connectData?.orderId)) {
      handleFileClaim()
      setIsFilingClaim(false)
    }
  }, [isFilingClaim, connectData, handleFileClaim])

  const selectedContract = Object.values(groupedContracts)
    .flat()
    .find((contract) => contract.id === connectData?.contractId)
  const selectedOrder = Object.values(groupedEmsData)
    .flat()
    .find(
      (emsData) =>
        emsData.id === connectData?.orderId ||
        (emsData.contracts && emsData.contracts[connectData?.contractId as string]),
    )

  return (
    <ContractsWrapper data-cy="contract-list">
      {groupedEmsData &&
        Object.entries(groupedEmsData).map(([type, emsData]) => {
          return (
            <ContractGroupWrapper key={type}>
              {emsData.map((emsData: EntitlementSearchByOrdersResponse) => (
                <CardMapper key={emsData.id} emsData={emsData} handleFileClaimClick={handleFileClaimClick} />
              ))}
            </ContractGroupWrapper>
          )
        })}
      {Object.entries(groupedContracts).map(([type, contracts]) => (
        <ContractGroupWrapper key={type}>
          {contracts.map((contract: ContractsSearchIndividual) => (
            <CardMapper key={contract.id} contract={contract} handleFileClaimClick={handleFileClaimClick} />
          ))}
        </ContractGroupWrapper>
      ))}
      <FileClaimLanguageOptionModal
        isModalOpen={isModalOpen}
        onDismiss={handleModalClose}
        onContinue={handleFileClaim}
      />
      <ModalController isOpen={isCustomerSupportModalOpen}>
        <ContactMerchantModal
          onDismissRequest={() => setIsCustomerSupportModalOpen(false)}
          storeId={(selectedContract?.sellerId as string) || (selectedOrder?.store.id as string)}
        />
      </ModalController>
    </ContractsWrapper>
  )
}

const ContractsWrapper = styled.div({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: '24px',
  [bp.mobile]: {
    justifyContent: 'center',
  },
  [bp.desktop]: {
    justifyContent: 'flex-start',
  },
})

const ContractGroupWrapper = styled.div({
  display: 'flex',
  [bp.mobile]: {
    flexDirection: 'column',
    gap: '16px',
  },
  [bp.desktop]: {
    gap: '24px',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
})

export { ContractList }
