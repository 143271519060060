import type { FC } from 'react'
import React from 'react'
import { Slide } from 'pure-react-carousel'
import styled from '@emotion/styled'
import type { ClaimStatus, ContractCarouselPromptOption, ContractAndLineItemId } from '@customers-api-client'
import { COLOR } from '@customers-ui'
import { currency } from '@extend/client-helpers'
import { colorScheme } from '../chat-track-card/color-scheme'
import { formatDate } from './utils'
import { ChatCardImage } from './chat-card-image'

interface ChatContractCardProps {
  onClick: (message: string, value: string | ContractAndLineItemId) => void
  product: ContractCarouselPromptOption
  index: number
}

const ChatContractCard: FC<ChatContractCardProps> = ({ product, index, onClick }) => {
  const {
    title,
    imageUrl,
    outputText,
    value,
    attributes: { storeName, transactionDate, purchasePrice },
    status,
  } = product

  const formattedDate = formatDate(transactionDate)

  const handleClick = (): void => {
    onClick(outputText || '', value)
  }

  return (
    <Slide index={index}>
      <SlideContent onClick={handleClick} status={status} data-cy="carousel-product">
        <ContractCardHeader data-cy="contract-card-header">Product Protection</ContractCardHeader>

        <ProductBodyWrapper>
          <ChatCardImage imageUrl={imageUrl} title={title} />
          <ProductDetailsWrapper>
            <ProductTitle>{title}</ProductTitle>
            <ProductDetail>{storeName}</ProductDetail>
            <ProductDetail>{`Purchased ${formattedDate}`}</ProductDetail>
          </ProductDetailsWrapper>
        </ProductBodyWrapper>
        <ContractDetailWrapper>
          <ContractDetail>
            <ContractDetailTitle>CONTRACT ID</ContractDetailTitle>
            <ContractDetailValue>{typeof value === 'object' ? value.contractId : value}</ContractDetailValue>
          </ContractDetail>
          <ContractDetail>
            <ContractDetailTitle>PURCHASE PRICE</ContractDetailTitle>
            <ContractDetailValue>{currency.format(purchasePrice)}</ContractDetailValue>
          </ContractDetail>
        </ContractDetailWrapper>
      </SlideContent>
    </Slide>
  )
}

const SlideContent = styled.div<{ status?: ClaimStatus }>(({ status }) => ({
  cursor: 'pointer',
  borderRadius: 6,
  border: `1px solid ${COLOR.BLUE[700]}`,
  margin: '0px 8px',
  borderColor: status && colorScheme[status].primary,
  paddingTop: status && 54,
  height: '268px',
}))

const ContractCardHeader = styled.div({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: COLOR.NEUTRAL[100],
  padding: '8px 16px 8px 16px',
  borderRadius: '8px 8px 0px 0px',
  color: COLOR.NEUTRAL[800],
  fontSize: 17,
  fontWeight: 700,
  lineHeight: '24px',
})

const ProductBodyWrapper = styled.div({
  display: 'flex',
  height: '120px',
  padding: '16px',
  gap: '16px',
})

const ProductDetailsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  width: '197px',
})

const ProductTitle = styled.div({
  fontSize: 15,
  fontWeight: 400,
  lineHeight: '20px',
  width: '197px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
})

const ProductDetail = styled.div({
  fontSize: 13,
  fontWeight: 400,
  lineHeight: '20px',
  color: COLOR.NEUTRAL[700],
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

const ContractDetailWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  borderTop: `1px solid ${COLOR.NEUTRAL[200]}`,
  margin: '0 21px 16px',
  paddingTop: '16px',
  gap: '16px',
})

const ContractDetail = styled.div({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: 'auto',
})

const ContractDetailTitle = styled.div({
  color: COLOR.NEUTRAL[600],
  fontSize: 8,
  fontWeight: 700,
  lineHeight: '11px',
})

const ContractDetailValue = styled.div({
  fontSize: 10,
  fontWeight: 400,
  lineHeight: '14px',
})

export type { ChatContractCardProps }
export { ChatContractCard }
