import type { FC } from 'react'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { COLOR, Step, Stepper } from '@customers-ui'
import { bp } from '@extend/client-helpers'
import type { Claim, ContractsV2GetResponse, ServiceOrder } from '@customers-api-client'

import { calcActiveStep } from '../utils/stepper-helper'
import { logEvent } from '../analytics'
import { ClaimApproveDetails } from './claim-approve-detail'
import { ClaimApproveWheelTireDetails } from './claim-approve-wheel-tire-detail'
import { DefectiveProductDetails } from './claim-defective-detail'
import { ClaimCompleteDetails } from './claim-complete-detail'
import { useTheme } from '@emotion/react'

export interface ClaimStepperProps {
  contract?: ContractsV2GetResponse
  claim: Claim
  serviceOrder?: ServiceOrder[]
  isRepair: boolean
  currentStep?: number
  setActiveStep?(active: number): void
  hasDetails?: boolean
  className?: string
}

export const ClaimStepper: FC<ClaimStepperProps> = ({
  contract,
  claim,
  serviceOrder,
  isRepair,
  currentStep,
  setActiveStep,
  hasDetails = false,
  className,
}) => {
  const history = useHistory()
  const theme = useTheme()

  const handleClick = (): void => {
    logEvent('Claims Details - Clicks', 'Contact Support')
    if (theme.merchantConfiguration.merchantSupportUrl) {
      window.open(theme.merchantConfiguration.merchantSupportUrl, '_blank')
    } else {
      history.push('/contact')
    }
  }

  if (claim.status === 'denied') {
    return (
      <Stepper activeStep={0} bubbleColor={COLOR.RED[800]} {...{ className, currentStep }}>
        <Step title="CLAIM DENIED">
          {hasDetails && (
            <DetailsWrapper>
              Based on your responses, your claim has been denied. If you have any questions please feel free to{' '}
              <StyledLink to="/contact" onClick={handleClick}>
                contact support
              </StyledLink>{' '}
              or call <NoWrap>(877) 248-7707.</NoWrap>
            </DetailsWrapper>
          )}
        </Step>
      </Stepper>
    )
  }

  if (claim.status === 'review') {
    return (
      <Stepper activeStep={0} bubbleColor={COLOR.YELLOW[600]} {...{ className, currentStep }}>
        <Step title="CLAIM IN REVIEW">
          {hasDetails && (
            <DetailsWrapper>
              Sit tight, your claim is currently in review. One of our customer care specialists may reach out to you
              shortly to follow up. <br />
              <br />
              We’ll let you know as soon as a decision is made. In the meantime, if you have any questions please feel
              free to
              <StyledLink to="/contact" onClick={handleClick}>
                {' '}
                contact support
              </StyledLink>{' '}
              or call <NoWrap>(877) 248-7707.</NoWrap>
            </DetailsWrapper>
          )}
        </Step>
      </Stepper>
    )
  }

  return (
    <ClaimProgressStepper
      {...{
        contract,
        serviceOrder,
        isRepair,
        currentStep,
        setActiveStep,
        hasDetails,
        className,
        claim,
      }}
    />
  )
}

const ClaimProgressStepper: FC<ClaimStepperProps> = ({
  contract,
  serviceOrder,
  isRepair,
  currentStep,
  setActiveStep,
  hasDetails,
  className,
  claim,
}) => {
  if (contract?.planDetails?.category === 'wheel_tire') {
    return (
      <Stepper activeStep={0} className={className}>
        <Step title="CLAIM APPROVED">{hasDetails && <ClaimApproveWheelTireDetails />}</Step>
      </Stepper>
    )
  }

  if (claim?.status === 'closed' && claim?.closedMetaData?.resolution === 'no_service') {
    return (
      <Stepper
        activeStep={calcActiveStep(serviceOrder, isRepair)}
        {...{ className, setActiveStep, currentStep }}
        bubbleColor={COLOR.BLUE[800]}
      >
        <Step title="CLAIM APPROVED">{hasDetails && <ClaimApproveDetails {...{ serviceOrder, isRepair }} />}</Step>
        <Step title="CLOSED - NO SERVICE REQUIRED">
          {hasDetails && <ClaimCompleteDetails {...{ serviceOrder, contract, isRepair }} />}
        </Step>
      </Stepper>
    )
  }

  return (
    <Stepper
      activeStep={calcActiveStep(serviceOrder, isRepair)}
      {...{ className, setActiveStep, currentStep }}
      bubbleColor={COLOR.BLUE[800]}
    >
      <Step title="CLAIM APPROVED">{hasDetails && <ClaimApproveDetails {...{ serviceOrder, isRepair }} />}</Step>
      {!isRepair && <Step title="DEFECTIVE PRODUCT SHIPPED">{hasDetails && <DefectiveProductDetails />}</Step>}
      {isRepair && (
        <Step title="REPAIR IN PROGRESS">{hasDetails && <ClaimApproveDetails {...{ serviceOrder, isRepair }} />}</Step>
      )}
      <Step title={isRepair ? 'REPAIR COMPLETE' : 'REPLACEMENT ISSUED'}>
        {hasDetails && <ClaimCompleteDetails {...{ serviceOrder, contract, isRepair }} />}
      </Step>
    </Stepper>
  )
}

const DetailsWrapper = styled.div({
  height: '100%',
  width: 400,
  marginTop: 16,
  fontSize: 16,
  lineHeight: '24px',
  [bp.maxWidthMd]: {
    width: 300,
  },
})

const StyledLink = styled(Link)({
  color: COLOR.BLUE[800],
  '&:visited': {
    color: COLOR.BLUE[800],
  },
  cursor: 'pointer',
})

const NoWrap = styled.div({
  whitespace: 'nowrap',
})
