import type { AuthReducerState } from '.'
import type { AuthErrorReducerState } from './error'
import type { AuthAccessTokenReducerState } from './access-token'
import type { AuthIsLoadingReducerState } from './is-loading'
import { isValidToken } from '../../lib/security-utils/jwt'
import type { AuthDecodedAccessTokenReducerState } from './decoded-access-token'

export const getAccessToken = (state: AuthReducerState): AuthAccessTokenReducerState => {
  return state.accessToken
}
export const getDecodedAccessToken = (state: AuthReducerState): AuthDecodedAccessTokenReducerState => {
  return state.decodedAccessToken
}
export const getError = (state: AuthReducerState): AuthErrorReducerState => {
  return state.error
}
export const getIsLoading = (state: AuthReducerState): AuthIsLoadingReducerState => {
  return state.isLoading
}

export const getIsLoggedIn = (state: AuthReducerState): boolean => {
  const accessToken = getAccessToken(state)

  return isValidToken(accessToken)
}
