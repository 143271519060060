import type { FC } from 'react'
import React from 'react'

const ImageMediaFile: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 14" width="16" height="14">
      <g fill="#03C">
        <path d="M15.5 14H.5c-.3 0-.5-.2-.5-.5V.5C0 .2.2 0 .5 0h15c.3 0 .5.2.5.5v13c0 .3-.2.5-.5.5zM1 13h14V1H1v12z" />
        <path d="M10.5 11c-.1 0-.3 0-.4-.2L5.5 4.5.9 10.8c-.2.2-.5.3-.7 0a.6.6 0 010-.7l5-6.9c.1-.3.5-.3.7 0l5 6.9c.2.2.1.6 0 .8l-.4.1z" />
        <path d="M15.5 12s-.2 0-.3-.2l-4.3-5.5L9 8.5c-.1.2-.3-.8-.4-1-.2-.2.3-.5.4-.7l1.4-1.6.4-.2c.1 0 .2 0 .3.2L16 11c.2.2.1.6 0 .8l-.4.1z" />
      </g>
    </svg>
  )
}

export { ImageMediaFile }
