import type { CSSProperties } from 'react'

import { bp } from '../../utils/breakpoints'

export type TextType =
  | 'caption-legal'
  | 'body'
  | 'subheading'
  | 'heading-small'
  | 'heading-large'
  | 'display-small'
  | 'display-medium'
  | 'display-large'
  | 'display-xlarge'

export const textStyles = (type: TextType): CSSProperties => {
  switch (type) {
    case 'caption-legal':
      return {
        fontWeight: 300,
        [bp.mobile]: {
          fontSize: 13,
          lineHeight: '20px',
        },
        [bp.desktop]: {
          fontSize: 14,
          lineHeight: '22px',
        },
      }
    case 'subheading':
      return {
        fontWeight: 700,
        [bp.mobile]: {
          fontSize: 13,
          lineHeight: '16px',
        },
        [bp.desktop]: {
          fontSize: 14,
          lineHeight: '18px',
        },
      }
    case 'heading-small':
      return {
        fontWeight: 700,
        [bp.mobile]: {
          fontSize: 17,
          lineHeight: '24px',
        },
        [bp.desktop]: {
          fontSize: 20,
          lineHeight: '28px',
        },
      }
    case 'heading-large':
      return {
        fontWeight: 700,
        [bp.mobile]: {
          fontSize: 20,
          lineHeight: '24px',
        },
        [bp.desktop]: {
          fontSize: 24,
          lineHeight: '32px',
        },
      }
    case 'display-small':
      return {
        fontWeight: 400,
        [bp.mobile]: {
          fontSize: 16,
          lineHeight: '24px',
        },
        [bp.desktop]: {
          fontSize: 20,
          lineHeight: '28px',
        },
      }
    case 'display-medium':
      return {
        fontWeight: 400,
        [bp.mobile]: {
          fontSize: 20,
          lineHeight: '28px',
        },
        [bp.desktop]: {
          fontSize: 28,
          lineHeight: '36px',
        },
      }
    case 'display-large':
      return {
        fontWeight: 600,
        [bp.mobile]: {
          fontSize: 24,
          lineHeight: '28px',
        },
        [bp.desktop]: {
          fontSize: 36,
          lineHeight: '44px',
        },
      }
    case 'display-xlarge':
      return {
        fontWeight: 600,
        [bp.mobile]: {
          fontSize: 28,
          lineHeight: '36px',
        },
        [bp.desktop]: {
          fontSize: 44,
          lineHeight: '52px',
        },
      }
    case 'body':
      return {
        fontWeight: 400,
        [bp.mobile]: {
          fontSize: 15,
          lineHeight: '20px',
        },
        [bp.desktop]: {
          fontSize: 16,
          lineHeight: '24px',
        },
      }
    default:
      return {}
  }
}
