// Reseting API error state
export const customersApiErrorReset = () =>
  ({
    type: 'CUSTOMERS_API_ERROR_RESET',
  } as const)

export const resourceFetchErrorReset = () =>
  ({
    type: 'RESOURCE_FETCH_ERROR_RESET',
  } as const)
