import type { Claim, ContractsV2GetResponse, ServiceOrder } from '@customers-api-client'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import type { SerializedError } from '@reduxjs/toolkit'
import {
  useGetContractQuery,
  useGetInsuranceClaimQuery,
  useGetServiceOrdersByClaimQuery,
} from '@customers-api-rtk-query'

const useGetClaimDetailsData = (
  claimId: string,
): {
  contract?: ContractsV2GetResponse
  claim?: Claim
  isLoading: boolean
  isError: boolean
  error: FetchBaseQueryError | SerializedError | undefined
  serviceOrders?: ServiceOrder[]
} => {
  const {
    data: serviceOrders,
    isLoading: isServiceOrdersLoading,
    isError: isServiceOrdersQueryError,
    error: serviceOrdersQueryError,
  } = useGetServiceOrdersByClaimQuery({ claimId })
  const {
    data: insuranceClaimData,
    isLoading: isInsuranceClaimLoading,
    isError: isInsuranceClaimQueryError,
    error: insuranceClaimQueryError,
  } = useGetInsuranceClaimQuery({ claimId })

  const claim = insuranceClaimData ?? undefined

  const {
    data: contract,
    isLoading: isContractLoading,
    isError: isContractQueryError,
    error: contractQueryError,
  } = useGetContractQuery(
    {
      contractId: claim?.contractId || '',
    },
    { skip: !claim?.contractId },
  ) as {
    data?: ContractsV2GetResponse
    isLoading: boolean
    isError: boolean
    error: FetchBaseQueryError | SerializedError | undefined
  }

  const isLoading = isInsuranceClaimLoading || isContractLoading || isServiceOrdersLoading

  const isError = isServiceOrdersQueryError || isInsuranceClaimQueryError || isContractQueryError
  const error = serviceOrdersQueryError || insuranceClaimQueryError || contractQueryError

  return {
    contract,
    claim,
    isLoading,
    isError,
    error,
    serviceOrders,
  }
}

export { useGetClaimDetailsData }
