import images from './images'
import svgs from './svgs'
import { FAQ_TEXT, ShippingProtectionFaqAnswer } from './faqs'
import { TESTIMONIALS, SHIPPING_PROTECTION_TESTIMONIALS } from './testimonials'
import { ShippingProtectionCharityContent } from './shipping-protection-charity-content'

export {
  images,
  svgs,
  FAQ_TEXT,
  TESTIMONIALS,
  SHIPPING_PROTECTION_TESTIMONIALS,
  ShippingProtectionFaqAnswer,
  ShippingProtectionCharityContent,
}
