import { createApi } from '@reduxjs/toolkit/query/react'
import type { PrecheckAllRequest, PrecheckAllResponse, PrecheckRequest, PrecheckResponse } from './types'
import { baseQuery } from '../base-query'
import type { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import type { EmsContract } from '../my-extend'

export const adjudicationManagementApi = createApi({
  baseQuery,
  reducerPath: 'adjudication-management',
  tagTypes: ['adjudication-management'],
  endpoints: (build) => ({
    precheck: build.query<PrecheckResponse, PrecheckRequest>({
      query: (body) => {
        return {
          url: `adjudication-management/pre-check`,
          method: 'POST',
          body,
        }
      },
      providesTags: ['adjudication-management'],
    }),
    precheckAll: build.query<PrecheckAllResponse, PrecheckAllRequest>({
      async queryFn(requests, _queryApi, _extraOptions, fetchWithBQ) {
        const promises = requests.map(
          async ({ contract, ...requestBody }: PrecheckRequest & { contract?: EmsContract }) => {
            const res: QueryReturnValue = await fetchWithBQ({
              url: `adjudication-management/pre-check`,
              method: 'POST',
              body: requestBody,
            })

            if (res.error) {
              console.error(res.error)
            }
            const payload = res.data as PrecheckResponse
            return { ...requestBody, contract, payload }
          },
        )

        const results = await Promise.all(promises)

        const data: PrecheckAllResponse = {}
        results.forEach(({ contract, payload, ...requestBody }) => {
          const id = requestBody?.orderId ?? requestBody?.contractId
          data[id ?? ''] = { contract, precheckResponse: payload }
        })

        return { data }
      },
    }),
  }),
})

export const { usePrecheckQuery, usePrecheckAllQuery } = adjudicationManagementApi
