import type { FC } from 'react'
import React, { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import styled from '@emotion/styled'
import { COLOR, Text, FormattedMessage } from '@customers-ui'
import { bp } from '@extend/client-helpers'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { images, svgs, TESTIMONIALS } from '../../../lib/assets'
import { BackButton, NextButton } from '../../../components/common'
import { useScreenSize } from '../../../hooks'
import { Testimonial } from './testimonial'

export const visibleSlidesCount = (screenSize: string): number => {
  if (screenSize === 'small') return 1
  if (screenSize === 'medium') return 2
  if (screenSize === 'large') return 2
  return 3
}

const Testimonials: FC = () => {
  const [maxHeight, setMaxHeight] = useState(0)

  const screenSize = useScreenSize()
  const isSmallScreen = screenSize === 'small'
  const CARD_WIDTH = 301
  const TOTAL_BUBBLE_HEIGHT = maxHeight + 68

  const BUBBLE_MARGIN_BOTTOM = isSmallScreen || screenSize === 'medium' ? 69 : 123

  return (
    <TestimonialsWrapper>
      <SectionTitle>
        <Text>
          <FormattedMessage
            id="PLAN_DETAILS_TESTIMONIAL_TITLE"
            defaultMessage="We {heart} making customers happy"
            description="We love making customers happy title.{heart}: image of a heart emoji."
            values={{
              heart: <Heart aria-label="love" src={images.heartEmoji} alt="love" />,
            }}
          />
        </Text>
      </SectionTitle>
      <CarouselProviderWrapper
        visibleSlides={visibleSlidesCount(screenSize)}
        totalSlides={TESTIMONIALS.length}
        step={visibleSlidesCount(screenSize)}
        naturalSlideWidth={CARD_WIDTH}
        naturalSlideHeight={TOTAL_BUBBLE_HEIGHT + BUBBLE_MARGIN_BOTTOM}
        touchEnabled={isSmallScreen}
        dragEnabled={isSmallScreen}
        height={TOTAL_BUBBLE_HEIGHT + BUBBLE_MARGIN_BOTTOM}
        bubbleheight={TOTAL_BUBBLE_HEIGHT}
      >
        {!isSmallScreen && (
          <BackButton className="back-btn">
            <svgs.BackArrow />
          </BackButton>
        )}
        <SliderContainer height={TOTAL_BUBBLE_HEIGHT} isSmallScreen={isSmallScreen}>
          <SliderWrapper aria-label="slider">
            {TESTIMONIALS.map((testimonial, i) => {
              return (
                <Slide index={i} key={uuid()}>
                  <Testimonial {...testimonial} setMaxHeight={setMaxHeight} maxHeight={maxHeight} />
                </Slide>
              )
            })}
          </SliderWrapper>
        </SliderContainer>

        {!isSmallScreen && (
          <NextButton className="next-btn">
            <svgs.RightChevron />
          </NextButton>
        )}
      </CarouselProviderWrapper>
    </TestimonialsWrapper>
  )
}

export const Heart = styled.img({
  display: 'inline',
  height: 26,
  transform: 'translateY(4px)',
  [bp.lg]: {
    height: 40,
    transform: 'translateY(8px)',
  },
})

const SectionTitle = styled.h2({
  display: 'flex',
  justifyContent: 'center',
  fontSize: 28,
  fontWeight: 800,
  textAlign: 'center',
  paddingTop: 66,
  paddingBottom: 29,
  margin: '0 32px',
  [bp.md]: {
    paddingBottom: 34,
  },
  [bp.lg]: {
    paddingTop: 123,
    fontSize: 39,
    paddingBottom: 56,
  },
})

const CarouselProviderWrapper = styled(CarouselProvider)<{ height: number; bubbleheight: number }>(
  {
    display: 'flex',
    width: '100%',
    height: 'auto',
    alignItems: 'flex-start',
    maxWidth: 1264,
    margin: '0 auto',
    '& svg': {
      width: 13,
    },
    '& button': {
      height: 'auto',
      width: 34,
      alignSelf: 'center',
    },
    '& .next-btn': {
      paddingRight: 32,
    },
    '& .back-btn': {
      paddingLeft: 32,
    },
  },
  ({ height, bubbleheight }) => ({
    height,
    '& button': {
      transform: `translateY(-${bubbleheight / 3}px)`,
      [bp.lg]: {
        transform: `translateY(-${bubbleheight / 2}px)`,
      },
      [bp.xl]: {
        transform: `translateY(-${bubbleheight / 3}px)`,
      },
    },
  }),
)

const TestimonialsWrapper = styled.div({
  background: COLOR.NEUTRAL[100],
  width: '100vw',
  marginLeft: -32,
  [bp.lg]: {
    marginLeft: -46,
  },
  [bp.xl]: {
    marginLeft: -112,
  },
  [bp.maxWidth]: {
    marginLeft: 0,
  },
})

interface SliderContainerProps {
  isSmallScreen: boolean
  height: number
}

const SliderContainer = styled.div<SliderContainerProps>(
  {
    flex: 1,
    margin: '0px auto',
    width: '90%',
    [bp.md]: {
      width: '100%',
    },
  },
  (props) => ({
    height: props.height,
  }),
)

const SliderWrapper = styled(Slider)({
  overflowX: 'hidden',
  height: '100%',
  width: '100%',
  padding: '0px 42px',
  '*': {
    outlineStyle: 'none !important',
  },
  [bp.md]: {
    padding: '0 0 0 32px',
  },
  [bp.lg]: {
    padding: '0 0 0 24px',
  },
})

export { Testimonials }
