import React from 'react'

const PhoneTouch: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="79" viewBox="0 0 63 79">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#224BC5"
          d="M38.482.717H7.519A6.615 6.615 0 00.88 7.34v65.035A6.615 6.615 0 007.519 79h30.96a6.615 6.615 0 006.64-6.624V7.263c0-3.591-2.96-6.546-6.637-6.546zM7.519 3.192h30.96c2.24 0 4.08 1.836 4.08 4.07v3.673l-39.118-.003v-3.67c0-2.234 1.84-4.07 4.078-4.07zM42.56 13.484v51.788l-39.118.003v-51.79H42.56zm-4.078 62.962H7.519c-2.24 0-4.08-1.835-4.08-4.07l.002-4.548H42.56v4.548c0 2.235-1.841 4.07-4.078 4.07z"
          transform="translate(-226 -2074) translate(226 2074)"
        />
        <path
          fill="#FFF"
          d="M25.682 71.977c0 3.513-5.282 3.513-5.282 0 0-3.51 5.282-3.51 5.282 0"
          transform="translate(-226 -2074) translate(226 2074)"
        />
        <path
          fill="#224BC5"
          d="M25.518 71.977c0 3.513-5.278 3.513-5.278 0 0-3.51 5.278-3.51 5.278 0M18.16 6.303c-.56 0-1.041.48-1.041 1.038a1.06 1.06 0 001.04 1.038H27.6c.56 0 1.04-.48 1.04-1.038a1.06 1.06 0 00-1.04-1.038h-9.44z"
          transform="translate(-226 -2074) translate(226 2074)"
        />
        <path
          fill="#FFF"
          stroke="#27AEE4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.2"
          d="M27.965 60h18.17C54.344 60 61 53.337 61 45.117c0-3.948-1.565-7.73-4.355-10.523l-4.655-4.66a3.184 3.184 0 00-4.504 0 3.193 3.193 0 000 4.51l-1.502-1.504a3.184 3.184 0 00-4.505 0 3.193 3.193 0 000 4.51l1.502 1.503-2.253-2.254a3.184 3.184 0 00-4.504 0 3.193 3.193 0 000 4.51l3.754 3.758-9.538-9.55a3.179 3.179 0 00-4.505 0 3.187 3.187 0 000 4.51l14.043 14.06H33.97A6.012 6.012 0 0027.965 60z"
          transform="translate(-226 -2074) translate(226 2074)"
        />
      </g>
    </svg>
  )
}

export default PhoneTouch
