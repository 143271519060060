import type { ExtractActions } from './utils'
import * as chatActions from './chat'
import * as errorActions from './error'
import * as authActions from './auth'
export * from './factories'

export { chatActions, errorActions, authActions }

export type Action =
  | ExtractActions<typeof chatActions>
  | ExtractActions<typeof errorActions>
  | ExtractActions<typeof authActions>

export type ActionType = Action['type']
