import type { FC } from 'react'
import React, { createRef, useEffect } from 'react'

const ScrollToBottom: FC = ({ children }) => {
  const divRef = createRef<HTMLDivElement>()

  useEffect(() => {
    if (divRef && divRef.current) {
      divRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [children, divRef])

  return (
    <>
      {children}
      <div ref={divRef} />
    </>
  )
}

export default ScrollToBottom
