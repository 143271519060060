import type { Slot } from '@customers-api-client'
import type { Action } from '../../types/action-type'
import type { ChatMessage } from '../../types/chat-message'
import { generateBotFailureMessage, BOT_FAILURE_MESSAGE } from '../../lib/chat-utils'

export type MessagesReducerState = ChatMessage[]

export const initialState: MessagesReducerState = []

export default function messages(state = initialState, action: Action): MessagesReducerState {
  switch (action.type) {
    case 'CHAT_MESSAGES_ADD':
      return [action.payload, ...state]
    case 'CHAT_MESSAGES_STOP_TYPING': {
      const key = action.payload
      return state.map((message) => {
        if (message.key === key) {
          return { ...message, isTyping: false }
        }
        return message
      })
    }
    case 'CHAT_MESSAGES_UPDATE_MESSAGE': {
      const { key, updatedMessage } = action.payload

      return state.map((message) => {
        if (message.key === key) {
          // Want to overide all the properties but keep the original message's key for React
          return { ...message, ...updatedMessage, key: message.key }
        }
        return message
      })
    }
    case 'CHAT_SESSION_UPDATE_FAILURE': {
      return state.map((message) => {
        if (!message.content) {
          return { ...message, isTyping: false, content: BOT_FAILURE_MESSAGE }
        }
        return message
      })
    }
    case 'CHAT_SESSION_CONNECT_FAILURE':
      return [...state, generateBotFailureMessage(BOT_FAILURE_MESSAGE)]
    case 'CHAT_MESSAGES_SET':
      return action.payload
    case 'CHAT_MESSAGES_RESET':
    case 'CHAT_SESSION_CONNECT_REQUEST':
      return []
    default:
      return state
  }
}

export function stateBefore(state: MessagesReducerState, slot: Slot): MessagesReducerState {
  const slotIndex = state.findIndex((item) => item.slot === slot)

  return state.slice(slotIndex + 1)
}
