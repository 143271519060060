/* eslint-disable @typescript-eslint/no-require-imports */
const images: Record<string, string> = {
  analyzingIcon: require('./analyzing-icon.png'),
  analyzingGif: require('./analyzing.gif'),
  approvedIcon: require('./approved-icon.png'),
  approvedIcon2: require('./approved-icon-2.png'),
  adhPlanHero: require('./adh-plan-hero.png'),
  basePlanHero: require('./base-plan-hero.png'),
  breakerBox: require('./breaker-box.png'),
  claimsMockupMobile: require('./claims-mockup-mobile.png'),
  crownAndCaliberHero: require('./crown-and-caliber-hero.png'),
  dangerImage: require('./danger.png'),
  defaultCarouselImage: require('./default-carousel-image.png'),
  defaultMobileProductImage: require('./default-mobile-product-image.png'),
  defaultProductImage: require('./default-product-image.png'),
  deliveryTruckImage: require('./delivery-truck-image.png'),
  deniedIcon: require('./denied-icon.png'),
  deniedIcon2: require('./denied-icon-2.png'),
  disconnectedPlug: require('./disconnected-plug-404.png'),
  extendIcon: require('./extend-icon.png'),
  extendLogo: require('./extend-logo.png'),
  extendLogoWhite: require('./extend-logo-white.png'),
  extendLogoWhiteBlue: require('./extend-logo-white-blue.png'),
  extendLoginBackground: require('./extend-login-background.png'),
  handWave: require('./hand-wave.png'),
  heartEmoji: require('./heart-emoji.png'),
  iPhoneClaim: require('./iphone-claim.png'),
  iPhoneClaimWheelsTires: require('./iphone-claim-wheels-tires.png'),
  kaleyLarge: require('./kaley-large.png'),
  kaleySmall: require('./kaley-small.png'),
  myExtendLogo: require('./myExtend-logo.png'),
  profile: require('./proflile-icon.png'),
  profileWhite: require('./proflile-icon-white.png'),
  raisedHandsEmoji: require('./raised-hands.png'),
  repairAnimation: require('./repair-animation.gif'),
  reviewIcon: require('./review-icon.png'),
  reviewIcon2: require('./review-icon-2.png'),
  sparksImage: require('./sparks-image.png'),
  smilingFaceEmoji: require('./smiling-face-emoji.png'),
  tempHarmony: require('./harmony.png'),
  tempLaptop: require('./laptop.jpg'),
  tempPlaystation: require('./playstation.jpg'),
  wheelsTiresPlanHero: require('./wheels-tires-hero.jpg'),
  leftArrow: require('./left-arrow.png'),
  rightArrow: require('./right-arrow.png'),
  claimManufactureCover: require('./claim-manufacture-cover.png'),
  shippingProtection: require('./shipping-protection.png'),
  noFees: require('./no-fees.png'),
  onlineClaims: require('./online-claims.png'),
  easyReplacement: require('./replace.png'),
  spWoman: require('./SP-woman.png'),
  virtualCardBackground: require('./virtual-card-background.png'),
  visaLogo: require('./visa-commercial-logo.png'),
  shippingProtectionMobile: require('./shipping-protection-mobile.png'),
  whyKaleyNoMobile: require('./why_kaley_no_mobile.png'),
  welcomeDesktop: require('./welcome-desktop.png'),
  welcomeMobile: require('./welcome-mobile.png'),
  charity: require('./charity.png'),
  fallbackImage: require('./fallback-image.png'),
}
export default images
