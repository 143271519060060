import { KJUR } from 'jsrsasign'
import dayjs from 'dayjs'

type TimeOptions = 's' | 'm' | 'h' | 'd'
export type ExpiresIn = `${number}${TimeOptions}`

interface Options {
  expiresIn?: ExpiresIn
  subject?: string
  issuer?: string
}

interface JwtPayload {
  iss?: string // Issuer
  sub?: string // Subject
  aud?: string | string[] // Audience
  exp?: number // Expiration Time
  nbf?: number // Not Before
  iat?: number // Issued At
  jti?: string // JWT ID
  [key: string]: any // Custom claims
}

interface JwtHeader {
  alg: string // Algorithm
  typ: string // Type
  kid?: string // Key ID
  [key: string]: any // Custom header parameters
}

/**
 * Sign a JWT token
 * @param header - The header of the JWT token
 * @param payload - The payload of the JWT token
 * @param secret - The secret to sign the JWT token with
 * @param options - Optional including expresIn for the JWT token
 * @returns The signed JWT token
 */
export function sign(payload: JwtPayload, secret: string, options?: Options): string {
  const headers: JwtHeader = {
    typ: 'JWT',
    alg: 'HS256',
  }

  Object.assign(payload, { iat: dayjs().unix() })

  if (options?.expiresIn) {
    // given a string like '3m' or '1h' split the string into a number and a unit
    // and add that to the current time as a unix timestamp
    const timeUnit: TimeOptions = options.expiresIn.slice(-1) as TimeOptions
    const timeValue = parseInt(options.expiresIn.slice(0, -1))

    Object.assign(payload, { exp: dayjs().add(timeValue, timeUnit).unix() })
  }

  if (options?.issuer) {
    Object.assign(payload, { iss: options.issuer })
  }

  if (options?.subject) {
    Object.assign(payload, { sub: options.subject })
  }

  return KJUR.jws.JWS.sign(headers.alg, JSON.stringify(headers), JSON.stringify(payload), secret)
}
