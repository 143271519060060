import React from 'react'
import styled from '@emotion/styled'
import { Timeline, TimelineItem, Icon, COLOR } from '@extend/zen'
import type { FC } from 'react'
import { SentimentCalm } from '@extend/zen'
import { bp } from '@customers-ui'

export const ClaimManualReviewTimeline: FC = () => {
  return (
    <NoActionContainer data-cy="claim-review-timeline">
      <Timeline>
        <TimelineItem
          label="Claim in review. We will notify you via email when ready."
          state="current"
          data-cy="claim-in-review-timeline-item"
        />
      </Timeline>
      <NoActionContentContainer>
        <Icon icon={SentimentCalm} color={`var(--neutral-700, ${COLOR.NEUTRAL[700]});`} data-cy="sentiment-calm-icon" />
        <NoActionNeededText data-cy="no-action-needed-text">Relax, no action needed.</NoActionNeededText>
      </NoActionContentContainer>
    </NoActionContainer>
  )
}

const NoActionContainer = styled.div({
  width: '100%',
  background: `var(--white, ${COLOR.WHITE});`,
})

const NoActionContentContainer = styled.div({
  display: 'flex',
  padding: '8px var(--borderRadius_consumer, 20px) 8px 16px;',
  alignItems: 'center',
  gap: 'var(--badge-border-radius, 4px);',
  borderRadius: 'var(--button-border-radius, 36px);',
  [bp.mobile]: {
    justifyContent: 'center',
  },
  [bp.desktop]: {
    justifyContent: 'flex-start',
  },
})

const NoActionNeededText = styled.div({
  color: `var(--neutral-700, ${COLOR.NEUTRAL[700]});`,
  fontFamily: 'Nunito Sans',
  fontSize: 16,
  fontWeight: 700,
  lineHeight: '24px',
})
