import type { CSSProperties } from 'react'
import { keyframes } from '@emotion/react'
import { COLOR } from '@extend/zen'

import { bp } from '../../utils/breakpoints'

export type ToastType = 'info' | 'success' | 'warning' | 'danger'

export type ToastPosition = 'top-center' | 'bottom-center' | 'top-left' | 'bottom-left' | 'top-right' | 'bottom-right'

const toastInRight = keyframes`
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  `

const toastInLeft = keyframes`
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  `

const toastInTop = keyframes`
    from {
     transform: translateX(-50%) translateY(-100%);
    }
    to {
      transform: translateX(-50%) translateY(0);
    }
  `

const toastInBottom = keyframes`
    from {
      transform: translateX(-50%) translateY(100%);
    }
    to {
      transform: translateX(-50%) translateY(0);
    }
  `

export const animationTransform = (position: ToastPosition): CSSProperties => {
  switch (position) {
    case 'top-right':
    case 'bottom-right':
      return {
        animation: `${toastInRight} .7s`,
      }
    case 'top-left':
    case 'bottom-left':
    default:
      return {
        animation: `${toastInLeft} .7s`,
      }
    case 'top-center':
      return {
        animation: `${toastInTop} .7s`,
      }
    case 'bottom-center':
      return {
        animation: `${toastInBottom} .7s`,
      }
  }
}

export const kindStyles = (type: ToastType): CSSProperties => {
  switch (type) {
    case 'success':
      return {
        background: COLOR.GREEN[100],
        color: COLOR.GREEN[800],
      }
    case 'warning':
      return {
        background: COLOR.YELLOW[100],
        color: COLOR.YELLOW[800],
      }
    case 'danger':
      return {
        background: COLOR.RED[200],
        color: COLOR.RED[800],
      }
    case 'info':
    default:
      return {
        background: COLOR.BLUE[100],
        color: COLOR.BLUE[800],
      }
  }
}

export const toastCssStyling = (position: ToastPosition): CSSProperties => {
  switch (position) {
    case 'top-left':
      return {
        left: 16,
        [bp.mobile]: {
          top: 88,
        },
        [bp.desktop]: {
          top: 94,
        },
      }
    case 'top-right':
      return {
        right: 16,
        [bp.mobile]: {
          top: 88,
        },
        [bp.desktop]: {
          top: 94,
        },
      }
    case 'bottom-left':
      return {
        bottom: 16,
        left: 16,
      }
    case 'bottom-right':
      return {
        bottom: 16,
        right: 16,
      }
    case 'bottom-center':
      return {
        bottom: 16,
        left: '50%',
        transform: 'translateX(-50%)',
      }
    case 'top-center':
    default:
      return {
        left: '50%',
        transform: 'translateX(-50%)',
        [bp.mobile]: {
          top: 88,
        },
        [bp.desktop]: {
          top: 94,
        },
      }
  }
}
