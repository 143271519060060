import React from 'react'

const ArrowsCircle: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="52" viewBox="0 0 57 52">
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        transform="translate(-145 -1331) translate(147 1333)"
      >
        <path stroke="#224BC5" d="M25.3541667 0.145833333L34.5208333 9.3125 25.3541667 18.4791667" />
        <path stroke="#27AEE4" d="M27.6458333 48.2708333L18.4791667 39.1041667 27.6458333 29.9375" />
        <path
          stroke="#224BC5"
          d="M13.896 39.104h0c-7.592 0-13.75-6.158-13.75-13.75v-2.291c0-7.593 6.158-13.75 13.75-13.75H34.52"
        />
        <path
          stroke="#27AEE4"
          d="M39.104 9.313h0c7.592 0 13.75 6.157 13.75 13.75v2.291c0 7.592-6.158 13.75-13.75 13.75H18.48"
        />
      </g>
    </svg>
  )
}

export default ArrowsCircle
