import type { PlanSubcategory, PlanContract } from './plan'
import type { CurrencyCode, Money, SupportedCurrencyCode } from './currency'
import type { CategoryProduct } from './product'

export type CustomerType = 'consumer'

export type PlanCategory = 'service_contract' | 'shipping_contract'
export enum ContractType {
  PCRS = 'pcrs',
  SHIPPING_PROTECTION = 'shipping_protection',
  SUBSCRIPTION = 'subscription',
  CATEGORY = 'category',
  PRODUCT_PROTECTION_BUNDLE = 'product_protection_bundle',
}

export enum ContractStatus {
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
  FULFILLED = 'fulfilled',
  DELIVERED = 'delivered',
  LIVE = 'live',
  REFUND_ACCEPTED = 'refund_accepted',
  REFUND_DENIED = 'refund_denied',
  REFUND_PAID = 'refund_paid',
  REFUND_REQUESTED = 'refund_requested',
  VOIDED = 'voided',
  CREATED = 'created',
  TERMINATED = 'terminated',
}

export enum ContractAdminSyncStatus {
  SYNC_ERROR = 'sync_error',
  SYNCED = 'synced',
  UNSYNCED = 'unsynced',
  RESYNC_REQUIRED = 'resync_required',
}

export enum LimitOfLiabilityAmountType {
  PRODUCT_PURCHASE_PRICE = 'product_purchase_price',
  PRODUCT_LIST_PRICE = 'product_list_price',
}
export interface Contract
  extends ContractReadOnlyProperties,
    ContractWritableProperties,
    ContractAdminSearchKeyProperties {}

interface ContractAdminSearchKeyProperties {
  adminContractId?: string
  adminSyncError?: string
  adminSyncStatus?: ContractAdminSyncStatus
  store?: ContractStore
  syncedAt?: number
}

export interface ContractTerms {
  termsId: string
  version: string
}

interface ContractReadOnlyProperties {
  contractVersion?: string
  cancelledAt?: number
  createdAt: number
  id: string // unique identifier for contract
  poNumber?: string
  resellerMerchant?: {
    id?: string
    name?: string
    domain?: string
  }
  refundedAt?: number
  refundAmount?: Money
  sellerId: string
  sellerName: string
  status: ContractStatus
  updatedAt: number
  terms?: ContractTerms
  type: ContractType
  purchasePrice?: number // 2.0 property
  offerToken?: string // 2.0 property
  orderId?: string // 2.0 property
  quoteId?: string // 2.0 property
  purchaseCurrency?: string // 2.0 property?
  limitOfLiabilityAmount?: number
  limitOfLiabilityAmountType?: LimitOfLiabilityAmountType
}

export interface ContractPlan {
  planId: string
  planSku?: string
  expectedPrice?: number
  purchasePrice?: number | Money
  subcategory?: PlanSubcategory
  category?: PlanCategory // 2.0  field
  id?: string // 2.0 field
  skuId?: string // 2.0 field
  termsId?: string // 2.0 field
  termsVersion?: string // 2.0 field
  version?: number // 2.0 field
}

interface ContractWritableProperties {
  currency?: CurrencyCode
  coverage?: {
    starts: number
    ends: number
    status?: string
    amount?: number
    amountRemaining?: number
  }
  terms?: ContractTerms
  customer: ContractCustomer
  merchantCustomerId?: string
  plan: ContractPlan
  product: ContractProduct
  productsList?: ContractCoveredProduct[]
  syncedAt?: number
  transactionDate?: number
  purchaseDate?: number // 2.0 field
  trackingInfo?: ContractTrackingInfo[]
  transactionId: string
  transactionTotal?: number
  source?: ContractSource
  isTest?: boolean
  listPrice?: number
}

export interface ContractCustomer {
  billingAddress?: Address
  email: string
  name: string
  phone?: string
  shippingAddress?: Address
  type?: CustomerType
}

// TODO: this should prob belong somewhere outside of here
export interface Address {
  address1: string
  address2?: string
  city: string
  countryCode: string
  postalCode: string
  // usually the state but not necessarily
  provinceCode: string
}

export interface ContractProduct {
  brand?: string
  condition?: IContractProductCondition
  imageUrl?: string
  listPrice: number
  manufacturerWarrantyLengthLabor: number
  manufacturerWarrantyLengthParts: number
  purchasePrice: number
  referenceId: string
  reimbursementAmount: number
  serialNumber?: string
  title: string
  name?: string // 2.0 field
  transactionDate: number
  transactionId: string
  purchaseDate?: number // 2.0 field
  fulfillmentDate?: number // 2.0 field
  sku?: string
  gtin?: string
  upc?: string
  asin?: string
  barcode?: string
  quantity?: number
}

export interface ContractCoveredProduct {
  listPrice: number
  purchasePrice: number
  quantity: number
  referenceId: string
  title: string
  imageUrl?: string
  lineItemId?: string
}

export interface ContractTrackingInfo {
  trackingId: string
  shippingProvider: 'usps' | 'ups' | 'fedex'
  shippingState: 'created' | 'shipped' | 'delivered' | 'expired'
  shipmentId?: string
  productsShipped: string[]
}

export interface ContractStore {
  adminLocationNumber?: string
  adminDealerNumber?: string
  adminManufacturer?: string
}

export type IContractProductCondition = 'new' | 'used' | 'refurbished'

export interface SearchOptions {
  adminSyncStatus?: ContractAdminSyncStatus
  contractId?: string
  customerEmail?: string
  merchantCustomerId?: string
  productTransactionId?: string
  status?: ContractStatus
  transactionId?: string
  productSerialNumber?: string
}

export interface SearchOptionsWithTypeFilter extends SearchOptions {
  typeFilter?: ContractType[]
}

type SourceChannel = 'web' | 'mobile' | 'callcenter' | 'store'

export interface ContractSource {
  agentId?: string
  channel?: SourceChannel
  integratorId?: string
  locationId?: string
  platform?: string
}

export interface ContractsGetResponse {
  contractVersion: '1.0'
  adminContractId?: string
  adminSyncError?: string
  adminSyncStatus?: ContractAdminSyncStatus
  cancelledAt?: number
  coverage: {
    starts: number
    ends: number
    amountRemaining?: number
  }
  createdAt: number
  customer: ContractCustomer
  id: string
  poNumber?: string
  product: {
    brand?: string
    condition?: IContractProductCondition
    imageUrl?: string
    listPrice: Money
    manufacturerWarrantyLengthLabor?: number
    manufacturerWarrantyLengthParts?: number
    purchasePrice: Money
    referenceId: string
    reimbursementAmount: Money
    serialNumber?: string
    title: string
    name?: string
    transactionDate: number
    transactionId: string
  }
  plan: {
    expectedPrice?: Money
    planId: string
    planSku?: string
    purchasePrice: Money
    subcategory?: PlanSubcategory
  }
  purchasePrice: number
  planDetails: PlanContract
  refundedAt?: number
  sellerName: string
  sellerId: string
  status: ContractStatus
  store?: ContractStore
  syncedAt?: number
  terms: {
    termsId: string
    version: string
  }
  transactionDate: number
  transactionId: string
  transactionTotal?: Money
  type?: ContractType
  updatedAt: number
  sku?: string
  upc?: string
  gtin?: string
  barcode?: string
  asin?: string
  isTest?: boolean
  source?: ContractSource
}

export interface ContractsUpdateResponse extends ContractsGetResponse {}

export interface ContractsV2GetResponse {
  contractVersion: '2.0'
  adminContractId?: string
  adminSyncError?: string
  adminSyncStatus?: ContractAdminSyncStatus
  coverage?: {
    starts: number
    ends: number
  }
  createdAt: number
  customer: {
    billingAddress?: Address
    email: string
    name: string
    phone?: string
    shippingAddress?: Address
    type?: CustomerType
  }
  currency: SupportedCurrencyCode
  id: string
  isTest: boolean
  listPrice?: Money
  offerToken?: string
  orderId?: string
  plan: {
    category?: PlanCategory
    id: string
    skuId?: string
    termsId?: string
    termsVersion?: string
    version?: number
    expectedPrice?: number
  }
  product: {
    fulfillmentDate: number
    listPrice: Money
    manufacturerWarrantyLengthLabor: number
    manufacturerWarrantyLengthParts: number
    purchaseDate: number
    purchasePrice: Money
    referenceId: string
    reimbursementAmount: Money
    serialNumber?: string
    name: string
    transactionId: string
    imageUrl?: string
  }
  productsList?: ContractCoveredProduct[]
  productIds?: string[]
  purchaseCurrency: CurrencyCode
  transactionTotal?: number
  transactionId: string
  transactionDate: number
  purchaseDate: number
  purchasePrice: number
  sellerId: string
  sellerName: string
  status: ContractStatus
  trackingInfo?: ContractTrackingInfo[]
  type?: ContractType
  updatedAt: number
  planDetails: PlanContract
  merchantCustomerId?: string
  [key: string]: unknown
}

export interface Contract20220201GetResponse {
  accountId: string
  adminContractId?: string
  adminSyncError?: string
  adminSyncStatus: ContractAdminSyncStatus
  cancelledAt?: number
  createdAt: number
  coverage?: {
    starts: number
    ends: number
  }
  customer: {
    billingAddress?: Address
    email: string
    name: string
    phone?: string
    shippingAddress?: Address
    type: CustomerType
    locale?: string
    region?: string
    subdivision?: string
  }
  formId?: string
  id: string
  isTest: boolean
  listPrice?: number
  expectedPrice?: number
  orderId?: string

  quoteId?: string
  plan: {
    category: PlanCategory
    id: string
    skuId?: string
    termsId?: string
    termsVersion?: string
    version?: number
  }
  poNumber?: string
  product: {
    fulfillmentDate: number
    imageUrl?: string
    listPrice: number
    manufacturerWarrantyLengthLabor: number
    manufacturerWarrantyLengthParts: number
    purchaseDate: number
    purchasePrice: number
    referenceId: string
    reimbursementAmount?: number
    serialNumber?: string
    name: string
    transactionId: string
    brand?: string
    sku?: string
  }
  purchaseCurrency: CurrencyCode
  purchaseDate: number
  purchasePrice: number
  refundedAt?: number
  sellerId: string
  sellerName: string
  status: ContractStatus
  transactionId: string
  type:
    | ContractType.PCRS
    | ContractType.SHIPPING_PROTECTION
    | ContractType.CATEGORY
    | ContractType.PRODUCT_PROTECTION_BUNDLE
  updatedAt: number
  limitOfLiabilityAmount?: number
  limitOfLiabilityAmountType?: LimitOfLiabilityAmountType
  merchantCustomerId?: string

  productsList?: ContractCoveredProduct[]
  trackingInfo?: ContractTrackingInfo[]
  charitableDonation?: {
    id: string
    name?: string
    donationAmount: number
  }
}

export interface ContractsUpdateRequest {
  adminContractId?: string
  adminSyncError?: string
  cancelledAt?: number
  coverage?: {
    starts?: number
    ends?: number
  }
  currency?: CurrencyCode
  customer?: ContractCustomer
  isTest?: boolean
  listPrice?: number
  merchantCustomerId?: string
  plan?: {
    expectedPrice?: number
    planId?: string
    planSku?: string
    purchasePrice?: number
    subcategory?: PlanSubcategory
    category?: PlanCategory
    version?: number
    termsVersion?: string
    termsId?: string
  }
  poNumber?: string
  product?: {
    brand?: string
    condition?: IContractProductCondition
    fulfillmentDate?: number
    imageUrl?: string
    listPrice?: number
    manufacturerWarrantyLengthLabor?: number
    manufacturerWarrantyLengthParts?: number
    purchasePrice?: number
    purchaseDate?: number
    referenceId?: string
    reimbursementAmount?: number
    serialNumber?: string
    title?: string
    transactionDate?: number
    transactionId?: string
    sku?: string
    gtin?: string
    upc?: string
    asin?: string
    barcode?: string
    name?: string
  }
  purchaseDate?: number
  purchasePrice?: number
  refundedAt?: number
  refundAmount?: Money
  sellerId?: string
  sellerName?: string
  source?: ContractSource
  status?: ContractStatus
  store?: ContractStore
  terms?: {
    termsId?: string
    version?: string
  }
  transactionDate?: number
  transactionId?: string
  transactionTotal?: number
}

export interface CategoryContract {
  accountId: string
  createdAt: number

  quoteId?: string

  listPrice?: number

  merchantCustomerId?: string
  customer: {
    billingAddress?: {
      address1: string
      address2?: string
      city: string
      countryCode: string
      postalCode: string
      provinceCode?: string
    }
    email: string
    name: string
    phone?: string
    shippingAddress?: {
      address1: string
      address2?: string
      city: string
      countryCode: string
      postalCode: string
      provinceCode?: string
    }
    type: 'consumer'
    locale?: string
    region?: string
    subdivision?: string
  }
  formId?: string

  coverage?: {
    starts?: number
    ends?: number
  }
  id: string
  isTest: boolean
  limitOfLiabilityAmount?: number
  limitOfLiabilityAmountType?: 'product_purchase_price' | 'product_list_price'
  lineItemId: string
  orderId: string
  plan: {
    category: 'service_contract' | 'shipping_contract'
    id: string
    skuId?: string
    termsId?: string
    termsVersion?: string
    version?: number
  }
  productIds: string[]
  productsList?: Array<CategoryProduct & unknown>
  purchaseCurrency: 'AUD' | 'CAD' | 'EUR' | 'GBP' | 'NZD' | 'USD'
  purchaseDate: number
  purchasePrice: number
  expectedPrice?: number
  resellerMerchant?: {
    id?: string
    name?: string
    domain?: string
  }
  refundedAt?: number
  refundAmount?: {
    amount: number
    currencyCode?: 'AUD' | 'CAD' | 'EUR' | 'GBP' | 'NZD' | 'USD'
    formattedAmount?: string
  }
  sellerId: string
  sellerName: string
  status: ContractStatus
  transactionId: string
  type: ContractType.CATEGORY
  updatedAt: number
  planDetails?: {
    category: string
    coverage_includes: string
    coverage_starts: string
    deductible: number
    pcmi_category: string
    pcmi_subcategory: string
    servicer_ids: string[]
    replacement_type: string
    service_type: 'replace' | 'repair_depot' | 'repair_onsite' | 'repair_irobot'
    term_length: number
  }
}
