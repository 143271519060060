import type { SagaIterator } from 'redux-saga'
import { takeLatest, takeEvery, all } from 'redux-saga/effects'
import type { ActionType } from '../../types/action-type'
import sessionStart from './session-start'
import throttleSessionUpdate from './throttle-session-update'
import { chatSessionImageUpload } from './chat-session-image-upload'

export default function* sagas(): SagaIterator {
  yield all([
    takeLatest(['CHAT_SESSION_RESTART'] as ActionType[], sessionStart),
    takeEvery('CHAT_SESSION_UPDATE' as ActionType, throttleSessionUpdate),
    takeLatest(['CHAT_SESSION_IMAGE_UPLOAD'] as ActionType[], chatSessionImageUpload),
  ])
}
