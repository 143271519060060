import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Grid } from '@customers-ui'

import type { ToastType, ToastPosition } from './toast.utils'
import { kindStyles, toastCssStyling, animationTransform } from './toast.utils'

export interface ToastProps {
  message: string
  position?: ToastPosition
  type?: ToastType
  isVisible?: boolean
  isDismissible?: boolean
  handleDismiss?: () => void
  dataCy?: string
}

const Toast: FC<ToastProps> = ({
  message,
  position = 'top-center',
  type = 'info',
  children,
  isVisible = true,
  isDismissible = false,
  handleDismiss,
  dataCy = 'customers-toast',
}) => {
  return (
    <ToastContainer {...{ position, type, isVisible }} data-cy={dataCy}>
      <StyledGrid>
        <MessageContainer>{message}</MessageContainer>
        {children && <InteractionContainer>{children}</InteractionContainer>}
        {isDismissible && handleDismiss && (
          <DismissButton toastType={type} onClick={handleDismiss}>
            x
          </DismissButton>
        )}
      </StyledGrid>
    </ToastContainer>
  )
}

const ToastContainer = styled.div<{
  position: ToastPosition
  type: ToastType
  isVisible: boolean
}>(({ position, type, isVisible }) => ({
  display: isVisible ? 'flex' : 'none',
  fontSize: 15,
  boxSizing: 'border-box',
  position: 'fixed',
  transition: 'transform .6s ease-in',
  padding: 16,
  borderRadius: 6,
  lineHeight: '20px',
  maxWidth: '50%',
  minWidth: 343,
  maxHeight: '50%',
  zIndex: 10,
  ...kindStyles(type),
  ...toastCssStyling(position),
  ...animationTransform(position),
}))

const StyledGrid = styled(Grid)({
  justifyContent: 'space-between',
  width: '100%',
  gap: 12,
})

const MessageContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const InteractionContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const DismissButton = styled.button<{ toastType: ToastType }>(({ toastType }) => ({
  '&:hover': {
    cursor: 'pointer',
  },
  display: 'flex',
  fontSize: 18,
  border: 'none',
  lineHeight: '19px',
  fontWeight: 400,
  ...kindStyles(toastType),
}))

export { Toast }
