import React from 'react'
import { COLOR } from '@extend/zen'

const Profile: React.FC = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22" cy="22" r="22" fill={COLOR.BLUE['900']} />
      <circle cx="22" cy="17" r="8" fill={COLOR.BLUE['100']} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.89502 38.8841C9.98657 33.1188 15.5123 29 22 29C28.4877 29 34.0134 33.1188 36.105 38.8841C32.2862 42.0778 27.3676 44 22 44C16.6324 44 11.7138 42.0778 7.89502 38.8841Z"
        fill={COLOR.BLUE['100']}
      />
    </svg>
  )
}

export default Profile
