import type { Slot, Reply } from '@customers-api-client'
import type { SagaIterator } from 'redux-saga'
import { cancel, fork, put, select, take } from '@redux-saga/core/effects'
import { chatActions } from '../../actions'
import * as selectors from '../../reducers/selectors'
import { filteredSlots } from '../../lib/chat-utils'
import loadBotContent from './load-bot-content'
import type { ActionType } from '../../types/action-type'

export default function* cancelableLoadBotContent(
  reply: Reply,
  slot?: Slot,
  key?: string,
  requestDelay?: number,
): SagaIterator {
  const slots: ReturnType<typeof selectors.getChatSlots> = yield select(selectors.getChatSlots)

  if (slot) {
    yield put(chatActions.chatSlotsSet(filteredSlots(slots, slot)))
  }

  // Load bot content as a forked background task
  const bgBotLoadTask = yield fork(() => loadBotContent(reply, key, requestDelay))

  // If the chat is restarted or edited
  yield take(['CHAT_SESSION_RESTART', 'CHAT_SESSION_EDIT'] as ActionType[])
  // Cancel the loading of content
  yield cancel(bgBotLoadTask)
}
