import type { FC } from 'react'
import React from 'react'

export const RoadCone: FC = () => {
  return (
    <svg width="61px" height="62px" viewBox="0 0 61 62" version="1.1">
      <g id="Tire/Wheel-Plan-Details" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Plan-Details-Copy" transform="translate(-143.000000, -1314.000000)" strokeWidth="3">
          <g id="Group-5" transform="translate(145.000000, 1035.000000)">
            <g id="trafficcone" transform="translate(0.000000, 281.000000)">
              <path
                d="M19,15 C22.1535824,17.9639973 26.1459754,19.1890497 29.1963647,18.9765644 C31.9878728,18.7821122 33.9971127,17.7024796 37,15"
                id="Path-12"
                stroke="#27AEE4"
              />
              <path
                d="M15.6168297,26.6803302 C24.3694279,33.1119318 32.0859316,32.6583783 40,26.6803302"
                id="Path-13"
                stroke="#27AEE4"
              />
              <path
                d="M12.766448,37.2821746 C11.1683273,37.6638189 5.0221004,40.0530906 2.32623085,41.1760778 C-0.369638715,42.2990653 -0.99649712,43.8509622 1.91512901,45.4376549 C2.96932381,46.0121392 18.4685403,54.7517829 21.5390253,56.2353685 C22.7505672,56.820757 23.8904503,57.2521525 24.9586746,57.529555 C27.5884148,58.156815 30.0279137,58.156815 32.2771713,57.529555 C35.6510578,56.5886644 50.3146514,46.9639892 52.5293442,45.4376549 C54.7440373,43.911321 57.9606392,42.4934202 56,41.1760778 C54.0393608,39.8587354 49.3082977,38.2799118 42.7644523,36.3303912"
                id="Path-15"
                stroke="#224BC5"
              />
              <path
                d="M24.7179134,1.28882029 C23.8583293,2.36895127 19.7053881,14.8521073 12.2590898,38.7382883 C11.1961619,41.9588127 13.4037673,44.5109811 18.881906,46.394793 C24.3600446,48.278605 30.2131871,48.5043374 36.4413333,47.0719909 C42.7669414,45.1115002 45.122274,42.1526756 43.5073315,38.1955173 C41.8923889,34.2383588 38.2652775,22.416564 32.6259973,2.73013289 C31.8392859,0.910091635 30.4832187,0 28.5577958,0 C26.8329379,0 26.2790024,-0.0108736463 25.8215315,0.238997996 C25.5130045,0.407516142 25.2372455,0.636241019 24.7179134,1.28882029 Z"
                id="Path-11"
                stroke="#224BC5"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
