// Feel there is not enough overlap (and already enough complexity) with the other <Button />
// in Common to try to recycle the code
import styled from '@emotion/styled'
import { COLOR } from '@customers-ui'

interface ButtonProps {
  disabled: boolean
}
export const Button = styled.button<ButtonProps>((props) => ({
  backgroundColor: COLOR.WHITE,
  border: `1px solid ${props.disabled ? COLOR.NEUTRAL[500] : COLOR.BLUE[700]}`,
  boxShadow: 'none',
  color: props.disabled ? COLOR.NEUTRAL[500] : COLOR.BLUE[700],
  cursor: props.disabled ? 'default' : 'pointer',
  padding: '8px 16px',
  outline: 'none',
  whiteSpace: 'nowrap',
  fontSize: '16px',
  marginBottom: '8px',
  borderRadius: '16px',

  '&:hover, &:active': {
    backgroundColor: props.disabled ? COLOR.WHITE : COLOR.BLUE[700],
    color: props.disabled ? COLOR.NEUTRAL[500] : COLOR.WHITE,
  },
}))
