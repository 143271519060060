import React from 'react'

const Package: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="61" height="61" viewBox="0 0 61 61">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#224BC5">
          <path
            d="M60.984 18.216a1.19 1.19 0 00-.115-.39c-.014-.027-.014-.055-.03-.079L49.655.464h-.003A1.01 1.01 0 0048.8 0H12.2a1.01 1.01 0 00-.853.464L.165 17.747c-.017.024-.017.052-.031.079l-.003.002c-.03.06-.054.124-.074.188a.932.932 0 00-.04.2c-.002.029-.017.055-.017.084v41.682A1.015 1.015 0 001.018 61h58.965A1.015 1.015 0 0061 59.982V18.3c0-.029-.014-.055-.016-.084zm-2.87-.934h-20.61l-1.795-15.25h12.539l9.867 15.25zM35.583 18.36v6.174l-1.467-.98a1.015 1.015 0 00-1.13 0L30.5 25.21l-2.485-1.656a1.015 1.015 0 00-1.13 0l-1.468.98v-6.175l1.92-16.327h6.325l1.92 16.327zM12.752 2.032h12.54l-1.795 15.25H2.886l9.867-15.25zm46.215 56.935H2.032v-39.65h21.35v7.118-.003c0 .377.208.72.539.899.329.176.731.157 1.044-.053l2.485-1.656 2.485 1.656c.343.229.786.229 1.13 0l2.485-1.656 2.485 1.656a1.015 1.015 0 001.582-.846v-7.115h21.35v39.65z"
            transform="translate(-226 -2265) translate(226 2265)"
          />
          <path
            d="M17.283 30.5v-7.117a1.014 1.014 0 00-1.016-1.016H6.1a1.02 1.02 0 00-1.018 1.016V30.5A1.02 1.02 0 006.1 31.517h10.167a1.014 1.014 0 001.016-1.017zm-2.033-1.017H7.117V24.4h8.133v5.083z"
            transform="translate(-226 -2265) translate(226 2265)"
          />
        </g>
        <g
          stroke="#27AEE4"
          strokeLinecap="round"
          strokeWidth="1.937"
          transform="translate(-226 -2265) translate(226 2265) translate(16.46 39.698)"
        >
          <path d="M0.739 0.484L27.456 0.484" />
          <path d="M0.739 5.81L27.456 5.81" />
          <path d="M0.739 11.135L27.456 11.135" />
        </g>
      </g>
    </svg>
  )
}

export default Package
