import React, { useState } from 'react'
import type { FC } from 'react'
import type { DatePickerPrompt, Reply, Slot } from '@extend-incredibot/types'
import styled from '@emotion/styled'
import { bp } from '@customers-ui'
import type { DateValue } from '@extend/zen'
import { Button, DatePicker as DatePickerZen } from '@extend/zen'
import type { UserInputComponentProps } from './types'

export interface DatePickerProps extends UserInputComponentProps<Reply & { prompt: DatePickerPrompt }> {}

const DatePicker: FC<DatePickerProps> = ({ isLoading, onSubmit, reply }) => {
  const [value, setValue] = useState<DateValue>(new Date())

  const handleChange = (date: DateValue): void => {
    if (date) {
      setValue(date)
    }
  }

  const handleSubmit = (): void => {
    if (reply.prompt?.slot && value) {
      onSubmit(reply.prompt.slot as Slot, value.getTime())
    }
  }

  return (
    <ContentWrapper data-cy="date-picker-content-wrapper">
      <DatePickerZen
        isScrollable={false}
        // future nice to have: add minDate based on coverage
        maxDate={new Date()}
        onChange={handleChange}
        value={value}
        color="neutral"
      />
      <ButtonWrapper>
        <Button
          data-cy="next-button"
          text="Next"
          isDisabled={isLoading}
          isProcessing={isLoading}
          onClick={handleSubmit}
          type="submit"
          color="neutral"
        />
      </ButtonWrapper>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  width: '100%',
  display: 'inline-flex',
  justifyContent: 'center',
  flexDirection: 'column',
  [bp.desktop]: {
    gap: 32,
  },
  [bp.mobile]: {
    gap: 16,
  },
})

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

export { DatePicker }
