import { customLogger } from '@extend/client-helpers'
import type { RootState } from '../reducers'
import { decodeToken, isValidToken } from '../lib/security-utils/jwt'

export const ACCESS_TOKEN_KEY = `${__EXTEND_ENV__}_CONSUMER_ACCESS_TOKEN`

export function saveState(state: RootState): void {
  setItem(ACCESS_TOKEN_KEY, state.myExtend.accessToken || state.auth.accessToken)
}

export function reset(): void {
  try {
    localStorage.clear()
  } catch (e) {
    customLogger.warn('failed to clear local storage')
  }
}

export function loadInitialState(): any {
  const accessToken = getItem(ACCESS_TOKEN_KEY)

  return {
    myExtend: {
      accessToken,
      decodedAccessToken: accessToken ? decodeToken(accessToken) : null,
      isLoggedIn: accessToken ? isValidToken(accessToken) : false,
      profile: null,
    },
    poll: {},
  }
}

export function getItem(key: string): string | null {
  try {
    return localStorage.getItem(key)
  } catch (e) {
    customLogger.warn('error getting an item in getItem', { key })
    return null
  }
}

export function setItem(key: string, value?: string | object | null): void {
  try {
    if (!value) {
      localStorage.removeItem(key)
      return
    }
    const valueToStore = typeof value === 'object' ? JSON.stringify(value) : value
    localStorage.setItem(key, valueToStore)
  } catch (e) {
    customLogger.warn('error setting an item in setItem', { key })
  }
}

export function removeItem(key: string): void {
  try {
    localStorage.removeItem(key)
  } catch (e) {
    customLogger.warn('error removing an item in removeItem')
  }
}
