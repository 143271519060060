import { useEffect, useState } from 'react'

enum Status {
  idle = 'idle',
  loading = 'loading',
  success = 'success',
  error = 'error',
}

interface UseImageProps {
  status: Status
  image: PreloadedImageType | undefined
}

export type PreloadedImageType =
  | {
      src: string
      width: number
      height: number
    }
  | undefined

export function useImage(imageUrl?: string): UseImageProps {
  const [status, setStatus] = useState<Status>(Status.idle)
  /* Setting the image properties in a "PreloadedImageType" object
   * to reduce the re-renders any component using the hook.
   * Storing the image attributes in three separate
   * states (i.e. source, width, height) would lead in extra renders, one for
   * every call to the to the setState function. Instead, this only concerns us with
   * the preloaded states of the image.
   */
  const [preloadedImage, setPreloadedImage] = useState<PreloadedImageType>(undefined)

  useEffect(() => {
    if (!imageUrl) {
      setStatus(Status.success)
      return
    }
    setStatus(Status.loading)
    const image = new Image()
    image.src = imageUrl
    image.onload = () => {
      const preloadedProps = {
        src: imageUrl,
        width: image.naturalWidth,
        height: image.naturalHeight,
      }
      setPreloadedImage(preloadedProps)
      setStatus(Status.success)
    }
    image.onerror = () => {
      setStatus(Status.error)
    }
  }, [imageUrl])

  return {
    status,
    image: preloadedImage,
  }
}
