import { useEffect, useState } from 'react'
import * as ReactGA from 'react-ga'

const prodHosts = ['customers.extend.com']
const prodGaId = 'UA-133302759-6'
const nonProdGaId = 'UA-133302759-7'
export interface GaState {
  initialized: boolean
}

export function useAnalytics(): GaState {
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    const url = new URL(window.location.href)
    if (prodHosts.includes(url.hostname)) {
      ReactGA.initialize(prodGaId)
    } else {
      ReactGA.initialize(nonProdGaId)
    }
    setInitialized(true)
  }, [])

  return {
    initialized,
  }
}
