export const ShippingProtectionCharityContent = {
  charityModalHeadline: 'Do good while you shop',
  charityModalSubheadline: 'A portion of this purchase goes toward a meaningful cause.',
  charityPlanDetailLinkText: 'More details',
  charityModalDisclosureText:
    'Eligible shipments are insured under a cargo insurance policy (the “Extend Policy”) issued to Extend, Inc. by Overtime Insurance Solutions Corporation and produced through Extend Insurance Agency, Inc. While you are not an insured under the Extend Policy, any reimbursement will be directed to you as a loss payee. Protection may not be available in all jurisdictions and coverage is governed by the terms, conditions, limitations and exclusions set forth in the Extend Policy.',
  charityPlanDetailsHeadline: 'Do good while you shop.',
  charityPlanDetailsSubheadline: 'A portion of this purchase goes toward a meaningful cause.',
  charityPlanDetailsDisclosureText:
    'Eligible shipments are insured under a cargo insurance policy (the “Extend Policy”) issued to Extend, Inc. by Overtime Insurance Solutions Corporation and produced through Extend Insurance Agency, Inc. While you are not an insured under the Extend Policy, any reimbursement will be directed to you as a loss payee. Protection may not be available in all jurisdictions and coverage is governed by the terms, conditions, limitations and exclusions set forth in the Extend Policy.',
  charityCartSubheader: 'Replaces lost, stolen, or damaged packages and supports a good cause.',
}
