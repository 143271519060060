import React from 'react'

const StartOverIcon: React.FC = () => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="22px" height="22px">
      <g
        id="Claims-portal-start-over"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g id="360_Home" transform="translate(-309.000000, -83.000000)" stroke="#000000">
          <g id="start-over" transform="translate(310.000000, 84.000000)">
            <g id="Group-5">
              <path
                d="M0.759675,17.615325 C2.724925,19.5797 5.30005,20.562325 7.87605,20.562325 C10.4503,20.562325 13.0263,19.5797 14.990675,17.615325 C16.95505,15.65095 17.937675,13.075825 17.937675,10.499825 C17.937675,7.9247 16.95505,5.349575 14.990675,3.384325 C13.025425,1.41995 10.4503,0.437325 7.875175,0.437325 C5.299175,0.437325 2.72405,1.41995 0.759675,3.384325"
                id="Stroke-1"
              />
              <polyline id="Stroke-3" points="0.4375 0.4375 0.4375 3.9375 3.9375 3.9375" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default StartOverIcon
