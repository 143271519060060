import React from 'react'
import type { FC } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import { Stack } from '@extend/zen'
import { isMobile } from 'react-device-detect'
import { bp } from '@customers-ui'
import { useScreenSize } from '../../../../hooks'
import { images } from '../../../../lib/assets'

interface WhyExtendProps {
  valuePropositionBody?: string
  valuePropositionHeader?: string
  valuePropositionImage?: string
}

const WhyExtend: FC<WhyExtendProps> = ({ valuePropositionHeader, valuePropositionBody, valuePropositionImage }) => {
  const screenSize = useScreenSize()
  const spacing = 2
  const heading = valuePropositionHeader || 'Why Add Extend?'
  const body =
    valuePropositionBody ||
    `Extend provides affordable shipping protection with a modern, easy-to understand process. Extend Shipping Protection protects your package en route; whether it’s a mixup on the truck, a mishandling from a shipping carrier, or it's stolen while you’re at work, you’re covered`
  const image = valuePropositionImage || images.whyKaleyNoMobile
  const altImage = 'Why Extend'

  if (isMobile || screenSize == 'medium' || screenSize == 'small') {
    return (
      <Stack align="center" justify="center" spacing={spacing}>
        <MainTitle data-cy="main-title">{heading}</MainTitle>
        <Description>{body}</Description>
        <Img src={image} alt={altImage} />
      </Stack>
    )
  } else {
    return (
      <Stack isRow align="center" justify="center" spacing={spacing}>
        <Stack spacing={spacing}>
          <MainTitle data-cy="main-title">{heading}</MainTitle>
          <Description>{body}</Description>
        </Stack>
        <Img src={image} alt={altImage} />
      </Stack>
    )
  }
}

const MainTitle = styled.div({
  [bp.mobile]: {
    fontSize: 28,
    lineHeight: '38px',
    fontWeight: 800,
    width: 345,
    textAlign: 'center',
  },
  [bp.desktop]: {
    fontSize: 52,
    lineHeight: '57px',
    fontWeight: 600,
    paddingBottom: 16,
    width: '100%',
    textAlign: 'center',
  },
})

const Description = styled.div({
  [bp.mobile]: {
    fontSize: 18,
    lineHeight: '30px',
    fontWeight: 400,
    width: 345,
    textAlign: 'center',
  },
  [bp.desktop]: {
    fontSize: 20,
    lineHeight: '30px',
    fontWeight: 400,
    width: '100%',
    textAlign: 'center',
  },
  color: COLOR.NEUTRAL[700],
})

const Img = styled.img({
  maxWidth: '400px',
  maxHeight: '600px',
  borderRadius: '16px',
  transform: 'scale',
})
export { WhyExtend }
