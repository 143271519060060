import type { Action } from '../../actions'

export type AuthAccessTokenReducerState = string | null

export const initialState: AuthAccessTokenReducerState = null

export default function accessToken(state = initialState, action: Action): AuthAccessTokenReducerState {
  switch (action.type) {
    case 'USER_LOGIN_SUCCESS':
      return action.payload.accessToken
    case 'USER_LOGOUT':
      return null
    default:
      return state
  }
}
