import { decode } from './decode'
import type { AccessToken, DecodedAccessToken } from '@customers-api-client'

export function decodeToken(token: AccessToken): DecodedAccessToken {
  return decode(token) as DecodedAccessToken
}
export function isValidToken(token?: string | null): boolean {
  if (!token) {
    return false
  }

  try {
    const decoded = decode(token) as DecodedAccessToken
    if (!decoded) {
      return false
    }
    return decoded.at === 'consumer' && !isExpired(decoded.exp)
  } catch (e) {
    return false
  }
}

function isExpired(expiration: number): boolean {
  return Date.now() / 1000 > expiration
}
