import React from 'react'

const PackageIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <mask id="mask0_3096_4121" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3096_4121)">
        <path
          d="M7.33333 14.4833V8.38333L2 5.3V10.65C2 10.8944 2.05833 11.1167 2.175 11.3167C2.29167 11.5167 2.45556 11.6778 2.66667 11.8L7.33333 14.4833ZM8.66667 14.4833L13.3333 11.8C13.5444 11.6778 13.7083 11.5167 13.825 11.3167C13.9417 11.1167 14 10.8944 14 10.65V5.3L8.66667 8.38333V14.4833ZM11.3167 5.31666L13.2833 4.16666L8.66667 1.51666C8.45556 1.39444 8.23333 1.33333 8 1.33333C7.76667 1.33333 7.54444 1.39444 7.33333 1.51666L6.01667 2.26666L11.3167 5.31666ZM8 7.23333L9.98333 6.1L4.7 3.03333L2.7 4.18333L8 7.23333Z"
          fill="#6B7887"
        />
      </g>
    </svg>
  )
}

export default PackageIcon
