import type { FC } from 'react'
import React from 'react'

import { CustomerFormTemplate } from './customer-form-template'
import type { CustomerFormFields, CustomerSubmitDataTypes } from './customer-form-template.types'
import { isSubmitDisabled, formikConfig } from './contract-transfer-form.utils'

interface ContractTransferFormProps {
  className?: string
  initialValues: CustomerFormFields
  ownerEmail?: string
  onSubmit: (data: CustomerSubmitDataTypes) => void
  onCancel: () => void
}

const ContractTransferForm: FC<ContractTransferFormProps> = ({
  className,
  initialValues,
  ownerEmail,
  onSubmit,
  onCancel,
}) => {
  return (
    <CustomerFormTemplate
      dataQa="customers--contract-transfer"
      optionalFields={{ address2: true }}
      submitText="Review transfer"
      {...{
        className,
        onSubmit,
        onCancel,
        initialValues,
        ownerEmail,
        isSubmitDisabled,
        formikConfig,
      }}
    />
  )
}

export { ContractTransferForm }
