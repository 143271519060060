import type { PropsWithChildren } from 'react'
import { useEffect } from 'react'
import * as ReactGA from 'react-ga'
import { useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useUserAnalytics } from '../hooks'
import * as selectors from '../reducers/selectors'
import { LDFlag } from '../constants/ld-flags'

interface AnalyticsWrapperProps {
  initialized: boolean
  children: PropsWithChildren<any>
}

export function AnalyticsWrapper(props: AnalyticsWrapperProps): PropsWithChildren<any> {
  const { [LDFlag.CustomersDisablePendo]: FF_DISABLE_PENDO } = useFlags()
  const location = useLocation()
  const { initialize } = useUserAnalytics()

  const decodedAccessToken = useSelector(selectors.getDecodedAccessToken)

  useEffect(() => {
    if (props.initialized) {
      ReactGA.pageview(location.pathname + location.search)
    }
  }, [props.initialized, location])

  useEffect(() => {
    if (FF_DISABLE_PENDO) return
    const email = decodedAccessToken?.email
    if (email) {
      initialize({ userId: email, email })
    }
  }, [initialize, decodedAccessToken, FF_DISABLE_PENDO])

  return props.children
}
