import React from 'react'

const PackageClaim: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="60" viewBox="0 0 72 60" fill="none">
      <path
        d="M57.1589 29.7617C58.6054 29.1504 59.7388 28.2408 59.7388 28.2408V15.1636L59.5151 14.9399C59.3361 14.6715 56.8159 13.3742 45.4826 7.7974C37.8922 4.05467 31.5097 0.953117 31.3159 0.923294C31.0474 0.893471 28.6316 1.92235 20.2957 5.66508C14.4351 8.30438 7.60528 11.3612 5.12984 12.4497C1.98334 13.8365 0.551756 14.5373 0.328071 14.7759L0 15.1188V29.9407C0 42.6302 0.0298246 44.7923 0.164035 45.0756C0.313159 45.3589 2.28159 46.2834 15.5535 52.2331C23.9194 56.0056 30.8983 59.0773 31.0773 59.0773C31.2562 59.0773 32.0466 58.7642 32.822 58.3914C41.8291 53.9777 45.3633 52.0392 49.4492 50.0113C49.0914 49.2806 48.7335 48.55 48.4352 47.7447L44.7519 49.7578C37.9519 53.083 32.3746 55.8118 32.3597 55.8118C32.3448 55.8118 32.3299 49.9516 32.3299 42.7942L32.3597 29.7766L44.6624 23.7376C51.4326 20.4123 57.0993 17.7432 57.1589 17.7432M19.5351 8.7219C19.5351 8.7219 22.2343 7.529 25.3659 6.12733C28.5124 4.71076 31.122 3.56259 31.1667 3.56259C31.2115 3.56259 36.6992 6.24663 43.3501 9.52711C50.0159 12.8076 55.4738 15.5065 55.5036 15.5364C55.5782 15.6109 44.9159 20.8299 44.722 20.8299C44.5431 20.8299 19.6395 9.02013 19.4904 8.8561C19.4606 8.81137 19.4904 8.76663 19.5351 8.7219ZM29.6606 55.8118C29.6606 55.8118 29.6575 55.8104 29.6514 55.8077C29.3837 55.689 23.3055 52.9935 16.0158 49.728L2.44562 43.6292V30.5968C2.44562 23.4393 2.44562 17.5643 2.47544 17.5643C2.50527 17.5643 8.61932 20.3229 16.1053 23.6779L29.6755 29.7766L29.7053 42.8091C29.7053 49.7303 29.7053 55.4384 29.6514 55.8077C29.6496 55.8203 29.6477 55.8267 29.6457 55.8267L29.6606 55.8118ZM31.0624 27.6145L17.7457 21.6351C10.4386 18.3397 4.44387 15.6109 4.44387 15.5662C4.44387 15.4916 16.0904 10.2429 16.2992 10.2279C16.3737 10.2279 21.8615 12.7927 28.4825 15.939C35.1036 19.1002 40.8299 21.814 41.2027 21.9929L41.8589 22.3061L36.4606 24.9603L31.0773 27.6145H31.0624Z"
        fill="black"
      />
      <path
        d="M60.2585 28.8747L59.3741 28.2697L58.5488 28.9702C55.3061 31.7087 51.9898 32.5047 48.5556 32.3137L47.0375 32.2341L46.9933 33.874C46.8312 40.529 47.8334 45.799 49.8969 49.8271C51.9898 53.887 55.1145 56.5617 58.9762 58.0902L59.4921 58.2971L60.0079 58.0902C64.0022 56.4503 67.1711 53.6481 69.2494 49.5882C71.3128 45.5442 72.2414 40.3539 71.9466 34.0332L71.8729 32.3774L70.34 32.5366C67.6133 32.8072 64.2381 31.6768 60.2438 28.9225L60.2585 28.8747Z"
        fill="#00FFBD"
      />
      <path
        d="M5.76678 25.0263C5.54214 25.3311 5.47974 25.5075 5.47974 25.9406C5.47974 26.2614 5.55462 26.5983 5.65446 26.7587C5.90407 27.1918 11.5951 30.5923 12.0444 30.6083C12.4687 30.6083 12.9554 30.1111 13.0303 29.5817C13.0927 29.1807 12.9055 28.5712 12.6434 28.2825C12.5311 28.1862 11.1832 27.3201 9.6232 26.3737C6.46568 24.4489 6.25352 24.3847 5.7543 25.0103L5.76678 25.0263Z"
        fill="black"
      />
      <path
        d="M6.02761 30.08C5.466 30.4649 5.29127 31.5236 5.7156 32.1171C5.97769 32.502 11.6562 35.9507 12.0182 35.9507C12.4175 35.9507 12.8918 35.5176 12.9791 35.0524C13.079 34.5552 12.9791 33.9938 12.7295 33.689C12.4675 33.3682 6.72651 29.9356 6.45194 29.9356C6.31466 29.9356 6.11497 30.0158 6.01513 30.08H6.02761Z"
        fill="black"
      />
    </svg>
  )
}

export default PackageClaim
