import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'

interface BubbleWrapperProps {
  bot: boolean
  height: number
}

export interface ChatBubbleWrapperProps extends BubbleWrapperProps {}

const ChatBubbleWrapper: FC<ChatBubbleWrapperProps> = ({ children, bot, height }) => (
  <BubbleWrapper bot={bot} height={height}>
    {children}
  </BubbleWrapper>
)

const BubbleWrapper = styled.div<BubbleWrapperProps>(
  {
    overflow: 'hidden',
    display: 'flex',
  },
  (props) => ({
    justifyContent: props.bot ? 'flex-start' : 'flex-end',
    // 10 is subtracted to properly space out from the <HiddenDiv />
    marginBottom: `${10 - props.height}px`,
  }),
)

export default ChatBubbleWrapper
