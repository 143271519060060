const breakpoints = [0, 600, 1024, 1680, 2560, 730]

const bp = {
  mobile: `@media (min-width: ${breakpoints[0]}px)`,
  tablet: `@media (min-width: ${breakpoints[1]}px)`,
  desktop: `@media (min-width: ${breakpoints[2]}px)`,
  desktopMd: `@media (min-width: ${breakpoints[3]}px)`,
  desktopLg: `@media (min-width: ${breakpoints[4]}px)`,
  desktopSm: `@media (min-width: ${breakpoints[5]}px)`,
  maxWidthDesktopSm: `@media (max-width: ${breakpoints[5]}px)`,
}

export { bp, breakpoints }
