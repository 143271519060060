import { createActionFailure } from './factories'
import { decodeToken } from '../lib/security-utils/jwt'

export const login = () =>
  ({
    type: 'USER_LOGIN',
  } as const)

export const loginRequest = () =>
  ({
    type: 'USER_LOGIN_REQUEST',
  } as const)

export const loginSuccess = (accessToken: string) =>
  ({
    type: 'USER_LOGIN_SUCCESS',
    payload: {
      accessToken,
      decodedAccessToken: decodeToken(accessToken),
    },
  } as const)

export const requestConsumerToken = (codeVerifier: string, code: string) =>
  ({
    type: 'CONSUMER_TOKEN_FETCH',
    payload: {
      codeVerifier,
      code,
    },
  } as const)

export const requestConsumerTokenError = createActionFailure('REQUEST_CONSUMER_TOKEN_ERROR')

export const logout = () => ({ type: 'USER_LOGOUT' } as const)

export const errorReset = () =>
  ({
    type: 'USER_AUTH_ERROR_RESET',
  } as const)
