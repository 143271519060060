import type { FC } from 'react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import type { IconSize } from '@extend/zen'
import { Button, COLOR, Icon, Search, Stack } from '@extend/zen'
import { useTheme } from '@emotion/react'

interface EmptyPageContentProps {
  primaryButtonText: string
  primaryButtonUrl: string
  headerText: string
  children: React.ReactNode
}

const EmptyPageContent: FC<EmptyPageContentProps> = ({ primaryButtonText, primaryButtonUrl, headerText, children }) => {
  const history = useHistory()
  const theme = useTheme()
  const handleOnContactSupport = (): void => {
    if (theme.merchantConfiguration.merchantSupportUrl) {
      window.open(theme.merchantConfiguration.merchantSupportUrl, '_blank')
    } else {
      history.push('/contact')
    }
  }
  const handlePrimaryClick = (): void => history.push(primaryButtonUrl)

  return (
    <Container data-cy="empty-page-content">
      <Stack align="center" justify="center" spacing={1}>
        <Icon icon={Search} size={100 as IconSize} data-cy="empty-page-content-icon" />
        <HeaderText data-cy="empty-page-content-header">{headerText}</HeaderText>
        <Content data-cy="empty-page-content-body">{children}</Content>
        <div>
          <Button
            data-cy="empty-page-content-primary"
            onClick={handlePrimaryClick}
            text={primaryButtonText}
            color="neutral"
          />
        </div>
        <div>
          <Button
            data-cy="empty-page-content-contact-support"
            onClick={handleOnContactSupport}
            text="Contact Support"
            color="neutral"
            emphasis="low"
          />
        </div>
      </Stack>
    </Container>
  )
}

const Container = styled.div({
  width: '100%',
  padding: 25,
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: 16,
})

const Content = styled.div({
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  maxWidth: 500,
  marginBottom: 25,
})

const HeaderText = styled.div({
  fontSize: 24,
  fontWeight: 700,
})

export { EmptyPageContent }
