import { CLAIM_FULFILLMENT_EN_CA, CLAIM_FULFILLMENT_EN_US, CLAIM_FULFILLMENT_FR_CA } from './claim-fulfillment'
import { ShippingProtectionFaqAnswer } from './shipping-protection-claim-fulfillment'

const FAQ_TEXT = {
  'en-US': { title: 'Frequently Asked Questions', claimFulfillment: CLAIM_FULFILLMENT_EN_US },
  'en-CA': { title: 'Frequently Asked Questions', claimFulfillment: CLAIM_FULFILLMENT_EN_CA },
  'fr-CA': { title: 'Foire aux questions', claimFulfillment: CLAIM_FULFILLMENT_FR_CA },
}

export { FAQ_TEXT, ShippingProtectionFaqAnswer }
