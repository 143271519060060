import React, { useState } from 'react'
import type { FC } from 'react'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import {
  COLOR,
  HeadingSmall,
  Button,
  DataProperty,
  Error,
  Badge,
  Check,
  ModalController,
  ButtonGroup,
  Input,
  InputType,
} from '@extend/zen'
import { ContentLayout, bp } from '@customers-ui'
import { useSelector } from 'react-redux'
import { useFlags } from 'launchdarkly-react-client-sdk'
import * as selectors from '../../reducers/selectors'
import { getAccountInfo } from '../../lib/consumer-profile-utils'
import { logEvent } from '../../analytics'
import { LDFlag } from '../../constants/ld-flags'
import type { UserInfo } from './verification-modal/verification-modal'
import { VerificationModal } from './verification-modal/verification-modal'
import { useFormik } from 'formik'
import { schema } from './schema'
import { formatPhoneNumberOnChange } from '@extend/client-helpers'

const UserProfilePage: FC = () => {
  const history = useHistory()
  const { [LDFlag.ProfileEditing]: FF_PROFILE_EDITING_ENABLED } = useFlags()
  const [verificationType, setVerificationType] = useState<'off' | 'phone' | 'email'>('off')
  const [isEditing, setIsEditing] = useState(false)
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined)
  const consumerProfile = useSelector(selectors.getConsumerProfile)
  const accountInfo = getAccountInfo(consumerProfile)

  const { isPhoneNumberVerified, isEmailVerified, name, phoneNumber, email, shippingAddress, hasAccountInfo } =
    accountInfo

  const decodedToken = useSelector(selectors.getDecodedAccessToken)
  const isEmailLogin = decodedToken?.cid === 'consumerPortalViaEmailCode'

  const routeToPlans = (): void => {
    logEvent('Claims - Clicks', 'View My Plans')
    history.push('/my_plans')
  }

  const handleVerifyClick = (isEmail: boolean): void => {
    setVerificationType(isEmail ? 'email' : 'phone')
  }

  const { values, errors, touched, handleChange, handleBlur, setFieldValue, handleSubmit, resetForm } = useFormik({
    validationSchema: schema,
    initialValues: {
      name: name ?? '',
      phoneNumber: phoneNumber ?? '',
      email: email ?? '',
      shippingAddress: shippingAddress ?? '',
    },
    onSubmit: (submittedValues) => {
      setUserInfo({
        email: isEmailLogin ? '' : submittedValues.email,
        phoneNumber: isEmailLogin ? submittedValues.phoneNumber : '',
      })
      setVerificationType(isEmailLogin ? 'phone' : 'email')
    },
  })

  const handleCompleteVerification = (): void => {
    setVerificationType('off')
    setUserInfo(undefined)
    setIsEditing(false)
  }

  const handleFormCancelClick = (): void => {
    setUserInfo(undefined)
    setIsEditing(false)
    resetForm()
  }

  // Show verify text if there is no existing accountInfo value for email or phone and one was entered or
  // an existing value exists and the value entered is different
  const isVerificationNeeded =
    (!email && values.email) ||
    (email && email !== values.email) ||
    (!phoneNumber && values.phoneNumber) ||
    (phoneNumber && phoneNumber !== values.phoneNumber)

  return (
    <>
      <ModalController isOpen={verificationType !== 'off'}>
        <VerificationModal
          isEditVerification={isEditing}
          userInfo={userInfo}
          isEmail={verificationType === 'email'}
          handleComplete={handleCompleteVerification}
          handleClose={() => setVerificationType('off')}
        />
      </ModalController>
      <ContentLayout pageTitle="My Account">
        <ProfilePageWrapper>
          <ProfilePageContent>
            <AccountHeader>My Account</AccountHeader>
            {hasAccountInfo && (
              <SectionWrapper>
                <SmallHeading>Contact Information</SmallHeading>
                {isEditing ? (
                  <>
                    <Input
                      id="name"
                      data-cy="account-name-input"
                      label="Name"
                      value={values.name}
                      onChange={handleChange}
                      isError={Boolean(errors.name && touched.name)}
                      errorFeedback={errors.name}
                      onBlur={handleBlur}
                    />
                    <Input
                      id="phoneNumber"
                      data-cy="account-phone-number-input"
                      label="Phone Number"
                      isDisabled={!isEmailLogin}
                      value={
                        values.phoneNumber.length > 4
                          ? formatPhoneNumberOnChange(values.phoneNumber, 'US')
                          : values.phoneNumber
                      }
                      type={InputType.tel}
                      onChange={(e) => {
                        const { value } = e.target

                        setFieldValue('phoneNumber', value)
                      }}
                      isError={Boolean(errors.phoneNumber && touched.phoneNumber)}
                      errorFeedback={errors.phoneNumber}
                      onBlur={handleBlur}
                    />
                    <Input
                      id="email"
                      data-cy="account-email-input"
                      isDisabled={isEmailLogin}
                      label="Email"
                      value={values.email}
                      onChange={handleChange}
                      isError={Boolean(errors.email && touched.email)}
                      errorFeedback={errors.email}
                      onBlur={handleBlur}
                    />
                    <Input
                      id="shippingAddress"
                      data-cy="account-shipping-address-input"
                      label="Shipping Address"
                      value={values.shippingAddress}
                      onChange={handleChange}
                      isError={Boolean(errors.shippingAddress && touched.shippingAddress)}
                      errorFeedback={errors.shippingAddress as string}
                      onBlur={handleBlur}
                    />
                    <ButtonGroup justify="space-between">
                      <Button emphasis="low" text="Cancel" data-cy="cancel-button" onClick={handleFormCancelClick} />
                      <Button
                        emphasis="high"
                        text={`${isVerificationNeeded ? 'Verify & ' : ''}Save`}
                        data-cy="save-button"
                        onClick={() => handleSubmit()}
                      />
                    </ButtonGroup>
                  </>
                ) : (
                  <>
                    <DataProperty data-cy="account-name" label="Name" value={accountInfo.name} />
                    <VerifiablePropertyWrapper>
                      <label htmlFor="phone">Phone Number</label>
                      {
                        <VerifiablePropertyData id="phone" data-cy="account-phone">
                          {accountInfo.phoneNumber && <p>{accountInfo?.phoneNumber}</p>}
                          {isPhoneNumberVerified ? (
                            <Badge icon={Check} color="green" text="Verified" data-cy="verified-phone" />
                          ) : (
                            <UnverifiedWrapper>
                              <Badge icon={Error} color="red" text="Unverified" data-cy="unverified-phone" />
                              <Button
                                onClick={() => handleVerifyClick(false)}
                                text="Verify"
                                size="small"
                                data-cy="verify-phone-button"
                              />
                            </UnverifiedWrapper>
                          )}
                        </VerifiablePropertyData>
                      }
                    </VerifiablePropertyWrapper>
                    <VerifiablePropertyWrapper>
                      <label htmlFor="email">Email</label>
                      {accountInfo ? (
                        <VerifiablePropertyData id="email" data-cy="account-email">
                          {accountInfo.email && <p>{accountInfo?.email}</p>}
                          {isEmailVerified ? (
                            <Badge icon={Check} color="green" text="Verified" data-cy="verified-email" />
                          ) : (
                            <UnverifiedWrapper>
                              <Badge icon={Error} color="red" text="Unverified" data-cy="unverified-email" />
                              <Button
                                onClick={() => handleVerifyClick(true)}
                                text="Verify"
                                size="small"
                                data-cy="verify-email-button"
                              />
                            </UnverifiedWrapper>
                          )}
                        </VerifiablePropertyData>
                      ) : (
                        <VerifiablePropertyData id="email" data-cy="account-email">
                          {''}
                          {/* Empty string as accountInfo (and hence accountInfo.email) does not exist */}
                        </VerifiablePropertyData>
                      )}
                    </VerifiablePropertyWrapper>
                    <DataProperty
                      data-cy="account-shipping-address"
                      label="Shipping Address"
                      value={accountInfo.shippingAddress}
                    />
                    {FF_PROFILE_EDITING_ENABLED && (
                      <ButtonWrapper>
                        <Button
                          emphasis="medium"
                          text="Edit"
                          data-cy="edit-button"
                          onClick={() => setIsEditing(true)}
                        />
                      </ButtonWrapper>
                    )}
                  </>
                )}
              </SectionWrapper>
            )}
            <ButtonWrapper>
              <Button emphasis="low" data-cy="view-my-plans-button" onClick={routeToPlans} text="View My Plans" />
            </ButtonWrapper>
          </ProfilePageContent>
        </ProfilePageWrapper>
      </ContentLayout>
    </>
  )
}

const UnverifiedWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
})

const VerifiablePropertyWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  marginBottom: 8,
  label: {
    lineHeight: '24px',
    cursor: 'text',
    color: COLOR.NEUTRAL[600],
    fontSize: 14,
    fontWeight: 700,
  },
})

const VerifiablePropertyData = styled.div({
  p: {
    overflow: 'hidden',
  },
  display: 'flex',
  flexDirection: 'row',
  lineHeight: '24px',
  alignItems: 'center',
  gap: 4,
})

const ProfilePageWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

const ProfilePageContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  [bp.mobile]: {
    margin: '32px 10px 40px',
    maxWidth: '95vw',
  },
  [bp.desktop]: {
    margin: '56px 156px 24px',
    width: '1134px',
  },
})

const AccountHeader = styled.div({
  fontWeight: 700,
  [bp.desktop]: {
    fontSize: '28px',
    lineHeight: '36px',
  },
  [bp.mobile]: {
    fontSize: '20px',
    lineHeight: '24px',
  },
})

const SmallHeading = styled(HeadingSmall)({
  [bp.mobile]: {
    fontSize: '17px',
    lineHeight: '24px',
  },
  [bp.desktop]: {
    fontSize: '20px',
    lineHeight: '28px',
  },
})

const SectionWrapper = styled.div({
  borderRadius: '16px',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  display: 'flex',
  padding: '16px',
  flexDirection: 'column',
  gap: 'var(--field-border-radius, 8px)',
})

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

export { UserProfilePage }
