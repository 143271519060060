import React from 'react'
import { SplashScreen } from '../../../components/common'
import type { FC } from 'react'
import { useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { querystring, customLogger } from '@extend/client-helpers'
import { ContentLayout } from '@customers-ui'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  useLazyGetContentQuery,
  useFetchPlanQuery,
  useFetchPlanDetailsQuery,
  useGetContractQuery,
  getRTKQueryErrorMessage,
} from '@customers-api-rtk-query'
import { useIntl } from 'react-intl'
import { LDFlag } from '../../../constants/ld-flags'

import { ResourceErrorBoundary } from '../../../components/resource-error-boundary'
import { WarrantyTermsMain } from '../components'
import { usePlanDetailsData } from '../../../hooks/use-plan-details-data'
import { usePlanTermsData } from '../../../hooks/use-plan-terms-data'

const DEFAULT_PLAN_ID = '10001-misc-elec-base-replace-1y'
const LAZY_FETCH_DEFAULT_PLAN = false

const WarrantyTerms: FC = () => {
  const { search } = useLocation()
  const { locale } = useIntl()
  const { id: planId = DEFAULT_PLAN_ID } = useParams<{ id: string; storeId: string }>()
  const { token, accessToken, contractId } = querystring.parse<{
    token?: string
    accessToken?: string
    contractId?: string
  }>(search)

  const fetchToken = accessToken ?? token // legacy support for redirect links (with "?token=..." param)
  const {
    data: contract,
    isLoading: isContractLoading,
    isError: isGetContractError,
    error: getContractError,
  } = useGetContractQuery(
    {
      contractId: contractId ?? '', // request is skipped below if contractId does not exist
      accessToken: fetchToken,
    },
    { skip: !contractId },
  )

  // const contract = data || null
  const query = useMemo(() => new URLSearchParams(search), [search])
  const storeId = query.get('storeId')

  const flags = useFlags()
  const isPlanDetailsCCSFetchingEnabled = flags[LDFlag.PlanDetailsCCSFetch]

  /*
    determines if the URL param has neither the plan ID
    nor the contract ID + token provided, implying that
    the page is visited via {{URL}}/warranty_terms and
    is directed to view the default plan messages
  */
  const isDefaultPlan = !contractId && (planId === undefined || planId === '')
  /*
    fetches the plan ID (or the default plan ID, if blank)
    provided in the URL params if the contract ID is not
    included the URL querystring params
  */
  const fetchPlanId = (contractId ? contract?.plan.planId : planId) ?? DEFAULT_PLAN_ID

  const {
    data: plan,
    isLoading: isPlanLoading,
    isError: isFetchPlanError,
    error: fetchPlanError,
  } = useFetchPlanQuery(fetchPlanId)
  const {
    data: planDetailsDefault,
    isLoading: isPlanDetailsLoading,
    isError: isFetchPlanDetailsError,
    error: fetchPlanDetailsError,
  } = useFetchPlanDetailsQuery(fetchPlanId)

  // contract, but also contract.plan
  const { terms, error: hasPlanTermsError, isLoading: isPlanTermsLoading } = usePlanTermsData({ contract, plan })

  // conditionally executed /content/offers query
  const [
    getContentFn,
    {
      isError: isErrorContent,
      isLoading: isLoadingContent,
      isFetching: isFetchingContent,
      data: contentQueryData,
      error: getContentError,
    },
  ] = useLazyGetContentQuery()

  const isWarrantyTermsDataLoaded = contractId
    ? Boolean(contract && plan && planDetailsDefault)
    : Boolean(plan && planDetailsDefault)

  const { planDetails, isErrored } = usePlanDetailsData({
    isErrored: false,
    isLoading: !isWarrantyTermsDataLoaded,
    storeId: storeId || '',
    plan: plan ?? undefined,
    locale,
    isPlanDetailsCCSFetchingEnabled,
    getContentFn,
    getContentState: {
      isError: isErrorContent,
      isLoading: isLoadingContent,
      isFetching: isFetchingContent,
      contentQueryData,
    },
    planDetailsQueryData: planDetailsDefault ?? undefined,
    isLazyPlanDetailsFetch: LAZY_FETCH_DEFAULT_PLAN,
  })

  // Error logging for requests
  const [hasLoggedError, setHasLoggedError] = useState(false)
  const queryError = fetchPlanError || fetchPlanDetailsError || getContentError || hasPlanTermsError || getContractError

  if (queryError && !hasLoggedError) {
    const errorMessage = getRTKQueryErrorMessage(queryError)

    customLogger.warn(`[Err: Warranty Terms Page]: ${errorMessage}`, {
      isFetchPlanError,
      isFetchPlanDetailsError,
      isGetContractError,
      hasPlanTermsError: hasPlanTermsError ? 'true' : 'false',
      contractId,
      planId,
    })

    setHasLoggedError(true)
  }

  if (
    isErrored ||
    !isWarrantyTermsDataLoaded ||
    !planDetails ||
    isPlanTermsLoading ||
    hasPlanTermsError ||
    isLoadingContent ||
    isErrorContent ||
    isFetchingContent ||
    isPlanLoading ||
    isPlanDetailsLoading ||
    isContractLoading
  ) {
    return (
      <ResourceErrorBoundary>
        <ContentLayout>
          <SplashScreen data-cy="splash-screen" />
        </ContentLayout>
      </ResourceErrorBoundary>
    )
  }

  return (
    <ContentLayout>
      {plan && planDetails && (
        <WarrantyTermsMain isDefault={isDefaultPlan} {...{ contract, plan, planDetails, terms }} />
      )}
    </ContentLayout>
  )
}

export { WarrantyTerms }
