import type { FC, CSSProperties } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import { Grid, Card } from '@customers-ui'

import { bp } from '../../utils/breakpoints'
import { svgs } from '../../svgs'

const { InfoCircleIcon } = svgs

type BannerKind = 'info' | 'warning'

interface InfoBannerProps {
  className?: string
  kind?: BannerKind
}

const InfoBanner: FC<InfoBannerProps> = ({ children, className, kind = 'info' }) => {
  return (
    <StyledCard {...{ className, kind }}>
      <StyledGrid>
        <InfoIconWrapper kind={kind}>
          <InfoCircleIcon />
        </InfoIconWrapper>
        <InfoText>{children}</InfoText>
      </StyledGrid>
    </StyledCard>
  )
}

const StyledCard = styled(Card)<{ kind: BannerKind }>(({ kind }) => ({
  borderRadius: 6,
  [bp.mobile]: {
    padding: 16,
  },
  [bp.desktop]: {
    padding: 16,
  },
  ...kindStyles(kind),
}))

const StyledGrid = styled(Grid)({
  gap: 8,
})

const InfoIconWrapper = styled.div<{ kind: BannerKind }>(({ kind }) => ({
  '& > svg': {
    height: 20,
    width: 20,
  },
  '& > svg g': {
    stroke: kind === 'info' ? COLOR.BLUE[800] : COLOR.YELLOW[800],
  },
}))

const InfoText = styled.div({
  fontSize: 15,
  lineHeight: '20px',
})

const kindStyles = (kind: BannerKind): CSSProperties => {
  switch (kind) {
    case 'warning':
      return {
        background: COLOR.YELLOW[100],
        border: `1px solid ${COLOR.YELLOW[300]}`,
        color: COLOR.YELLOW[800],
      }
    case 'info':
    default:
      return {
        background: COLOR.BLUE[100],
        border: `1px solid ${COLOR.BLUE[300]}`,
        color: COLOR.BLUE[800],
      }
  }
}

export { InfoBanner }
