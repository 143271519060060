import type { ConsumerProfileResponse } from '@customers-api-rtk-query'
import { formatPhoneNumberLocal } from '@extend/client-helpers'
import { formatAddress } from '../utils/format-address'

type ConsumerProfileContacts = ConsumerProfileResponse['emails'] | ConsumerProfileResponse['phones']
type ConsumerProfileContact = ConsumerProfileContacts[0]

export interface AccountInfo {
  name: string
  phoneNumber: string
  email: string
  shippingAddress: ConsumerProfileResponse['addresses'][0]
  isPhoneNumberVerified: boolean
  isEmailVerified: boolean
  hasAccountInfo: boolean
}

export const getAccountInfo = (consumerProfile: ConsumerProfileResponse | null): AccountInfo => {
  if (!consumerProfile) {
    return {
      name: '',
      phoneNumber: '',
      email: '',
      shippingAddress: 'None',
      isPhoneNumberVerified: false,
      isEmailVerified: false,
      hasAccountInfo: false,
    }
  }

  const { emails, phones, name } = consumerProfile
  const email = getContact(emails)
  const phoneNumber = getContact(phones)

  return {
    name,
    phoneNumber: formatPhoneNumberLocal(phoneNumber),
    email,
    shippingAddress: formatAddress(getShippingAddress(consumerProfile)),
    isPhoneNumberVerified: (phones && phones[phoneNumber]?.isVerified) || false,
    isEmailVerified: (emails && emails[email]?.isVerified) || false,
    hasAccountInfo: Boolean(email || phoneNumber || name),
  }
}

const getContact = (contacts: ConsumerProfileContacts): string => {
  if (!contacts || Object.keys(contacts).length < 1) {
    return ''
  }

  // map phone numbers and emails onto the contact object
  const mappedContacts = Object.entries(contacts).map(([contactKey, contactValues]) => ({
    contactKey,
    ...contactValues,
  }))

  // return primary contact if it exists
  const primaryContact = mappedContacts.find((contact: ConsumerProfileContact) => contact.isPrimary)
  if (primaryContact) {
    return primaryContact.contactKey
  }

  // sort by most recently verified
  const mostRecentVerified = mappedContacts
    .filter((mappedContract) => mappedContract.isVerified)
    .sort((a: ConsumerProfileContact, b: ConsumerProfileContact) => b.verifiedAt - a.verifiedAt)[0]

  // return most recently verified contact, fall back to first contact
  return mostRecentVerified?.contactKey || mappedContacts[0]?.contactKey
}

export const getShippingAddress = (
  consumerProfile: ConsumerProfileResponse,
): ConsumerProfileResponse['addresses'][0] => {
  return consumerProfile.addresses?.find((address) => address.type === 'shipping')
}
