import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { bp } from '@extend/client-helpers'
import { COLOR } from '@customers-ui'
import type { V2 } from '@extend-services/service-orders'
import type { FAQ } from '../../../types/faq'
import images from '../../../lib/images'
import type { Locale } from '../../../types/localization'
import { FAQ_TEXT } from '../../../lib/faqs'

interface FaqSectionProps {
  fulfillmentType: 'repair' | V2.Models.ReplacementFulfillmentMethod
  locale?: Locale
}

const FaqSection: FC<FaqSectionProps> = ({ fulfillmentType, locale = 'en-US' }) => {
  const { claimFulfillment, title } = FAQ_TEXT[locale]
  /**
   *  The claim fulfillment page is only used for repair, and virtual_card so we don't
   *  need FAQs for 'manual'.
   */
  const faqList: FAQ[] = claimFulfillment[fulfillmentType]

  return (
    <Section>
      <MainHeading>{title}</MainHeading>
      <FaqList>
        {faqList.map((faq) => {
          return (
            <FaqItem key={faq.question}>
              <Question data-cy={faq.question}>{faq.question}</Question>
              <KaleyChatWrapper>
                <Kaley alt="Kaley" src={images.kaleyLarge} />
                <ChatBubble data-cy={faq.answer}>{faq.answer}</ChatBubble>
              </KaleyChatWrapper>
            </FaqItem>
          )
        })}
      </FaqList>
    </Section>
  )
}

const Question = styled.h2({
  fontSize: 20,
  margin: 0,
  [bp.md]: {
    fontSize: 26,
  },
})

const MainHeading = styled.h1({
  margin: 0,
  fontSize: 26,
  lineHeight: '35px',
  textAlign: 'center',
  [bp.md]: {
    lineHeight: '45px',
    fontSize: 33,
  },
})

const Section = styled.section({
  marginTop: 32,
  borderBottom: `1px solid ${COLOR.NEUTRAL[200]}`,
  [bp.md]: {
    marginTop: 72,
    padding: '0 50px 40px',
  },
  // TODO: ask design about odd breakpoint
  '@media (min-width: 1680px)': {
    padding: '0 156px',
  },
})

const FaqList = styled.ul({
  listStyleType: 'none',
  margin: '32px 0 0 0',
  padding: 0,
  [bp.md]: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
})

const FaqItem = styled.li({
  [bp.md]: {
    width: 'calc(50% - 25px)',
    '& h2': {
      transform: 'translateX(50px)',
      paddingRight: 50,
    },
  },
  [bp.xl]: {
    width: 'calc(50% - 45px)',
  },
  // TODO: ask design about odd breakpoint
  '@media (min-width: 1680px)': {
    width: 'calc(50% - 107px)',
  },
})

const KaleyChatWrapper = styled.div({
  display: 'flex',
  marginTop: 16,
  marginBottom: 40,
  [bp.md]: {
    marginBottom: 64,
  },
})

const Kaley = styled.img({
  width: 39,
  alignSelf: 'flex-end',
})

const ChatBubble = styled.p({
  backgroundColor: COLOR.NEUTRAL[100],
  borderRadius: '16px 16px 16px 2px',
  padding: 16,
  margin: '0 0 0 8px',
  width: '100%',
})

export type { FaqSectionProps }
export { FaqSection }
