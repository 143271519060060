import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR, Text } from '@customers-ui'
import type { PlanDetails } from '@customers-api-client'

import { images } from '../../../../lib/assets'

export interface MainHeaderProps {
  planDetails: PlanDetails
}

const MainHeader: FC<MainHeaderProps> = ({ planDetails }) => {
  return (
    <>
      <LogoImg src={images.extendIcon} alt="icon" />
      <Header as="h1">{planDetails.planTerms.planFullName}</Header>
    </>
  )
}

const Header = styled(Text)({
  color: COLOR.BLUE[700],
  fontSize: 26,
  fontWeight: 800,
  textAlign: 'center',
  '@media(min-width: 1440px)': {
    fontSize: 36,
    lineHeight: '49px',
  },
})

const LogoImg = styled.img({
  width: '53px',
  height: '52px',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
})

export { MainHeader }
