import React from 'react'
import styled from '@emotion/styled'
import type { TrackingRequest, TrackingResponse } from '@extend-myextend/types'
import type { TimelineItemStatus } from '@extend/zen'
import { Button, COLOR, ContentCopy, Timeline, TimelineItem } from '@extend/zen'
import { formatDate } from '../date-formatting'
import WismoEvents from './wismo-events'
import { bp } from '@customers-ui'
import { useHistory } from 'react-router'
import { useIntl } from 'react-intl'

interface WismoContentProps {
  trackingData: TrackingResponse
  carrierCode: TrackingRequest['carrierCode']
}

const WismoContent: React.FC<WismoContentProps> = ({ trackingData, carrierCode }) => {
  const hasShipped = !!trackingData.shipDate
  const { locale } = useIntl()
  const history = useHistory()
  const hasOutForDelivery = trackingData.events.some((event) =>
    event.description.toLowerCase().includes('out for delivery'),
  )
  const hasDelivered = !!trackingData.actualDeliveryDate

  const shouldUseShipped = hasShipped && !hasOutForDelivery && !hasDelivered
  const shouldUseOutForDelivery = hasOutForDelivery && !hasDelivered

  const shippedTimelineState = (): TimelineItemStatus => {
    if (shouldUseShipped) {
      return 'current'
    } else if (hasShipped) {
      return 'complete'
    } else {
      return 'upcoming'
    }
  }
  const outForDeliveryTimelineState = (): TimelineItemStatus => {
    if (shouldUseOutForDelivery) {
      return 'current'
    } else if (hasOutForDelivery || hasDelivered) {
      return 'complete'
    } else {
      return 'upcoming'
    }
  }

  const mapCarrierName = (carrierCode: TrackingRequest['carrierCode']): string => {
    switch (carrierCode) {
      case 'fedex':
        return 'FedEx'
      case 'ups':
        return 'UPS'
      case 'dhl_global_mail':
        return 'DHL Global Mail'
      case 'usps':
        return 'USPS'
      default:
        return 'Carrier'
    }
  }

  const getTrackingUrlForCarrier = (carrierCode: TrackingRequest['carrierCode'], trackingNumber: string): string => {
    switch (carrierCode) {
      case 'fedex':
        return `https://www.fedex.com/apps/fedextrack/?tracknumbers=${trackingNumber}`
      case 'ups':
        return `https://www.ups.com/track?tracknum=${trackingNumber}`
      case 'dhl_global_mail':
        return `https://webtrack.dhlglobalmail.com/?trackingnumber=${trackingNumber}`
      case 'usps':
        return `https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=${trackingNumber}`
      default:
        return ''
    }
  }

  const getEstimatedDeliveryText = (): string => {
    if (hasDelivered) {
      return `Delivered ${formatDate(trackingData.actualDeliveryDate, true)}`
    }
    if (hasOutForDelivery && trackingData.estimatedDeliveryDate) {
      return 'Out for delivery, arriving today!'
    }
    if (trackingData.estimatedDeliveryDate && !hasDelivered) {
      return `Arriving ${formatDate(trackingData.estimatedDeliveryDate, true)}`
    }
    return 'Awaiting carrier information'
  }

  const getSloganText = (): string => {
    if (hasDelivered || (hasOutForDelivery && trackingData.estimatedDeliveryDate)) {
      return 'The day is about to get better.'
    }
    return 'We can’t wait, either.'
  }

  const handleProblemWithOrderClick = () => {
    const loginMethods = trackingData.orderMetadata.loginMethods.join(',')
    history.push(`/${locale}/authentication${loginMethods.length > 0 ? `?loginMethods=${loginMethods}` : ''}`)
  }

  return (
    <WismoContainer>
      <Title>Shipment Tracking</Title>
      <WismoContainerContents>
        <WismoContainerHeader>
          <WismoContainerHeaderText>
            <OrderText data-cy="transaction-id">{`ORDER # ${trackingData.transactionId}`}</OrderText>
            <EstimatedDeliveryText data-cy="estimated-delivery-text">
              {getEstimatedDeliveryText()}
            </EstimatedDeliveryText>
            <CantWaitText data-cy="slogan">{getSloganText()}</CantWaitText>
          </WismoContainerHeaderText>
          <ProblemWithOrderBtnWrapper>
            <Button
              text="Problems with order"
              emphasis="medium"
              color="neutral"
              onClick={handleProblemWithOrderClick}
            />
          </ProblemWithOrderBtnWrapper>
        </WismoContainerHeader>
        <WismoContainerBody>
          <Timeline>
            <TimelineItem
              data-cy="delivered-timeline"
              label={hasDelivered ? <CurrentTimelineItemLabel>Delivered</CurrentTimelineItemLabel> : 'Delivered'}
              state={hasDelivered ? 'current' : 'upcoming'}
            >
              {hasDelivered && <WismoEvents trackingData={trackingData} />}
            </TimelineItem>
            <TimelineItem
              data-cy="out-for-delivery-timeline"
              label={
                shouldUseOutForDelivery ? (
                  <CurrentTimelineItemLabel>Out for delivery</CurrentTimelineItemLabel>
                ) : (
                  'Out for delivery'
                )
              }
              state={outForDeliveryTimelineState()}
            >
              {shouldUseOutForDelivery && <WismoEvents trackingData={trackingData} />}
            </TimelineItem>
            <TimelineItem
              data-cy="shipped-timeline"
              label={shouldUseShipped ? <CurrentTimelineItemLabel>Shipped</CurrentTimelineItemLabel> : 'Shipped'}
              state={shippedTimelineState()}
            >
              {shouldUseShipped && <WismoEvents trackingData={trackingData} />}
            </TimelineItem>
            <TimelineItem
              data-cy="order-placed-timeline"
              label={hasShipped ? 'Order placed' : <CurrentTimelineItemLabel>Order placed</CurrentTimelineItemLabel>}
              state={!hasShipped ? 'current' : 'complete'}
            />
          </Timeline>
          <TrackingUrlFooter>
            <TrackingUrlTextContainer>
              <TrackingViaText data-cy="tracking-via-carrier">
                Tracking via {mapCarrierName(carrierCode)}:
              </TrackingViaText>
              <TrackingNumberLinkContainer>
                <TrackingNumberLink
                  data-cy="tracking-number-link"
                  href={getTrackingUrlForCarrier(carrierCode, trackingData.trackingNumber)}
                  target="_blank"
                >
                  {trackingData.trackingNumber}
                </TrackingNumberLink>
                <Button
                  icon={ContentCopy}
                  emphasis="low"
                  color="neutral"
                  size="xsmall"
                  onClick={() => navigator.clipboard.writeText(trackingData.trackingNumber)}
                />
              </TrackingNumberLinkContainer>
            </TrackingUrlTextContainer>
          </TrackingUrlFooter>
        </WismoContainerBody>
      </WismoContainerContents>
    </WismoContainer>
  )
}

const CurrentTimelineItemLabel = styled.span({
  color: COLOR.BLACK,
  fontSize: 20,
  fontWeight: 700,
  lineHeight: '28px',
})

const WismoContainer = styled.div({
  fontFamily: 'Nunito Sans',
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  maxWidth: 1128,
  minWidth: 340,
  width: '100%',
  [bp.mobile]: {
    padding: 8,
  },
})

const WismoContainerContents = styled.div({
  borderRadius: 16,
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
})

const WismoContainerHeader = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  padding: 24,
  alignItems: 'center',
  gap: 16,
  borderBottom: `1px solid ${COLOR.NEUTRAL[300]}`,
})

const WismoContainerHeaderText = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: 8,
  flex: '1 0 0',
  color: COLOR.NEUTRAL[1000],
  minWidth: 340,
})

const OrderText = styled.div({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '18px',
  textTransform: 'uppercase',
})

const EstimatedDeliveryText = styled.div({
  fontSize: 24,
  fontWeight: 700,
  lineHeight: '32px',
})

const CantWaitText = styled.div({
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '24px',
})

const WismoContainerBody = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: 24,
  gap: 16,
})

const Title = styled.div({
  fontSize: 24,
  fontWeight: 700,
  lineHeight: '32px',
})

const ProblemWithOrderBtnWrapper = styled.div({
  alignSelf: 'center',
})

const TrackingUrlFooter = styled.div({
  display: 'flex',
  paddingTop: 16,
  gap: 'var(--badge-border-radius, 4px)',
  flex: '1 0 0',
  borderTop: `1px solid ${COLOR.NEUTRAL[300]}`,
})

const TrackingUrlTextContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 'var(--badge-border-radius, 4px)',
})

const TrackingViaText = styled.div({
  color: COLOR.BLACK,
  fontSize: 16,
  fontWeight: 700,
  lineHeight: '24px',
})

const TrackingNumberLinkContainer = styled.div({
  display: 'flex',
  gap: 4,
})

const TrackingNumberLink = styled.a({
  color: COLOR.BLACK,
  fontSize: 16,
  lineHeight: '24px',
  textDecorationLine: 'underline',
  paddingBottom: 4,
})

export default WismoContent
