import type { FC } from 'react'
import React from 'react'
import { Paragraph } from '@extend/zen'
import styled from '@emotion/styled'

const AgreementLink: FC<{ href: string }> = ({ href }) => (
  <a target="_blank" rel="noreferrer" href={href}>
    {href}
  </a>
)

const BulletList = styled.ul({
  marginLeft: 40,
})

export const USCardholderAgreement: FC = () => (
  <>
    <Paragraph>
      <strong>E-Sign disclosure</strong>
    </Paragraph>
    <br />
    <Paragraph>
      <strong>Please read this document and print a copy for your reference.</strong>
    </Paragraph>
    <br />
    <Paragraph>
      <strong>
        Note: This Electronic Communication Disclosure (“Disclosure”) applies to any and all communications or
        disclosures that we are legally required to provide to you in writing in connection with your Extend Card
        (“Card”) and any related products and services (“Communications”).
      </strong>
    </Paragraph>
    <br />
    <Paragraph>
      This Disclosure supplements and is to be construed in accordance with the terms contained in the Cardholder
      Agreement (“<strong>Agreement</strong>”) you received from Pathward. The words &quot;<strong>we</strong>,&quot;
      &quot;<strong>us</strong>,&quot; and &quot;
      <strong>our</strong>&quot; refer to Pathward, the issuer of your Card. The words &quot;
      <strong>you</strong>&quot; and &quot;<strong>your</strong>&quot; mean you, the individual(s) identified on the
      Account. As used in this Disclosure, &quot;
      <strong>Account</strong>&quot; means the Card Account you have with us.
    </Paragraph>
    <br />
    <Paragraph>
      <strong>1. Scope of Communications to Be Provided in Electronic Form.</strong> When you use a product or service
      to which this Disclosure applies, you agree that we may provide you with any Communications in electronic format,
      and that we may discontinue sending paper Communications to you,{' '}
      <strong>unless and until you withdraw your consent as described below</strong>. Your consent to receive electronic
      Communications and transactions includes, but is not limited to:
      <BulletList>
        <li>
          All legal and regulatory disclosures and Communications associated with the Card and any related products and
          services;
        </li>
        <li>Your Cardholder Agreement;</li>
        <li>Notices about a change in the terms of your Cardholder Agreement;</li>
        <li>Privacy policies and notices;</li>
        <li>Error Resolution policies and notices;</li>
        <li>Responses to claims filed in connection with your Card Account; and</li>
        <li>Notices regarding insufficient funds or negative balances</li>
      </BulletList>
    </Paragraph>
    <br />
    <Paragraph>
      <strong>2. Method of Providing Communications to You in Electronic Form.</strong>The primary delivery method for
      all Communications that we provide to you in electronic form will be posting them in the Virtual Card Fulfillment
      Page on the Extend website. Communications may also be delivered to you at the primary email address listed in
      your Extend profile. Communications may also be delivered through other electronic communication methods such as
      by text message.
    </Paragraph>
    <br />
    <Paragraph>
      <strong>3. How to Withdraw Consent.</strong> You may withdraw your consent to receive Communications in electronic
      form at any time by calling Customer Service at
      <a href="tel:+18772487707">1-877-248-7707</a>, or by emailing{' '}
      <a href="mailto:warrantyops@extend.com">warrantyops@extend.com</a> or by filing a support ticket at{' '}
      <AgreementLink href="https://customers.extend.com/en-US/contact" />. If you do withdraw consent, Extend reserves
      the right to immediately close your Account. We will not impose any fee to process the withdrawal of your consent
      to receive electronic Communications. Any withdrawal of your consent to receive electronic Communications will be
      effective only after we have a reasonable period of time to process your withdrawal. In the meantime, you will
      continue to receive Communications in electronic form. If you withdraw your consent, the legal validity and
      enforceability of prior Communications delivered to you in electronic form will not be affected.
    </Paragraph>
    <br />
    <Paragraph>
      <strong>4. How to Update Your Records.</strong> It is your responsibility to provide us with a true, accurate and
      complete e-mail address, your remaining contact information, and other information related to this Disclosure and
      your Account, and to maintain and update promptly any changes in this information. You can update information
      (such as your e-mail address) by contacting customer service by calling{' '}
      <a href="tel:+18772487707">1-877-248-7707</a>, or by emailing{' '}
      <a href="mailto:warrantyops@extend.com">warrantyops@extend.com</a>, or by filing a support ticket at
      <AgreementLink href="https://customers.extend.com/en-US/contact" />.
    </Paragraph>
    <br />
    <Paragraph>
      <strong>5. Hardware and Software Requirements.</strong> In order to access, view, and retain electronic
      Communications that we make available to you, you must have:
      <BulletList>
        <li>A valid email address (your primary email address on file with Extend),</li>
        <li>A device with an Internet connection,</li>
        <li>
          A current web browser that includes 128-bit encryption (e.g. Internet Explorer version 9.0 and above, Firefox
          version 3.6 and above, Chrome version 31.0 and above, Safari 7.0 and above) with cookies enabled, and
        </li>
        <li>Sufficient storage space to save past Communications or an installed printer to print them.</li>
      </BulletList>
    </Paragraph>
    <br />
    <Paragraph>
      <strong>6. Requesting Paper Copies.</strong> We will not send you a paper copy of any Communication, unless we
      otherwise deem it appropriate to send you a paper copy. You can obtain a paper copy of an electronic Communication
      by printing it yourself. If you have trouble printing a paper copy, contact us by calling{' '}
      <a href="tel:+18772487707">1-877-248-7707</a>, by emailing
      <a href="mailto:warrantyops@extend.com">warrantyops@extend.com</a>, or by filing a support ticket at
      <AgreementLink href="https://customers.extend.com/en-US/contact" />. We may charge you a service charge for the
      delivery of paper copies of certain Communications provided to you electronically pursuant to this authorization.
      We reserve the right, but assume no obligation, to provide a paper (instead of electronic) copy of any
      Communication that you have authorized us to provide electronically.
    </Paragraph>
    <br />
    <Paragraph>
      <strong>7. Communications in Writing.</strong> All Communications in either electronic or paper format from us to
      you will be considered &quot;in writing.&quot; You should print or download for your records a copy of this
      Disclosure and any other Communication that is important to you.
    </Paragraph>
    <br />
    <Paragraph>
      <strong>8. Federal Law.</strong> You acknowledge and agree that your consent to electronic Communications is being
      provided in connection with a transaction affecting interstate commerce that is subject to the federal Electronic
      Signatures in Global and National Commerce Act, and that you and we both intend that the Act apply to the fullest
      extent possible to validate our ability to conduct business with you by electronic means.
    </Paragraph>
    <br />
    <Paragraph>
      <strong>9. Termination/Changes.</strong> We reserve the right, in our sole discretion, to discontinue the
      provision of your electronic Communications, or to terminate or change the terms and conditions on which we
      provide electronic Communications. We will provide you with notice of any such termination or change as required
      by law.
    </Paragraph>
  </>
)

export const CACardholderAgreement: FC = () => (
  <>
    <Paragraph>
      <strong>Consent to Receive Electronic Communications</strong>
    </Paragraph>
    <br />
    <Paragraph>
      <strong>We suggest you read this document and print a copy for your reference.</strong>
    </Paragraph>
    <br />
    <Paragraph>
      <strong>
        Note: This Consent to Receive Electronic Communications (“Consent”) applies to any and all communications or
        disclosures that we are legally required to provide to you in writing in connection with your Card and any
        related products and services (“Communications”).
      </strong>
    </Paragraph>
    <br />
    <Paragraph>
      <strong>
        When you acknowledge your agreement to this Consent, you are indicating your consent to all of the terms and
        conditions set out below.
      </strong>
    </Paragraph>
    <br />
    <Paragraph>
      In this Consent, &quot;<strong>we</strong>,&quot; &quot;<strong>us</strong>,&quot; and &quot;
      <strong>our</strong>&quot; refer to Peoples Trust Company, and &quot;<strong>you</strong>
      ,&quot; and &quot;<strong>your</strong>,&quot; refer to the person to whom the card is issued. &quot;
      <strong>Card</strong>&quot; means the Extend Visa Prepaid Card.
    </Paragraph>
    <br />
    <Paragraph>
      <strong>1. Scope of Communications to Be Provided in Electronic Form.</strong>You consent to receive all
      Communications in electronic format, unless and until you withdraw your consent as described below. The electronic
      Communications you consent to receive includes:
      <BulletList>
        <li>any Card related agreements, supplements or addenda;</li>
        <li>notices of any changes to such agreements;</li>
        <li>the privacy policies and any notices of updates and changes to the privacy policies;</li>
        <li>notices regarding your Card; and</li>
        <li>
          any other information relating to your use of the Card and any related products and services, including any
          legal and regulatory disclosures and communications.
        </li>
      </BulletList>
    </Paragraph>
    <br />
    <Paragraph>
      <strong>2. Method of Providing Communications to You in Electronic Form.</strong> We will provide all
      Communications:
      <BulletList>
        <li>
          via email to the email address you have provided to us which may contain the Communication or a link through
          which you may view the Communication; or
        </li>
        <li>via the Extend website or in the mobile app portal.</li>
      </BulletList>
      <br />
      You must provide us with a current email address to receive electronic Communications. Even if you have consented
      to receive electronic Communications, in our discretion we may from time to time send paper copies of certain
      Communications to any mailing address we have for you in our records, either in addition to or in lieu of
      providing electronic versions.
      <br />
      <br />
      If you give us an incorrect email address or fail to keep it updated, you agree that any Communications we may
      make available or attempt to send through any of the methods described above will be deemed to have been provided
      to you in a timely manner.
    </Paragraph>
    <br />
    <Paragraph>
      <strong>3. How to Withdraw Consent.</strong> You may withdraw your consent to receive electronic Communications at
      any time by providing notice by email to <a href="mailto:support@extend.com">support@Extend.com</a>. If you do, we
      may close your Card account. We will not impose any fee to process the withdrawal of your consent to receive
      electronic Communications. Any withdrawal of your consent to receive electronic Communications will be effective
      only after we have had a reasonable period of time to process your withdrawal. While we process your withdrawal,
      you will continue to receive Communications in electronic form. If you withdraw your consent, the legal validity
      and enforceability of prior Communications delivered in electronic form will not be affected.
    </Paragraph>
    <br />
    <Paragraph>
      <strong>4. How to Update Your Records.</strong> It is your responsibility to provide us with a true, accurate and
      complete e-mail address, your contact information, and other information related to this Consent and your Card,
      and to maintain and update promptly any changes to this information. You can update your information (such as your
      e-mail address) by emailing <a href="mailto:support@extend.com">support@Extend.com</a>.
    </Paragraph>
    <br />
    <Paragraph>
      <strong>5. System Requirements.</strong> To receive, access or retain electronic Communications, you will need a
      computer or other device with internet access, a compatible web browser and, for some types of Communications, a
      PDF file reader. Compatible browsers include the most current version (and typically one or more prior versions)
      of all major browsers in widespread use. You will also need access to a printer or the ability to download
      information to keep copies for your records. When you consent to receive electronic Communications, you are
      indicating that you have the capability to access the Communications electronically and to download or print
      copies for your records.
    </Paragraph>
    <br />
    <Paragraph>
      <strong>6. Requesting Paper Copies.</strong> We will not send you a paper copy of any Communication, unless we
      deem it appropriate to do so. You can obtain a paper copy of an electronic Communication by printing it yourself.
      We reserve the right, but assume no obligation, to provide a paper (instead of electronic) copy of any
      Communication that you have authorized us to provide electronically.
    </Paragraph>
    <br />
    <Paragraph>
      <strong>7. Retaining Communications.</strong> Communications that we post on the website will remain accessible
      for a period of time which we may establish in our discretion and which may vary with the type of Communication,
      and such communications will be retained by us for as long as you are an Extend Customer. You are responsible for
      printing or downloading a copy of this Consent and any other Communication for your records.
    </Paragraph>
    <br />
    <Paragraph>
      <strong>8. Communications from You.</strong> This Consent applies only to Communications you receive from us. Any
      notices or communications which you are required to send to us in writing, whether pursuant to our agreements with
      you or under applicable law, must still be sent in accordance with the instructions in those agreements.
    </Paragraph>
    <br />
    <Paragraph>
      <strong>9. Termination/Changes.</strong> We reserve the right, in our sole discretion, to discontinue the
      provision of your electronic Communications, or to terminate or change the terms and conditions on which we
      provide electronic Communications. We will provide you with notice of any such termination or change as required
      by law.
    </Paragraph>
    <br />
    <Paragraph>
      <strong>Consentement à recevoir des communications électroniques</strong>
    </Paragraph>
    <br />
    <Paragraph>
      <strong>Nous vous suggérons de lire ce document et d&apos;en imprimer une copie à titre de référence.</strong>
    </Paragraph>
    <br />
    <Paragraph>
      <strong>
        Remarque : ce Consentement à recevoir des communications électroniques (« Consentement ») s&apos;applique à
        toutes les communications ou divulgations que nous sommes légalement tenus de vous fournir par écrit en relation
        avec votre Carte et avec tous les produits et services connexes (« Communications »).
      </strong>
    </Paragraph>
    <br />
    <Paragraph>
      <strong>
        En acceptant de respecter ce Consentement, vous consentez à toutes les conditions générales énoncées ci-dessous
      </strong>
    </Paragraph>
    <br />
    <Paragraph>
      Dans ce Consentement, « nous », « notre » et « nos » font référence à la Peoples Trust Company, et « vous » et «
      votre » font référence à la personne à qui la Carte est délivrée. La « Carte » désigne la carte Visa prépayée
      Extend.
    </Paragraph>
    <br />
    <Paragraph>
      <strong>1. Portée des communications à transmettre par voie électronique.</strong>Vous consentez à recevoir toutes
      les Communications par voie électronique, à moins que et jusqu&apos;à ce que vous retiriez votre consentement
      comme décrit ci-dessous. Les Communications électroniques que vous consentez à recevoir comprennent :
      <BulletList>
        <li>tout accord, complément ou addenda lié à la Carte;</li>
        <li>les préavis de toute modification apportée à ces accords;</li>
        <li>
          les politiques de confidentialité et tout préavis de mise à jour et de modification des politiques de
          confidentialité;
        </li>
        <li>les préavis avis concernant votre Carte; et</li>
        <li>
          toute autre information relative à votre utilisation de la Carte et de tout produit et service connexe, y
          compris toute divulgation et toute communication légale et réglementaire.
        </li>
      </BulletList>
    </Paragraph>
    <br />
    <Paragraph>
      <strong>2. Méthode pour vous transmettre des communications par voie électronique.</strong> Nous transmettrons
      toutes les communications :
      <BulletList>
        <li>
          par courriel à l&apos;adresse que vous nous avez fournie et qui peut contenir la Communication ou un lien à
          suivre pour accéder à la Communication; ou
        </li>
        <li>via le site Web d&apos;Extend ou dans le portail de l&apos;application mobile.</li>
      </BulletList>
      <br />
      Pour recevoir des Communications électroniques, vous devez nous fournir une adresse courriel que vous utilisez
      actuellement. Même si vous avez consenti à recevoir des Communications électroniques, à notre discrétion, nous
      pouvons de temps à autre envoyer des copies papier de certaines Communications à toute adresse postale associée à
      votre compte, en plus ou au lieu de fournir des versions électroniques.
      <br />
      <br />
      Si vous indiquez une adresse courriel incorrecte ou si vous ne la tenez pas à jour, vous acceptez que toute
      Communication que nous pourrions transmettre ou essayer d&apos;envoyer en utilisant l&apos;une des méthodes
      décrites ci-dessus sera réputée vous avoir été fournie en temps opportun.
    </Paragraph>
    <br />
    <Paragraph>
      <strong>3. Comment retirer votre consentement.</strong> Vous pouvez retirer votre consentement à recevoir des
      Communications électroniques à tout moment en envoyant un préavis par courriel à{' '}
      <a href="mailto:support@extend.com">support@Extend.com</a>. Si vous le faites, nous pouvons fermer le compte de
      votre carte. Nous n&apos;imposerons aucun frais pour traiter le retrait de votre consentement à recevoir des
      Communications électroniques. Tout retrait de votre consentement à recevoir des Communications électroniques
      n&apos;entrera en vigueur qu&apos;après avoir eu un délai raisonnable pour traiter votre retrait. Pendant que nous
      traitons votre retrait, vous continuerez à recevoir des Communications par voie électronique. Si vous retirez
      votre consentement, la validité juridique et l&apos;applicabilité des Communications antérieures envoyées par voie
      électronique ne seront pas affectées.
    </Paragraph>
    <br />
    <Paragraph>
      <strong>4. Comment mettre à jour les informations associées à votre compte.</strong> Il est de votre
      responsabilité de nous fournir une vraie adresse courriel qui est exacte et complète, vos coordonnées et
      d&apos;autres informations relatives à ce Consentement et à votre Carte, ainsi que de maintenir et de mettre à
      jour rapidement toute modification de ces informations. Vous pouvez mettre à jour vos informations (comme votre
      adresse courriel) en envoyant un courriel à <a href="mailto:support@extend.com">support@Extend.com</a>.
    </Paragraph>
    <br />
    <Paragraph>
      <strong>5. Exigences relatives au système.</strong> Pour recevoir, conserver ou accéder à des Communications
      électroniques, vous aurez besoin d&apos;un ordinateur ou d&apos;un autre appareil avec accès à Internet, d&apos;un
      navigateur Web compatible et, pour certains types de Communications, d&apos;un lecteur de fichiers PDF. Les
      navigateurs compatibles comprennent la version la plus récente (et généralement une ou plusieurs versions
      antérieures) de tous les navigateurs les plus populaires. Vous devrez également avoir accès à une imprimante ou
      avoir la possibilité de télécharger des informations pour conserver des copies dans vos dossiers. Lorsque vous
      consentez à recevoir des Communications électroniques, vous indiquez que vous pouvez accéder aux Communications
      par voie électronique et que vous pouvez télécharger ou imprimer des copies pour vos dossiers.
    </Paragraph>
    <br />
    <Paragraph>
      <strong>6. Demande de copies papier.</strong> Nous ne vous enverrons pas de copie papier d&apos;une Communication,
      sauf si nous jugeons qu&apos;il est approprié de le faire. Vous pouvez obtenir une copie papier d&apos;une
      Communication électronique en l&apos;imprimant vous-même. Nous nous réservons le droit, sans y être tenus, de
      fournir une copie papier (au lieu d&apos;une copie électronique) de toute Communication que vous nous avez
      autorisés à envoyer par voie électronique.
    </Paragraph>
    <br />
    <Paragraph>
      <strong>7. Retenir les communications.</strong> Les Communications que nous publions sur le site Web seront
      accessibles pendant une période que nous pouvons établir à notre discrétion et qui peut varier en fonction du type
      de Communication, et nous conserverons ces communications aussi longtemps que vous êtes un client d&apos;Extend.
      Vous êtes responsable de l&apos;impression ou du téléchargement d&apos;une copie de ce Consentement et de toute
      autre Communication pour vos dossiers.
    </Paragraph>
    <br />
    <Paragraph>
      <strong>8. Communications de votre part.</strong> Ce Consentement s&apos;applique uniquement aux Communications
      que vous recevez de notre part. Tous les préavis ou toutes communications que vous êtes tenu de nous envoyer par
      écrit, que ce soit en vertu de nos accords avec vous ou en vertu de la loi applicable, doivent toujours être
      envoyés conformément aux instructions indiquées dans ces accords.
    </Paragraph>
    <br />
    <Paragraph>
      <strong>9. Résiliations ou modifications.</strong> Nous nous réservons le droit, à notre seule discrétion,
      d&apos;arrêter de vous envoyer des Communications électroniques, ou de résilier ou de modifier les conditions
      générales selon lesquelles nous envoyons des Communications électroniques. Nous vous informerons de toute
      résiliation ou modification de ce type, tel que requis par la loi.
    </Paragraph>
  </>
)
