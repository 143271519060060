import React from 'react'

const QuoteLeft: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="43" viewBox="0 0 54 43">
      <path
        fill="#385DCA"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        d="M278.552 2761.788V2782H258v-14.267c0-5.549.94-10.635 2.82-15.258 1.882-4.624 5.038-9.116 9.471-13.475l7.657 5.945c-5.24 5.68-8.194 11.295-8.866 16.843h9.47zm33.448 0V2782h-20.552v-14.267c0-5.549.94-10.635 2.82-15.258 1.881-4.624 5.038-9.116 9.47-13.475l7.658 5.945c-5.24 5.68-8.195 11.295-8.866 16.843H312z"
        transform="translate(-258 -2739)"
      />
    </svg>
  )
}

export default QuoteLeft
