import { createBooleanReducer } from '../../reducers/factories'
import type { Action } from '../../actions'

export type AuthIsLoadingReducerState = boolean

export const initialState: AuthIsLoadingReducerState = false

const isLoading = createBooleanReducer<Action>(['USER_LOGIN_REQUEST'], ['USER_LOGIN_SUCCESS'])

export default isLoading
