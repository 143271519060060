import React from 'react'
import type { FC } from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { COLOR, bp } from '@customers-ui'
import { formatPhoneNumberLocal } from '@extend/client-helpers'
import { Account, Icon } from '@extend/zen'
import * as selectors from '../../reducers/selectors'
import { getAccountInfo } from '../../lib/consumer-profile-utils'

const ProfileMenu: FC = () => {
  const decodedAccessToken = useSelector(selectors.getDecodedAccessToken)
  const consumerProfile = useSelector(selectors.getConsumerProfile)

  const tokenAccountInfo = decodedAccessToken?.phone_number
    ? formatPhoneNumberLocal(decodedAccessToken.phone_number)
    : decodedAccessToken?.email || ''

  const { email, phoneNumber, name, hasAccountInfo } = getAccountInfo(consumerProfile)

  return (
    <ProfileMenuWrapper data-cy="profile-menu-wrapper">
      <ProfileWrapper>
        <Icon icon={Account} color={COLOR.NEUTRAL[800]} data-cy="profile-icon" />
      </ProfileWrapper>
      <AccountDetailsWrapper>
        <AccountHeader data-cy="account-header">{hasAccountInfo ? name.toUpperCase() : 'ACCOUNT'}</AccountHeader>
        {hasAccountInfo ? (
          <>
            <SubInfo data-cy="account-email">{email}</SubInfo>
            <SubInfo data-cy="account-phone">{phoneNumber}</SubInfo>
          </>
        ) : (
          <SubInfo data-cy="account-info">{tokenAccountInfo}</SubInfo>
        )}
      </AccountDetailsWrapper>
    </ProfileMenuWrapper>
  )
}

const ProfileMenuWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  width: '100%',
})

const ProfileWrapper = styled.div({
  width: 40,
  height: 40,
  flexShrink: 0,
  flexGrow: 0,
  borderRadius: '50%',
  backgroundColor: COLOR.NEUTRAL[200],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [bp.desktop]: {
    display: 'none',
  },
})

const AccountDetailsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

const AccountHeader = styled.p({
  fontSize: 16,
  lineHeight: '16px',
  fontWeight: 700,
  fontFamily: 'Nunito Sans',
  color: COLOR.NEUTRAL[800],
})

const SubInfo = styled.div({
  fontSize: 16,
  lineHeight: '24px',
  fontWeight: 400,
  fontFamily: 'Nunito Sans',
  color: COLOR.NEUTRAL[600],
  wordWrap: 'break-word',
})

export { ProfileMenu }
