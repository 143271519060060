import { ButtonBack, ButtonNext } from 'pure-react-carousel'
import styled from '@emotion/styled'

// NOTE: emotion/TS does not like spreading out `BaseButton` into other Objects
// That is why these are still template strings
// `background: none` to prevent MS Edge from showing grey
// ::-moz-focus-inner to prevent outline on FF/Windows
const BaseButton = `
  outline: none;
  border: none;
  background: none;
  height: 100%;
  width: 45px;
  ::-moz-focus-inner {
    border: 0;
  }
  :hover:disabled {
    cursor: default;
  }
  :disabled > svg {
    display: none;
  }
`

export const NextButton = styled(ButtonNext)`
  ${BaseButton}
  padding-left: 16px;
  margin-right: 8px;
`

export const BackButton = styled(ButtonBack)`
  ${BaseButton};
  padding-right: 16px;
  margin-left: 8px;
`
