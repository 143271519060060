import React from 'react'
import styled from '@emotion/styled'
import type { ColorGroupKeys } from '@extend/zen'
import { ArrowDropDown, ArrowDropDownClose, Button, COLOR } from '@extend/zen'
import type { FC } from 'react'
import { useState } from 'react'
import { bp } from '@customers-ui'
import { isMobile } from 'react-device-detect'
import type { CardGroupItemProps } from './types'
import { CardMenuItems } from './card-menu-items'
import { CardListItems } from './card-list-items'
import { getDisplayItems } from './utils'

export const CardGroups: FC<{
  color: ColorGroupKeys
  'data-cy': string
  groups: CardGroupItemProps[]
  showMoreThreshold?: number
  showMoreText?: string
  showLessText?: string
}> = ({
  color,
  'data-cy': dataCy,
  groups,
  showMoreThreshold = 3,
  showMoreText = 'View All',
  showLessText = 'View Less',
}) => {
  const [isViewAllGroupsExpanded, setIsViewAllGroupsExpanded] = useState(false)
  const [isViewAllListItemsExpanded, setIsViewAllListItemsExpanded] = useState<Record<string, boolean>>({})

  const toggleViewAllItems = (id: string): void => {
    setIsViewAllListItemsExpanded({
      ...isViewAllListItemsExpanded,
      [id]: !isViewAllListItemsExpanded[id],
    })
  }

  const hasManyGroups = groups.length > showMoreThreshold
  let numberOfGroupsToShow = showMoreThreshold

  const displayGroups = getDisplayItems<CardGroupItemProps>(
    groups,
    numberOfGroupsToShow,
    showMoreThreshold,
    isViewAllGroupsExpanded,
  )

  return (
    <GroupsWrapper data-cy={`${dataCy}-groups-wrapper`}>
      {displayGroups.map(
        (
          {
            preHeading,
            heading,
            primaryButtonProps,
            secondaryButtonProps,
            menuItems,
            listItems,
            itemsShowLessText,
            itemsShowMoreText,
            itemsShowMoreThreshold,
          },
          index,
        ) => {
          numberOfGroupsToShow--
          const key = `${dataCy}-group-item-${index}`
          return (
            <GroupContainer key={key} data-cy={key}>
              {(preHeading || heading) && (
                <GroupHeaderDetails>
                  <GroupHeaderDetailsLeft>
                    <GroupHeaderText>
                      {preHeading && <GroupPreHeader data-cy={`${key}-preheader`}>{preHeading}</GroupPreHeader>}
                      {heading && <GroupHeader data-cy={`${key}-header`}>{heading}</GroupHeader>}
                    </GroupHeaderText>
                    {secondaryButtonProps && (
                      <Button
                        {...secondaryButtonProps}
                        color={color}
                        data-cy={`${key}-secondary-button`}
                        fillContainer={isMobile ? true : false}
                      />
                    )}
                  </GroupHeaderDetailsLeft>
                  <GroupHeaderDetailsRight isMobile={isMobile}>
                    {primaryButtonProps && (
                      <Button
                        {...primaryButtonProps}
                        color={color}
                        data-cy={`${key}-primary-button`}
                        fillContainer={isMobile ? true : false}
                      />
                    )}
                    {menuItems && menuItems.length > 0 && (
                      <CardMenuItems data-cy={dataCy} menuKey={key} color={color} menuItems={menuItems} />
                    )}
                  </GroupHeaderDetailsRight>
                </GroupHeaderDetails>
              )}
              {listItems && listItems.length > 0 && (
                <CardListItems
                  color={color}
                  data-cy={dataCy}
                  listItems={listItems}
                  listKey={key}
                  showMoreThreshold={itemsShowMoreThreshold}
                  showMoreText={itemsShowMoreText}
                  showLessText={itemsShowLessText}
                  isExpanded={isViewAllListItemsExpanded[key]}
                  toggleExpand={(key) => toggleViewAllItems(key)}
                />
              )}
            </GroupContainer>
          )
        },
      )}
      {hasManyGroups && (
        <Button
          color={color}
          emphasis="low"
          text={isViewAllGroupsExpanded ? showLessText : showMoreText}
          icon={isViewAllGroupsExpanded ? ArrowDropDownClose : ArrowDropDown}
          onClick={() => setIsViewAllGroupsExpanded(!isViewAllGroupsExpanded)}
          data-cy={`${dataCy}-view-all-groups-button`}
        />
      )}
    </GroupsWrapper>
  )
}

const GroupsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
})

const GroupHeaderDetails = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '8px',
  [bp.mobile]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  [bp.desktop]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})

const GroupHeaderText = styled.div({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
})

const GroupHeaderDetailsLeft = styled.div({
  display: 'flex',
  gap: '8px',
  lineHeight: '28px',
  fontSize: '18px',
  flex: 1,
  [bp.mobile]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  [bp.desktop]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})

const GroupHeaderDetailsRight = styled.div<{ isMobile: boolean }>(({ isMobile }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  width: isMobile ? '100%' : '234px',
  justifyContent: 'flex-end',
}))

const GroupPreHeader = styled.div({
  color: COLOR.NEUTRAL[500],
  fontWeight: 700,
})

const GroupHeader = styled.div({
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: '34px',
})

const GroupContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
})
