import type { FC, FormEvent } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'
import type { ImageUploadPrompt } from '@customers-api-client'
import { COLOR } from '@customers-ui'

import svgs from '../../../../lib/svgs'
import { chatActions } from '../../../../actions'

interface ChatImageInputProps {
  prompt: ImageUploadPrompt
}

const ChatImageInput: FC<ChatImageInputProps> = ({ prompt }) => {
  const dispatch = useDispatch()

  const handleChange = (e: FormEvent<HTMLInputElement>): void => {
    const { files } = e.currentTarget
    if (!files?.length) return
    //@ts-ignore
    const [file] = files

    dispatch(
      chatActions.chatSessionImageUpload({
        ...prompt,
        file,
      }),
    )
  }

  return (
    <InputForm data-cy="chat-image-input">
      <Label htmlFor="imageUpload" tabIndex={0}>
        <IconWrapper>
          <svgs.ImageMediaFile />
        </IconWrapper>
        <LabelText>Upload Photo</LabelText>
        <Input
          type="file"
          id="imageUpload"
          name="image-upload"
          tabIndex={-1}
          onChange={handleChange}
          accept="image/png, image/jpeg"
        />
      </Label>
    </InputForm>
  )
}

const InputForm = styled.form({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: 32,
})

const Label = styled.label({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${COLOR.BLUE[700]}`,
  borderRadius: 24,
  color: COLOR.BLUE[700],
  cursor: 'pointer',
  padding: '10px 16px',
  transition: 'all 200ms ease',
  '&:hover': {
    backgroundColor: COLOR.BLUE[700],
    color: COLOR.WHITE,
    '& svg g': {
      stroke: COLOR.WHITE,
    },
  },
})

const LabelText = styled.span({
  fontWeight: 700,
})

const IconWrapper = styled.span({
  marginRight: 8,
})

const Input = styled.input({
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  width: 1,
})

export { ChatImageInput }
