import type { FC } from 'react'
import React from 'react'
import { Modal, Row } from 'react-bootstrap'

interface ImagePreviewModalProps {
  image: string
  show: boolean
  title?: string
  onHide: () => void
}

const ImagePreviewModal: FC<ImagePreviewModalProps> = ({ image, onHide, title, show }) => {
  return (
    <Modal
      centered
      animation={false}
      onHide={onHide}
      show={show}
      css={{ '& .modal-dialog': { maxWidth: 'fit-content' } }}
    >
      <Modal.Header closeButton>{title ? <Modal.Title>{title}</Modal.Title> : null}</Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center">
          <img src={image} css={{ maxWidth: '100%' }} alt="product preview" />
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export { ImagePreviewModal }
